import type { BoxProps } from "@chakra-ui/react";
import { Box, Button, Collapse, Icon, useDisclosure, VStack } from "@chakra-ui/react";
import type { FilledLinkToWebField } from "@prismicio/types";
import * as React from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "../common";

interface CollapsibleMenuProps {
  links: { label: string; link: FilledLinkToWebField }[];
  onClick?: () => void;
}

export const CollapsibleMenu = ({ children, links, onClick, ...rest }: CollapsibleMenuProps & BoxProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box {...rest}>
      <Button
        variant="unstyled"
        rightIcon={<Icon fontSize="16px" as={RiArrowDownSLine} />}
        display="flex"
        fontWeight="medium"
        onClick={onToggle}
        h="max-content"
      >
        {children}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <VStack alignItems="flex-start" ml={6} spacing={6} pt={6}>
          {links.map((link) => {
            if (!link.link.url) {
              return null;
            }

            return (
              <Link key={link.label} href={link.link.url} fontWeight="medium" onClick={onClick}>
                {link.label}
              </Link>
            );
          })}
        </VStack>
      </Collapse>
    </Box>
  );
};
