import type { HeadingProps, TextProps } from "@chakra-ui/react";
import { Box, BoxProps, Heading, StylesProvider, Text, useMultiStyleConfig } from "@chakra-ui/react";
import { Container, ContainerProps } from "./Container";

export const SectionHeading = (props: HeadingProps) => (
  <Heading
    as="h2"
    fontSize={{ base: "40px", xl: "48px" }}
    maxW={{ base: "424px", md: "880px" }}
    fontWeight="semibold"
    mb={6}
    {...props}
  />
);

export const SectionDescription = (props: TextProps) => (
  <Text as="div" maxW={{ base: "424px", md: "80ch" }} mb={{ base: 16, md: 20 }} {...props} />
);

interface SectionProps {
  variant?: "white" | "cream" | "green" | "black";
  containerProps?: ContainerProps;
}

export const Section = ({ children, containerProps, variant = "white", ...rest }: SectionProps & BoxProps) => {
  const styles = useMultiStyleConfig("Section", { variant });

  return (
    <Box as="section" __css={styles.section} {...rest}>
      <StylesProvider value={styles}>
        <Container px={{ base: 4, md: 10, xl: 20 }} {...containerProps}>
          {children}
        </Container>
      </StylesProvider>
    </Box>
  );
};
