import { MenuLink } from "@/components/common";
import { DropdownButton } from "@/components/common/DropdownButton";
import { CitiesWithPublishedApartmentsDocument } from "@/generated/graphql";
import { useGraphqlQuery } from "@/lib/useGraphql";
import { Menu, MenuButton, useDisclosure } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import useTranslation from "next-translate/useTranslation";
import * as React from "react";
import { MenuList } from "../MenuList";

const TIMEOUT_LENGTH = 200;

export const CITIES_WITH_AVAILABLE_APARTMENTS = gql`
  query CitiesWithPublishedApartments {
    cities(filter: { unitPublished: true }) {
      id
      slug
      name
    }
  }
`;

interface CitiesMenuProps {
  children: React.ReactNode;
  onClick?: () => void;
  size?: "md" | "lg";
  toApartments?: boolean;
  variant?: "primary" | "transparent" | "white" | "unstyled";
}

export const CitiesMenu = ({
  children,
  onClick,
  size = "md",
  toApartments = false,
  variant = "primary",
}: CitiesMenuProps) => {
  const [mouseOverButton, setMouseOverButton] = React.useState<boolean>(false);
  const [mouseOverMenu, setMouseOverMenu] = React.useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("common");

  const enterButton = () => {
    setMouseOverButton(true);
  };

  const leaveButton = () => {
    setTimeout(() => {
      setMouseOverButton(false);
    }, TIMEOUT_LENGTH);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setMouseOverMenu(false);
    }, TIMEOUT_LENGTH);
  };

  const { data, isLoading } = useGraphqlQuery(CitiesWithPublishedApartmentsDocument);

  const handleClick = () => {
    setTimeout(() => {
      setMouseOverMenu(false);
      setMouseOverButton(false);
      onClick?.();
    }, TIMEOUT_LENGTH);
  };

  return (
    <Menu isOpen={isOpen || mouseOverButton || mouseOverMenu} onClose={onClose}>
      <MenuButton
        as={DropdownButton}
        size={size}
        variant={variant}
        onClick={onOpen}
        onMouseEnter={enterButton}
        onMouseLeave={leaveButton}
      >
        {children}
      </MenuButton>
      <MenuList onMouseEnter={enterMenu} onMouseLeave={leaveMenu} isLoading={isLoading}>
        {data?.cities
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((city) => (
            <MenuLink
              key={city.id}
              href={{ pathname: toApartments ? "/[uid]/homes" : "/[uid]", query: { uid: city.slug } }}
              color="gray.900"
              onClick={handleClick}
            >
              {t(city.slug)}
            </MenuLink>
          ))}
      </MenuList>
    </Menu>
  );
};
