import type { IconBaseProps } from "react-icons";

export const CheckboxOn = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4Zm12.848 6.53a1 1 0 0 0-1.696-1.06l-4.334 6.934-2.11-2.111a1 1 0 0 0-1.415 1.414l3 3a1 1 0 0 0 1.555-.177l5-8Z"
    />
  </svg>
);
