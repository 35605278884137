import { MenuLink } from "@/components/common";
import { DropdownButton } from "@/components/common/DropdownButton";
import type { MenuProps as ChakraMenuProps } from "@chakra-ui/react";
import { Menu as ChakraMenu, MenuButton, useDisclosure } from "@chakra-ui/react";
import type { FilledLinkToWebField } from "@prismicio/types";
import * as React from "react";
import { MenuList } from "../MenuList";

const TIMEOUT_LENGTH = 200;

interface MenuProps {
  children: React.ReactNode;
  links: { label: string; link: FilledLinkToWebField }[];
  onClick?: () => void;
  size?: "md" | "lg";
  variant?: "primary" | "transparent" | "white" | "unstyled";
}

export const Menu = ({
  children,
  links,
  onClick,
  size = "md",
  variant = "primary",
  ...rest
}: MenuProps & ChakraMenuProps) => {
  const [mouseOverButton, setMouseOverButton] = React.useState<boolean>(false);
  const [mouseOverMenu, setMouseOverMenu] = React.useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const enterButton = () => {
    setMouseOverButton(true);
  };

  const leaveButton = () => {
    setTimeout(() => {
      setMouseOverButton(false);
    }, TIMEOUT_LENGTH);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setMouseOverMenu(false);
    }, TIMEOUT_LENGTH);
  };

  return (
    <ChakraMenu isOpen={isOpen || mouseOverButton || mouseOverMenu} onClose={onClose} {...rest}>
      <MenuButton
        as={DropdownButton}
        size={size}
        variant={variant}
        onClick={onOpen}
        onMouseEnter={enterButton}
        onMouseLeave={leaveButton}
      >
        {children}
      </MenuButton>
      <MenuList onMouseEnter={enterMenu} onMouseLeave={leaveMenu}>
        {links.map((link) => {
          if (!link.link.url) return null;
          return (
            <MenuLink key={link.label} href={link.link.url} color="gray.900" onClick={onClick}>
              {link.label}
            </MenuLink>
          );
        })}
      </MenuList>
    </ChakraMenu>
  );
};
