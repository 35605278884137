import type { IconBaseProps } from "react-icons";

export const InfoSmall = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.456 6.716c0-.943-.78-1.716-1.76-1.716s-1.759.75-1.784 1.692c0 .966.804 1.74 1.784 1.74.956 0 1.76-.774 1.76-1.716ZM9.883 15.206a23.296 23.296 0 0 0-.301 1.836c-.126.895.779 1.74 1.533 1.885.427.073.854.073 1.282.073.93-.024 1.684-.387 2.337-1.015.126-.12.201-.266.251-.41.076-.267-.15-.436-.402-.34-.088.037-.17.08-.251.122-.082.042-.164.084-.252.12a2.872 2.872 0 0 1-.653.17.467.467 0 0 1-.528-.363c-.05-.17-.075-.387-.05-.58.044-.357.106-.696.171-1.048l.03-.16c.218-1.05.417-2.116.614-3.17l.09-.48c.05-.314.1-.652.1-.99-.025-.387-.276-.58-.678-.58H9.808c-.126 0-.276 0-.402.024-.251.024-.377.145-.402.338-.025.193.075.362.301.435.176.048.377.072.578.097.453.048.604.217.604.652 0 .022-.005.049-.011.078-.007.036-.015.076-.015.115-.2 1.064-.402 2.127-.578 3.19Z"
      fill="currentColor"
    />
  </svg>
);
