import type { IconBaseProps } from "react-icons";

export const ChevronRight = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M13.5198 12.0003L9.27682 7.75726C8.88636 7.36679 8.88636 6.73372 9.27682 6.34326C9.66729 5.95279 10.3004 5.95279 10.6908 6.34326L15.7693 11.4217C16.0888 11.7412 16.0888 12.2593 15.7693 12.5788L10.6908 17.6573C10.3004 18.0477 9.66729 18.0477 9.27682 17.6573C8.88636 17.2668 8.88636 16.6337 9.27682 16.2433L13.5198 12.0003Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(0 24) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>
);
