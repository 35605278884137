import { ChevronDown } from "@/icons";
import type { ButtonProps } from "@chakra-ui/react";
import { Box, Button, Icon, useMultiStyleConfig } from "@chakra-ui/react";
import * as React from "react";

interface DropdownButtonProps {
  size?: "md" | "lg";
  variant?: "primary" | "transparent" | "white" | "unstyled";
}

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps & ButtonProps>(
  ({ children, size = "md", variant = "primary", ...rest }, ref) => {
    const styles = useMultiStyleConfig("DropdownButton", { size, variant });

    if (variant === "unstyled") {
      return (
        <Button
          ref={ref}
          size={size}
          variant={variant}
          rightIcon={<Icon boxSize={6} as={ChevronDown} />}
          display="flex"
          fontWeight="medium"
          {...rest}
        >
          {children}
        </Button>
      );
    }

    return (
      <Button ref={ref} __css={styles.button} {...rest}>
        <Box __css={styles.label}>{children}</Box>
        <Box __css={styles.divider} />
        <Box __css={styles.icon}>
          <Icon boxSize={6} as={ChevronDown} />
        </Box>
      </Button>
    );
  },
);

DropdownButton.displayName = "DropdownButton";

export default DropdownButton;
