import type { IconBaseProps } from "react-icons";

export const Calendar = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1a1 1 0 00-1 1v1H5a3 3 0 00-3 3v12a3 3 0 003 3h14a3 3 0 003-3V6a3 3 0 00-3-3h-2V2a1 1 0 10-2 0v1H9V2a1 1 0 00-1-1zm12 8V6a1 1 0 00-1-1h-2v1a1 1 0 11-2 0V5H9v1a1 1 0 01-2 0V5H5a1 1 0 00-1 1v3h16zM4 11v7a1 1 0 001 1h14a1 1 0 001-1v-7H4z"
    />
  </svg>
);
