import { Box, useStyleConfig } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

export type CardProps = BoxProps;

export const Card = (props: BoxProps) => {
  const styles = useStyleConfig("Card");

  return <Box __css={styles} {...props} />;
};
