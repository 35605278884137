import type { Callback } from "amplitude-js";

export const AMPLITUDE_EVENTS = {
  apartmentClick: "Apartment click",
  signupSubmitted: "Signup form submited",
  subscribeSubmitted: "Subscribe form submitted",
  notFoundPageView: "404 page view",
  indexPageView: "Index page view",
  otherPageView: "Other page view",
  signupPageView: "Signup page view",
  blogPageView: "Blog page view",
  apartmentsPageView: "Apartments page view",
  apartmentDetailPageView: "Apartment detail page view",
  browseButtonClick: "Browse button click",
  apartmentTabClick: "Apartment detail tab click",
  apartmentListTabClick: "Apartment list tab click",
  subscribePageView: "Subscribe page view",
  subscribeButtonClick: "Subscribe button click",
  signupButtonClick: "Signup button click",
  formSubmitted: "Form submitted",
  apartmentDescriptionReadMoreClick: "Apartment description read more click",
  roomDescriptionReadMoreClick: "Room description read more click",
  apartmentTypeHover: "Apartment type hover",
  moveInDateChanged: "Move in date changed",
  maxPriceChanged: "Max price changed",
  roomTypeChanged: "Room type changed",
  unitTypeChanged: "Unit type changed",
  buildingDetailPageView: "Building detail page view",
  availableUnitAlertCreated: "Available unit alert created",
  billsIncludedHover: "Bills included hover",
};

export const logEvent = (event: string, data?: any, callback?: Callback) => {
  if (window && window.amplitude) {
    window.amplitude.getInstance().logEvent(event, data, callback);
  }
};
