import type { IconBaseProps } from "react-icons";

export const Contract = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15 7H9a1 1 0 000 2h6a1 1 0 100-2zM15 11H9a1 1 0 100 2h6a1 1 0 100-2zM9 15h3a1 1 0 110 2H9a1 1 0 110-2z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5a3 3 0 013-3h12a3 3 0 013 3v14a3 3 0 01-3 3H6a3 3 0 01-3-3V5zm3-1h12a1 1 0 011 1v14a1 1 0 01-1 1H6a1 1 0 01-1-1V5a1 1 0 011-1z"
    />
  </svg>
);
