export const getSrcset = (src: string, widths = [360, 540, 720, 900, 1080, 1620, 2160]): string | undefined => {
  if (!src) {
    return;
  }
  const srcSet = [];
  const baseUrl = src.split("?")[0];
  const isContentful = baseUrl.includes("ctfassets.net");
  const isPrismic = baseUrl.includes("prismic.io");

  if (isContentful || isPrismic) {
    for (const w of widths) {
      srcSet.push(`${baseUrl}?q=80&w=${w}&fm=webp ${w}w`);
    }

    return srcSet.join(", ");
  }

  for (const w of widths) {
    srcSet.push(`${src} ${w}w`);
  }

  return srcSet.join(", ");
};
