import type { IconBaseProps } from "react-icons";

export const Water = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.586 1.686-4.95 4.95a9 9 0 1 0 12.728 0l-4.95-4.95a2 2 0 0 0-2.828 0ZM6.6 12.726c.957.552 2.18.224 3.132-1.425.952-1.65.625-2.873-.332-3.425-.957-.552-2.18-.224-3.132 1.425-.952 1.65-.625 2.873.332 3.425Z"
      fill="currentColor"
    />
  </svg>
);
