import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AccountingCode = {
  __typename?: "AccountingCode";
  code: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Apartment = {
  __typename?: "Apartment";
  addressLocality?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use contacts instead */
  administrator?: Maybe<Landlord>;
  apartmentAttributes: ApartmentAttributes;
  apartmentType?: Maybe<ApartmentType>;
  bookableUnit?: Maybe<BookableUnit>;
  building?: Maybe<Building>;
  buildingAttributes?: Maybe<BuildingAttributes>;
  businessUnitId: Scalars["Int"]["output"];
  city: City;
  /** @deprecated use cmsData instead */
  cmsContent?: Maybe<ApartmentContent>;
  cmsData?: Maybe<Scalars["JSONObject"]["output"]>;
  contacts: Array<Contact>;
  /** @deprecated use cmsData instead */
  contentful?: Maybe<ApartmentContent>;
  contractAdditionalInfo?: Maybe<Scalars["String"]["output"]>;
  contractAdditionalInfoEnglish?: Maybe<Scalars["String"]["output"]>;
  contractEnd?: Maybe<Scalars["DateTime"]["output"]>;
  contractStart?: Maybe<Scalars["DateTime"]["output"]>;
  contractTemplate?: Maybe<ContractTemplate>;
  coupleContractTemplate?: Maybe<ContractTemplate>;
  createdAt: Scalars["DateTime"]["output"];
  currentStockList: Array<CategoryStockList>;
  deposit?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use apartmentAttributes.floorPlan instead */
  floorPlan?: Maybe<Scalars["String"]["output"]>;
  generalFeed?: Maybe<StreamFeed>;
  housekeepers: Array<User>;
  id: Scalars["Int"]["output"];
  /** @deprecated Not used anymore */
  info?: Maybe<Scalars["String"]["output"]>;
  invoicingCosts: Array<InvoicingCost>;
  issues?: Maybe<Array<Issue>>;
  /** @deprecated Use contacts instead */
  landlord?: Maybe<Landlord>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  maxMonthsRentalPeriod?: Maybe<Scalars["Int"]["output"]>;
  minMonthsRentalPeriod?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  ongoingStays?: Maybe<Array<Stay>>;
  opsManagers: Array<User>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  potentialRevenue: Scalars["Float"]["output"];
  rent?: Maybe<Scalars["Int"]["output"]>;
  rentalPeriodType?: Maybe<RentalPeriodType>;
  rooms?: Maybe<Array<Room>>;
  signedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  /** @deprecated Use specifications */
  specification: Array<Specification>;
  specifications: Array<SpecificationOneValue>;
  streetAddress?: Maybe<Scalars["String"]["output"]>;
  suburb?: Maybe<Scalars["String"]["output"]>;
  terminationNoticePeriod?: Maybe<Scalars["Int"]["output"]>;
  unitMedia: Array<UnitMedia>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ApartmentAttributes = {
  __typename?: "ApartmentAttributes";
  addressRegistration?: Maybe<Scalars["Boolean"]["output"]>;
  airConditioning: Scalars["Boolean"]["output"];
  balconyOrTerrace: Scalars["Boolean"]["output"];
  bathrooms?: Maybe<Scalars["Int"]["output"]>;
  bedrooms?: Maybe<Scalars["Int"]["output"]>;
  /** [[nb of beds for this size, width, length]] */
  bedsSizes: Array<Maybe<Array<Maybe<Scalars["Int"]["output"]>>>>;
  constructionYear?: Maybe<Scalars["Int"]["output"]>;
  desk: Scalars["Boolean"]["output"];
  dishwasher: Scalars["Boolean"]["output"];
  dryer: Scalars["Boolean"]["output"];
  floor?: Maybe<Scalars["Int"]["output"]>;
  floorPlan?: Maybe<Scalars["String"]["output"]>;
  furnished: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  kitchen: Scalars["Boolean"]["output"];
  linenAndTowels: Scalars["Boolean"]["output"];
  listingDescription?: Maybe<Scalars["String"]["output"]>;
  listingTitle?: Maybe<Scalars["String"]["output"]>;
  locationDescription?: Maybe<Scalars["String"]["output"]>;
  petsAllowed: Scalars["Boolean"]["output"];
  roomsCount?: Maybe<Scalars["Int"]["output"]>;
  sharedBathroom: Scalars["Boolean"]["output"];
  size?: Maybe<Scalars["Float"]["output"]>;
  spaceDescription?: Maybe<Scalars["String"]["output"]>;
  storage: Scalars["Boolean"]["output"];
  tv: Scalars["Boolean"]["output"];
  washer: Scalars["Boolean"]["output"];
  wheelchairAccessible: Scalars["Boolean"]["output"];
  wifi: Scalars["Boolean"]["output"];
};

export type ApartmentContent = {
  __typename?: "ApartmentContent";
  city?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  facts?: Maybe<Array<Scalars["String"]["output"]>>;
  floorplan?: Maybe<Asset>;
  heroImage?: Maybe<Asset>;
  heroVideo?: Maybe<Asset>;
  immoViewerLink?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  newApartment?: Maybe<Scalars["Boolean"]["output"]>;
  photos?: Maybe<Array<Asset>>;
  quickDescription?: Maybe<Scalars["String"]["output"]>;
  suburb?: Maybe<Scalars["String"]["output"]>;
  uniqueFeature?: Maybe<Scalars["String"]["output"]>;
};

export type ApartmentDefaultFilter = {
  apartmentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  citySlug?: InputMaybe<Scalars["String"]["input"]>;
  contact?: InputMaybe<ContactTagLandlordFilterInput>;
  housekeeperId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isIntoBuilding?: InputMaybe<Scalars["Boolean"]["input"]>;
  opsManagerId?: InputMaybe<Scalars["Int"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  staffId?: InputMaybe<Scalars["Int"]["input"]>;
  unitType?: InputMaybe<UnitType>;
};

export type ApartmentPaginationInput = {
  filter?: InputMaybe<ApartmentDefaultFilter>;
};

export type ApartmentStockItem = {
  __typename?: "ApartmentStockItem";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  quantity?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ApartmentStockList = {
  __typename?: "ApartmentStockList";
  categories: Array<CategoryStockList>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type ApartmentType = {
  __typename?: "ApartmentType";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  internalName: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Asset = {
  __typename?: "Asset";
  file?: Maybe<File>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Attribute = {
  __typename?: "Attribute";
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  type: SpecificationAttributeType;
  values: Array<Value>;
};

export type AttributeOneValue = {
  __typename?: "AttributeOneValue";
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  key?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  type: SpecificationAttributeType;
  unitType: SpecificationUnitType;
  value?: Maybe<ValueOneValue>;
};

export enum AvailableUnitAlertFrequency {
  Daily = "daily",
  Monthly = "monthly",
  Realtime = "realtime",
  Weekly = "weekly",
}

export type AvailableUnitAlertSubscription = {
  __typename?: "AvailableUnitAlertSubscription";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Bank = {
  __typename?: "Bank";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  bankAddress?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  iban?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  personalAddress?: Maybe<Scalars["String"]["output"]>;
  registrationNumber?: Maybe<Scalars["String"]["output"]>;
  routingNumber?: Maybe<Scalars["String"]["output"]>;
  swiftBic?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BaseAnswer = {
  __typename?: "BaseAnswer";
  changeState?: Maybe<QuestionChangeState>;
  comment?: Maybe<Scalars["String"]["output"]>;
  media?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type BaseApartmentAttributesInput = {
  addressRegistration?: InputMaybe<Scalars["Boolean"]["input"]>;
  airConditioning?: InputMaybe<Scalars["Boolean"]["input"]>;
  balconyOrTerrace?: InputMaybe<Scalars["Boolean"]["input"]>;
  bathrooms?: InputMaybe<Scalars["Int"]["input"]>;
  bedrooms?: InputMaybe<Scalars["Int"]["input"]>;
  /** [[bedCount, width, length]] */
  bedsSizes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>>>;
  constructionYear?: InputMaybe<Scalars["Int"]["input"]>;
  desk?: InputMaybe<Scalars["Boolean"]["input"]>;
  dishwasher?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryer?: InputMaybe<Scalars["Boolean"]["input"]>;
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  furnished?: InputMaybe<Scalars["Boolean"]["input"]>;
  kitchen?: InputMaybe<Scalars["Boolean"]["input"]>;
  linenAndTowels?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingDescription?: InputMaybe<Scalars["String"]["input"]>;
  listingTitle?: InputMaybe<Scalars["String"]["input"]>;
  locationDescription?: InputMaybe<Scalars["String"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  roomsCount?: InputMaybe<Scalars["Int"]["input"]>;
  sharedBathroom?: InputMaybe<Scalars["Boolean"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  spaceDescription?: InputMaybe<Scalars["String"]["input"]>;
  storage?: InputMaybe<Scalars["Boolean"]["input"]>;
  tv?: InputMaybe<Scalars["Boolean"]["input"]>;
  washer?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  wifi?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BaseApartmentInput = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  apartmentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  coupleContractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  deposit?: InputMaybe<Scalars["Int"]["input"]>;
  generalFeedId?: InputMaybe<Scalars["Float"]["input"]>;
  housekeepersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  maxMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  opsManagersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  rent?: InputMaybe<Scalars["Int"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  signedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BaseBuildingAttributesInput = {
  bikePark?: InputMaybe<Scalars["Boolean"]["input"]>;
  canteen?: InputMaybe<Scalars["Boolean"]["input"]>;
  cinemaRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  communalKitchen?: InputMaybe<Scalars["Boolean"]["input"]>;
  communalSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  constructionYear?: InputMaybe<Scalars["Int"]["input"]>;
  cowokringSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  elevator?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeCarPark?: InputMaybe<Scalars["Boolean"]["input"]>;
  laundryRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingDescription?: InputMaybe<Scalars["String"]["input"]>;
  listingTitle?: InputMaybe<Scalars["String"]["input"]>;
  locationDescription?: InputMaybe<Scalars["String"]["input"]>;
  nbFloor?: InputMaybe<Scalars["Int"]["input"]>;
  outdoorSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  paidCarPark?: InputMaybe<Scalars["Boolean"]["input"]>;
  sharedStorage?: InputMaybe<Scalars["Boolean"]["input"]>;
  spaceDescription?: InputMaybe<Scalars["String"]["input"]>;
  sportFacilities?: InputMaybe<Scalars["Boolean"]["input"]>;
  wellnessFacilities?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BaseContextVariable = {
  __typename?: "BaseContextVariable";
  contextId: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  handlebarsSyntax: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  requiresStay: Scalars["Boolean"]["output"];
  type: Scalars["String"]["output"];
};

export type BaseCreateApartmentInput = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  apartmentTypeId: Scalars["Int"]["input"];
  bathrooms?: InputMaybe<Scalars["Float"]["input"]>;
  bedrooms?: InputMaybe<Scalars["Float"]["input"]>;
  cityId: Scalars["Int"]["input"];
  createBookableUnit?: InputMaybe<CreateBookableUnitInput>;
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  roomsCount?: InputMaybe<Scalars["Float"]["input"]>;
  signedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Float"]["input"]>;
};

export type BaseCreateContactInput = {
  landlordId: Scalars["Int"]["input"];
  tagId: Scalars["Int"]["input"];
};

export type BaseCreateListingStatus = {
  isEnabled: Scalars["Boolean"]["input"];
  platform: ListingPlatform;
};

export type BaseCreateRoomInput = {
  bookableUnit: CreateBookableUnitInput;
  name: Scalars["String"]["input"];
  roomAttributes?: InputMaybe<BaseRoomAttributesInput>;
  roomTypeId: Scalars["Int"]["input"];
};

export type BaseQuestion = {
  __typename?: "BaseQuestion";
  changeState?: Maybe<QuestionChangeState>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  status: InspectionItemStatus;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type BaseRoomAttributesInput = {
  /** [[bedCount, width, length]] */
  bedsSizes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>>>;
  desk?: InputMaybe<Scalars["Boolean"]["input"]>;
  ensuiteBathroom?: InputMaybe<Scalars["Boolean"]["input"]>;
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  furnished?: InputMaybe<Scalars["Boolean"]["input"]>;
  linenAndTowels?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingDescription?: InputMaybe<Scalars["String"]["input"]>;
  listingTitle?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  privateBalcony?: InputMaybe<Scalars["Boolean"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  storage?: InputMaybe<Scalars["Boolean"]["input"]>;
  tv?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BaseSpecificationValueArgs = {
  attributeId?: InputMaybe<Scalars["Float"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type BaseSurveyQuestion = {
  __typename?: "BaseSurveyQuestion";
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type BaseUpdateListingStatus = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isEnabled: Scalars["Boolean"]["input"];
  platform: ListingPlatform;
};

export type BookableUnit = {
  __typename?: "BookableUnit";
  apartment: Apartment;
  available: Scalars["Boolean"]["output"];
  availableFrom?: Maybe<Scalars["DateTime"]["output"]>;
  /** Date when the unit became available */
  becameAvailableAt?: Maybe<Scalars["DateTime"]["output"]>;
  category: UnitCategory;
  closeDate?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  currentStay?: Maybe<Stay>;
  id: Scalars["Int"]["output"];
  lastStay?: Maybe<Stay>;
  listingStatuses?: Maybe<Array<ListingStatusOutput>>;
  nextStay?: Maybe<Stay>;
  openDate?: Maybe<Scalars["DateTime"]["output"]>;
  published: Scalars["Boolean"]["output"];
  rent?: Maybe<Scalars["Float"]["output"]>;
  /** Use this note to add reservation info. It will be reset when a stay is created for this unit */
  reservedNote?: Maybe<Scalars["String"]["output"]>;
  room?: Maybe<Room>;
  sharedSpaceSize?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BookableUnitFilter = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  apartmentTypeIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  available?: InputMaybe<Scalars["Boolean"]["input"]>;
  availableFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  availableTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  bathroomsCount?: InputMaybe<Scalars["Int"]["input"]>;
  bedrooms?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  hasListingStatusError?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  landlordId?: InputMaybe<Scalars["Int"]["input"]>;
  maxPrice?: InputMaybe<Scalars["Int"]["input"]>;
  maxSize?: InputMaybe<Scalars["Int"]["input"]>;
  minPrice?: InputMaybe<Scalars["Int"]["input"]>;
  minSize?: InputMaybe<Scalars["Int"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  reserved?: InputMaybe<Scalars["Boolean"]["input"]>;
  roomTypeIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  roomsCount?: InputMaybe<Scalars["Int"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
  unitType?: InputMaybe<UnitType>;
  unpublished?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Building = {
  __typename?: "Building";
  addressLocality?: Maybe<Scalars["String"]["output"]>;
  apartments: Array<Apartment>;
  buildingAttributes: BuildingAttributes;
  businessUnitId?: Maybe<Scalars["Int"]["output"]>;
  city: City;
  cmsData?: Maybe<Scalars["JSONObject"]["output"]>;
  contacts: Array<Contact>;
  createdAt: Scalars["DateTime"]["output"];
  generalFeed?: Maybe<StreamFeed>;
  id: Scalars["Int"]["output"];
  invoicingCosts: Array<InvoicingCost>;
  issues: Array<Issue>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  ongoingStays: Array<Stay>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  slug: Scalars["String"]["output"];
  specifications: Array<SpecificationOneValue>;
  streetAddress?: Maybe<Scalars["String"]["output"]>;
  suburb?: Maybe<Scalars["String"]["output"]>;
  unitMedia: Array<UnitMedia>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BuildingAttributes = {
  __typename?: "BuildingAttributes";
  bikePark: Scalars["Boolean"]["output"];
  canteen: Scalars["Boolean"]["output"];
  cinemaRoom: Scalars["Boolean"]["output"];
  communalKitchen: Scalars["Boolean"]["output"];
  communalSpace: Scalars["Boolean"]["output"];
  constructionYear?: Maybe<Scalars["Int"]["output"]>;
  cowokringSpace: Scalars["Boolean"]["output"];
  elevator: Scalars["Boolean"]["output"];
  freeCarPark: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  laundryRoom: Scalars["Boolean"]["output"];
  listingDescription?: Maybe<Scalars["String"]["output"]>;
  listingTitle?: Maybe<Scalars["String"]["output"]>;
  locationDescription?: Maybe<Scalars["String"]["output"]>;
  nbFloor?: Maybe<Scalars["Int"]["output"]>;
  outdoorSpace: Scalars["Boolean"]["output"];
  paidCarPark: Scalars["Boolean"]["output"];
  sharedStorage: Scalars["Boolean"]["output"];
  spaceDescription?: Maybe<Scalars["String"]["output"]>;
  sportFacilities: Scalars["Boolean"]["output"];
  wellnessFacilities: Scalars["Boolean"]["output"];
  wheelchairAccessible: Scalars["Boolean"]["output"];
};

export type BuildingDefaultFilter = {
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  contact?: InputMaybe<ContactTagLandlordFilterInput>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type BuildingDetail = {
  __typename?: "BuildingDetail";
  buildingAttributes?: Maybe<BuildingAttributes>;
  id: Scalars["Int"]["output"];
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  media?: Maybe<Array<Scalars["String"]["output"]>>;
  name: Scalars["String"]["output"];
};

export type BuildingListingType = {
  __typename?: "BuildingListingType";
  citySlug?: Maybe<Scalars["String"]["output"]>;
  cmsData?: Maybe<Scalars["JSONObject"]["output"]>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  specifications: Array<SpecificationOneValue>;
};

export type BulkParseInput = {
  fieldName: Scalars["String"]["input"];
  fieldValue: Scalars["String"]["input"];
};

export type BulkParseOutput = {
  __typename?: "BulkParseOutput";
  fieldName: Scalars["String"]["output"];
  fieldValue: Scalars["String"]["output"];
};

export type BulkUpdateApartmentPaginationInput = {
  filter?: InputMaybe<ApartmentDefaultFilter>;
  ignoreErrors?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BulkUpdateApartmentsData = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  apartmentAttributes?: InputMaybe<BaseApartmentAttributesInput>;
  apartmentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  bookableUnit?: InputMaybe<CreateBookableUnitInput>;
  buildingAttributes?: InputMaybe<BaseBuildingAttributesInput>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  coupleContractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  deposit?: InputMaybe<Scalars["Int"]["input"]>;
  generalFeedId?: InputMaybe<Scalars["Float"]["input"]>;
  housekeepersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  maxMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  opsManagersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  rent?: InputMaybe<Scalars["Int"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  signedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  specifications?: InputMaybe<Array<BaseSpecificationValueArgs>>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BulkUpdateRoomPaginationInput = {
  filter?: InputMaybe<RoomFilter>;
  ignoreErrors?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BulkUpdateRoomsData = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  bookableUnit?: InputMaybe<CreateBookableUnitInput>;
  roomAttributes?: InputMaybe<BaseRoomAttributesInput>;
  roomTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  specifications?: InputMaybe<Array<BaseSpecificationValueArgs>>;
};

export type BusinessUnit = {
  __typename?: "BusinessUnit";
  code: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type Card = {
  __typename?: "Card";
  action?: Maybe<CardAction>;
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  type: CardType;
};

export type CardAction = {
  __typename?: "CardAction";
  isDeeplink?: Maybe<Scalars["Boolean"]["output"]>;
  url: Scalars["String"]["output"];
};

export type CardBasicPost = {
  __typename?: "CardBasicPost";
  action?: Maybe<CardAction>;
  attachment?: Maybe<CardPostAttachment>;
  authorId?: Maybe<Scalars["Int"]["output"]>;
  avatar?: Maybe<CardImage>;
  body: Scalars["String"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  postId?: Maybe<Scalars["Int"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  showAllBody?: Maybe<Scalars["Boolean"]["output"]>;
  streamFeedId?: Maybe<Scalars["Int"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: CardType;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CardContentPreview = {
  __typename?: "CardContentPreview";
  action?: Maybe<CardAction>;
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  image?: Maybe<CardImage>;
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: CardType;
};

export type CardEvent = {
  __typename?: "CardEvent";
  action?: Maybe<CardAction>;
  category?: Maybe<Scalars["String"]["output"]>;
  event: Event;
  id: Scalars["String"]["output"];
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: CardType;
  userResponse?: Maybe<EventResponse>;
};

export type CardGroup = {
  __typename?: "CardGroup";
  action?: Maybe<CardAction>;
  cards: Array<GroudChildCardUnion>;
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  type: CardType;
};

export type CardGroupCarousel = {
  __typename?: "CardGroupCarousel";
  action?: Maybe<CardAction>;
  cards: Array<GroudChildCardUnion>;
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  type: CardType;
};

export type CardGroupCollapse = {
  __typename?: "CardGroupCollapse";
  action?: Maybe<CardAction>;
  cards: Array<GroudChildCardUnion>;
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  opened?: Maybe<Scalars["Boolean"]["output"]>;
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  type: CardType;
};

export type CardIcon = {
  __typename?: "CardIcon";
  color?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  size?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type CardImage = {
  __typename?: "CardImage";
  size?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

/** This card is deprecated. Can be remove once app build 238 to 243 (included) are not used anymore */
export type CardInspection = {
  __typename?: "CardInspection";
  action?: Maybe<CardAction>;
  apartmentId: Scalars["Float"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  inspectionId: Scalars["Int"]["output"];
  landlordCompletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  roomId?: Maybe<Scalars["Float"]["output"]>;
  tenantCompletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: CardType;
};

export type CardListItem = {
  __typename?: "CardListItem";
  action?: Maybe<CardAction>;
  category?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<CardIcon>;
  id: Scalars["String"]["output"];
  image?: Maybe<CardImage>;
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: CardType;
};

export type CardPost = {
  __typename?: "CardPost";
  action?: Maybe<CardAction>;
  attachment?: Maybe<CardPostAttachment>;
  author: CardPostAuthor;
  body: Scalars["String"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  feedName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  type: CardType;
};

export type CardPostAttachment = {
  __typename?: "CardPostAttachment";
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  isDeeplink?: Maybe<Scalars["Boolean"]["output"]>;
  linkName?: Maybe<Scalars["String"]["output"]>;
  linkOpenGraph?: Maybe<OpenGraph>;
  linkUrl?: Maybe<Scalars["String"]["output"]>;
  media?: Maybe<Array<Media>>;
};

export type CardPostAuthor = {
  __typename?: "CardPostAuthor";
  firstName: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  lastName: Scalars["String"]["output"];
  profilePicture?: Maybe<Scalars["String"]["output"]>;
};

export type CardRating = {
  __typename?: "CardRating";
  action?: Maybe<CardAction>;
  category?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  feedbackQuestion?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  maxScore: Scalars["Int"]["output"];
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  question: Scalars["String"]["output"];
  ratingId: Scalars["Int"]["output"];
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  thankYouMessage: Scalars["String"]["output"];
  theme?: Maybe<Scalars["String"]["output"]>;
  type: CardType;
};

export type CardStreamFeed = {
  __typename?: "CardStreamFeed";
  action?: Maybe<CardAction>;
  category?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  ownReactions?: Maybe<Scalars["JSONObject"]["output"]>;
  reactionCounts?: Maybe<Scalars["JSONObject"]["output"]>;
  streamFeed?: Maybe<StreamFeed>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: CardType;
};

export enum CardType {
  BasicPost = "BasicPost",
  ContentPreview = "ContentPreview",
  Event = "Event",
  GroupCarousel = "GroupCarousel",
  GroupCollapse = "GroupCollapse",
  Inspection = "Inspection",
  ListItem = "ListItem",
  Post = "Post",
  Rating = "Rating",
  StreamFeed = "StreamFeed",
}

export type CardUnion =
  | CardBasicPost
  | CardContentPreview
  | CardEvent
  | CardGroupCarousel
  | CardGroupCollapse
  | CardInspection
  | CardListItem
  | CardPost
  | CardRating
  | CardStreamFeed;

export type CategoryStockList = {
  __typename?: "CategoryStockList";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  stockList: Array<StockList>;
};

export type CheckboxAnswer = {
  __typename?: "CheckboxAnswer";
  changeState?: Maybe<QuestionChangeState>;
  comment?: Maybe<Scalars["String"]["output"]>;
  media?: Maybe<Array<Scalars["String"]["output"]>>;
  value?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type CheckboxQuestion = {
  __typename?: "CheckboxQuestion";
  changeState?: Maybe<QuestionChangeState>;
  choices: Array<Scalars["String"]["output"]>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  landlordAnswer: CheckboxAnswer;
  previousAnswer?: Maybe<CheckboxAnswer>;
  status: InspectionItemStatus;
  tenantAnswer: CheckboxAnswer;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type CheckboxSurveyQuestion = {
  __typename?: "CheckboxSurveyQuestion";
  choices: Array<Scalars["String"]["output"]>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type City = {
  __typename?: "City";
  /** @deprecated Use availableUnits instead */
  availableRooms: Scalars["Int"]["output"];
  availableUnits: Scalars["Int"]["output"];
  /** @deprecated use cmsData instead */
  cmsContent?: Maybe<CityContent>;
  cmsData?: Maybe<Scalars["JSONObject"]["output"]>;
  /** @deprecated use cmsData instead */
  contentful?: Maybe<CityContent>;
  country: Country;
  createdAt: Scalars["DateTime"]["output"];
  emergencyPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  generalFeed?: Maybe<StreamFeed>;
  id: Scalars["Int"]["output"];
  invoicingCosts: Array<InvoicingCost>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CityAvailableRoomsArgs = {
  filter?: InputMaybe<RoomFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type CityAvailableUnitsArgs = {
  filter?: InputMaybe<BookableUnitFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type CityContent = {
  __typename?: "CityContent";
  description: Scalars["String"]["output"];
  image: Asset;
  name: Scalars["String"]["output"];
};

export type CityDefaultFilter = {
  countryIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  unitPublished?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum ColorStatus {
  Green = "green",
  Orange = "orange",
  Red = "red",
}

export type Comment = {
  __typename?: "Comment";
  author?: Maybe<User>;
  channelId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  isAuthor: Scalars["Boolean"]["output"];
  media: Array<Media>;
  message?: Maybe<Scalars["String"]["output"]>;
  private?: Maybe<Scalars["Boolean"]["output"]>;
  systemMessage?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CommentDefaultFilter = {
  channelId: Scalars["String"]["input"];
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Company = {
  __typename?: "Company";
  accountingCodeId: Scalars["Int"]["output"];
  addressCountry?: Maybe<Scalars["String"]["output"]>;
  addressLocality?: Maybe<Scalars["String"]["output"]>;
  bank?: Maybe<Bank>;
  /** Group invoicing line in one invoice */
  collectiveBilling?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  streetAddress?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vat?: Maybe<Scalars["String"]["output"]>;
};

export type Contact = {
  __typename?: "Contact";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  landlord: Landlord;
  tag: ContactTag;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContactTag = {
  __typename?: "ContactTag";
  color?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContactTagLandlordFilterInput = {
  contactTagId: Scalars["Int"]["input"];
  landlordId: Scalars["Int"]["input"];
};

export type Contract = {
  __typename?: "Contract";
  contractTemplate?: Maybe<ContractTemplate>;
  contractUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  isCouple?: Maybe<Scalars["Boolean"]["output"]>;
  isSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sentAt?: Maybe<Scalars["DateTime"]["output"]>;
  signatureRequestId?: Maybe<Scalars["String"]["output"]>;
  signedAt?: Maybe<Scalars["DateTime"]["output"]>;
  state: ContractState;
  stay: Stay;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type ContractFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ContractState>;
};

export enum ContractState {
  Cancelled = "cancelled",
  Declined = "declined",
  Sent = "sent",
  Signed = "signed",
}

export type ContractTemplate = {
  __typename?: "ContractTemplate";
  additionalInfoRequired?: Maybe<Scalars["Boolean"]["output"]>;
  apartments: Array<Apartment>;
  contractEnglish?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  draft: Scalars["Boolean"]["output"];
  eSignatureCustomFields?: Maybe<Array<ESignatureCustomFields>>;
  eSignatureMessage?: Maybe<Scalars["String"]["output"]>;
  eSignatureSubject?: Maybe<Scalars["String"]["output"]>;
  eSignatureTemplate?: Maybe<ESignatureTemplate>;
  eSignatureTemplateId: Scalars["String"]["output"];
  /** @deprecated use eSignatureCustomFields instead */
  hellosignCustomFields?: Maybe<Array<ESignatureCustomFields>>;
  /** @deprecated use eSignatureMessage instead */
  hellosignMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use hellosignSubject instead */
  hellosignSubject?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use eSignatureTemplate instead */
  hellosignTemplate?: Maybe<ESignatureTemplate>;
  /** @deprecated use eSignatureTemplateId instead */
  hellosignTemplateId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isCouple?: Maybe<Scalars["Boolean"]["output"]>;
  messageTemplate?: Maybe<MessageTemplate>;
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContractTemplateFilter = {
  apartmentId?: InputMaybe<Scalars["Float"]["input"]>;
  cityId?: InputMaybe<Scalars["Float"]["input"]>;
  countryId?: InputMaybe<Scalars["Float"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isCouple?: InputMaybe<Scalars["Boolean"]["input"]>;
  landlordId?: InputMaybe<Scalars["Float"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Conversation = {
  __typename?: "Conversation";
  assignee?: Maybe<User>;
  attachments?: Maybe<Array<Media>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  issue?: Maybe<Issue>;
  latestComment?: Maybe<ConversationPart>;
  latestMention?: Maybe<ConversationPart>;
  open?: Maybe<Scalars["Boolean"]["output"]>;
  participants: Array<ConversationParticipant>;
  parts: Array<ConversationPart>;
  subject: Scalars["String"]["output"];
  tags?: Maybe<Array<Tag>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ConversationDefaultFilter = {
  assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  issueId?: InputMaybe<Scalars["Int"]["input"]>;
  participantId?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  tagId?: InputMaybe<Scalars["Int"]["input"]>;
  view?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConversationPart = {
  __typename?: "ConversationPart";
  attachedUsers: Array<ConversationParticipant>;
  attachments: Array<Media>;
  author: ConversationParticipant;
  authorIsAdmin: Scalars["Boolean"]["output"];
  body: Scalars["String"]["output"];
  conversation: Conversation;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  isAuthor: Scalars["Boolean"]["output"];
  isUnread: Scalars["Boolean"]["output"];
  issue?: Maybe<Issue>;
  messageId?: Maybe<Scalars["String"]["output"]>;
  read: Scalars["Boolean"]["output"];
  readReceipts: Array<ReadReceipt>;
  status?: Maybe<ConversationPartStatus>;
  type: ConversationPartType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ConversationPartDefaultFilter = {
  conversationId: Scalars["Int"]["input"];
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ConversationPartStatus {
  Bounced = "BOUNCED",
  Deferred = "DEFERRED",
  Delivered = "DELIVERED",
  Dropped = "DROPPED",
  Processed = "PROCESSED",
}

export enum ConversationPartType {
  Assignment = "ASSIGNMENT",
  Bounced = "BOUNCED",
  Close = "CLOSE",
  CloseIssue = "CLOSE_ISSUE",
  Comment = "COMMENT",
  LinkIssue = "LINK_ISSUE",
  Note = "NOTE",
  Open = "OPEN",
  OpenIssue = "OPEN_ISSUE",
  ParticipantAdded = "PARTICIPANT_ADDED",
  Unassignment = "UNASSIGNMENT",
  UnlinkIssue = "UNLINK_ISSUE",
}

export type ConversationParticipant = ExternalParticipant | User;

export type Country = {
  __typename?: "Country";
  bank?: Maybe<Bank>;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  daysUntilMoveInInspectionAutoComplete: Scalars["Int"]["output"];
  daysUntilMoveOutInspectionAutoComplete: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  invoicingCosts: Array<InvoicingCost>;
  invoicingTenants?: Maybe<Array<InvoicingTenant>>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CreateApartmentStockItemInput = {
  apartmentId: Scalars["Int"]["input"];
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  stockItemId: Scalars["Int"]["input"];
};

export type CreateBookableUnitInput = {
  apartmentId?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  closeDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  listingStatuses?: InputMaybe<Array<BaseCreateListingStatus>>;
  openDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedNote?: InputMaybe<Scalars["String"]["input"]>;
  roomId?: InputMaybe<Scalars["Float"]["input"]>;
  sharedSpaceSize?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CreateRemoteServiceKeyInput = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name: RemoteServiceKeyNames;
};

export type CreateRoomInput = {
  apartmentId: Scalars["Int"]["input"];
  bookableUnit: CreateBookableUnitInput;
  name: Scalars["String"]["input"];
  roomAttributes?: InputMaybe<BaseRoomAttributesInput>;
  roomTypeId: Scalars["Int"]["input"];
};

export type CustomerComAttachments = {
  __typename?: "CustomerComAttachments";
  contentType: Scalars["String"]["output"];
  filesize?: Maybe<Scalars["Int"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type CustomerComConversation = {
  __typename?: "CustomerComConversation";
  conversationParts: Array<CustomerComConversationParts>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerComConversationAuthor = {
  __typename?: "CustomerComConversationAuthor";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type CustomerComConversationParts = {
  __typename?: "CustomerComConversationParts";
  attachments?: Maybe<Array<CustomerComAttachments>>;
  author: CustomerComConversationAuthor;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
};

export type DefaultFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type ESignatureCustomFields = {
  __typename?: "ESignatureCustomFields";
  fieldName: Scalars["String"]["output"];
  fieldValue: Scalars["String"]["output"];
};

export type ESignatureCustomFieldsInput = {
  fieldName: Scalars["String"]["input"];
  fieldValue: Scalars["String"]["input"];
};

export type ESignatureTemplate = {
  __typename?: "ESignatureTemplate";
  customFields?: Maybe<Array<ESignatureCustomFields>>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  signerCount: Scalars["Int"]["output"];
};

export enum EmailNotificationEvent {
  CancelMoveIn = "CANCEL_MOVE_IN",
  CancelMoveOut = "CANCEL_MOVE_OUT",
  NewMemberStay = "NEW_MEMBER_STAY",
  TerminateStay = "TERMINATE_STAY",
}

export type EmailNotificationFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EmailNotificationSetting = {
  __typename?: "EmailNotificationSetting";
  apartment?: Maybe<Apartment>;
  apartmentId?: Maybe<Scalars["Int"]["output"]>;
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars["Int"]["output"]>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars["Int"]["output"]>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars["Int"]["output"]>;
  events: Array<EmailNotificationEvent>;
  id: Scalars["Int"]["output"];
  user: User;
  userId: Scalars["Int"]["output"];
};

export type ErrorMessage = {
  __typename?: "ErrorMessage";
  key: Scalars["String"]["output"];
  params: Scalars["JSONObject"]["output"];
};

export type Event = {
  __typename?: "Event";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  end?: Maybe<Scalars["DateTime"]["output"]>;
  generalFeed?: Maybe<StreamFeed>;
  host: User;
  id: Scalars["Int"]["output"];
  link?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<EventLocation>;
  maxAttendees?: Maybe<Scalars["Int"]["output"]>;
  picture?: Maybe<Scalars["String"]["output"]>;
  responses: Array<EventResponse>;
  slug: Scalars["String"]["output"];
  start: Scalars["DateTime"]["output"];
  streamFeed: StreamFeed;
  title: Scalars["String"]["output"];
  triggerPushNotification?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  userResponse?: Maybe<EventResponse>;
};

export type EventFilter = {
  aroundMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  hostId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  myEvents?: InputMaybe<Scalars["Boolean"]["input"]>;
  past?: InputMaybe<Scalars["Boolean"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  streamFeedId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EventLocation = {
  __typename?: "EventLocation";
  formattedAddress?: Maybe<Scalars["String"]["output"]>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  placeId: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type EventLocationInput = {
  formattedAddress?: InputMaybe<Scalars["String"]["input"]>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  placeId: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
};

export type EventResponse = {
  __typename?: "EventResponse";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  event: Event;
  id: Scalars["Int"]["output"];
  type: ResponseType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type ExpenseAccount = {
  __typename?: "ExpenseAccount";
  apartment?: Maybe<Apartment>;
  building?: Maybe<Building>;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExternalParticipant = {
  __typename?: "ExternalParticipant";
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type File = {
  __typename?: "File";
  contentType?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<FileDetails>;
  fileName?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type FileDetails = {
  __typename?: "FileDetails";
  image?: Maybe<FileDetailsImage>;
  size?: Maybe<Scalars["Int"]["output"]>;
};

export type FileDetailsImage = {
  __typename?: "FileDetailsImage";
  height?: Maybe<Scalars["Int"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type FinancialEntry = {
  __typename?: "FinancialEntry";
  accountName: Scalars["String"]["output"];
  accountNumber: Scalars["Int"]["output"];
  amount: Scalars["Float"]["output"];
  amountInBaseCurrency: Scalars["Float"]["output"];
  baseCurrency: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  customerNumber?: Maybe<Scalars["Int"]["output"]>;
  date: Scalars["DateTime"]["output"];
  department?: Maybe<BusinessUnit>;
  departmentNumber?: Maybe<Scalars["Int"]["output"]>;
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  entryNumber: Scalars["Int"]["output"];
  entryType: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  invoiceNumber?: Maybe<Scalars["Int"]["output"]>;
  remainder?: Maybe<Scalars["Float"]["output"]>;
  remainderInBaseCurrency?: Maybe<Scalars["Float"]["output"]>;
  supplierNumber?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vat?: Maybe<Scalars["Float"]["output"]>;
  vatCode?: Maybe<Scalars["String"]["output"]>;
  voucherNumber?: Maybe<Scalars["Int"]["output"]>;
};

export type GroudChildCardUnion = CardContentPreview | CardInspection | CardListItem;

export enum HoldRoomReason {
  Other = "other",
  Repairs = "repairs",
  Reserved = "reserved",
}

export type InNOut = {
  __typename?: "InNOut";
  /** @deprecated use comments instead */
  commentCount: Scalars["Int"]["output"];
  comments: Array<Comment>;
  completed: Scalars["Boolean"]["output"];
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  moveInPart?: Maybe<InNOutPart>;
  moveOutPart?: Maybe<InNOutPart>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InNOutFilter = {
  assigneeIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  cityIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  countryIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  inspectionStatuses?: InputMaybe<Array<InspectionStatus>>;
  interval?: InputMaybe<Scalars["String"]["input"]>;
  issueStatus?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  templateIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type InNOutPart = {
  __typename?: "InNOutPart";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  inNOut?: Maybe<InNOut>;
  stay: Stay;
  tasks?: Maybe<Array<InNOutTask>>;
  type: InNOutType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InNOutTask = {
  __typename?: "InNOutTask";
  completed: Scalars["Boolean"]["output"];
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  completedBy?: Maybe<User>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  issue?: Maybe<Issue>;
  template?: Maybe<InNOutTaskTemplate>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum InNOutTaskKind {
  Checkbox = "checkbox",
  Issue = "issue",
}

export type InNOutTaskTemplate = {
  __typename?: "InNOutTaskTemplate";
  cities: Array<City>;
  createdAt: Scalars["DateTime"]["output"];
  disabled: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  issueAssigneeIds?: Maybe<Array<Scalars["Int"]["output"]>>;
  issueDeadlineShift?: Maybe<Scalars["Int"]["output"]>;
  issueDeadlineType?: Maybe<IssueDeadlineType>;
  issueDescription?: Maybe<Scalars["String"]["output"]>;
  issueTitle?: Maybe<Scalars["String"]["output"]>;
  kind: InNOutTaskKind;
  name: Scalars["String"]["output"];
  picture?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<IssueTag>>;
  types: Array<InNOutType>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum InNOutType {
  MemberMoveIn = "memberMoveIn",
  MemberMoveInWithin = "memberMoveInWithin",
  MemberMoveOut = "memberMoveOut",
  MemberMoveOutWithin = "memberMoveOutWithin",
  MoveIn = "moveIn",
  MoveOut = "moveOut",
}

export type Inspection = {
  __typename?: "Inspection";
  assignees?: Maybe<Array<User>>;
  conflictCount: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deadline?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  isEditable: Scalars["Boolean"]["output"];
  landlordCompletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  landlordCompletedBy?: Maybe<User>;
  media: Array<Media>;
  sections: Array<Section>;
  status: InspectionStatus;
  stay: Stay;
  tenantCompletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  tenantCompletionForcedAt?: Maybe<Scalars["DateTime"]["output"]>;
  type: InspectionType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InspectionDefaultFilter = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  cityIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  landlordCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InspectionStatus>>;
  stayId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<InspectionType>;
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum InspectionItemStatus {
  Deprecated = "Deprecated",
  Editable = "Editable",
  Uneditable = "Uneditable",
}

export enum InspectionStatus {
  Approved = "approved",
  Conflicted = "conflicted",
  Draft = "draft",
  Incomplete = "incomplete",
  Pending = "pending",
  Untouched = "untouched",
}

export type InspectionSurvey = {
  __typename?: "InspectionSurvey";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  numQuestions: Scalars["Int"]["output"];
  numSections: Scalars["Int"]["output"];
  sections: Array<SurveySection>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type InspectionSurveyDefaultFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum InspectionType {
  MoveIn = "moveIn",
  MoveOut = "moveOut",
}

export type Invoice = {
  __typename?: "Invoice";
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  dueDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  invoiceNumber?: Maybe<Scalars["String"]["output"]>;
  invoiceNumeric?: Maybe<Scalars["Int"]["output"]>;
  issueDate: Scalars["DateTime"]["output"];
  status: InvoiceStatus;
  totalAmount?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vatAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type InvoiceItem = {
  __typename?: "InvoiceItem";
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  periodEnd?: Maybe<Scalars["DateTime"]["output"]>;
  periodStart: Scalars["DateTime"]["output"];
  quantity: Scalars["Int"]["output"];
  sku: Scalars["String"]["output"];
  totalAmount: Scalars["Int"]["output"];
  unitGrossPrice: Scalars["Int"]["output"];
  unitPrice: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  vatAmount: Scalars["Int"]["output"];
  vatRate: Scalars["Int"]["output"];
};

export enum InvoiceStatus {
  Booked = "booked",
  Collecting = "collecting",
}

export type InvoicingCost = {
  __typename?: "InvoicingCost";
  activated?: Maybe<Scalars["Boolean"]["output"]>;
  /** Fixed price or % if the cost is related to another product. Can be negative */
  amount: Scalars["Float"]["output"];
  apartmentId?: Maybe<Scalars["Int"]["output"]>;
  applyOnlyToUnitCategory?: Maybe<UnitCategory>;
  buildingId?: Maybe<Scalars["Int"]["output"]>;
  cityId?: Maybe<Scalars["Int"]["output"]>;
  costsGroupedWith?: Maybe<Array<InvoicingCost>>;
  countryId?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  duration?: Maybe<Scalars["Int"]["output"]>;
  followIncreaseOfCost?: Maybe<InvoicingCost>;
  followPriceIndex?: Maybe<PriceIndex>;
  groupWithCost?: Maybe<InvoicingCost>;
  id: Scalars["Int"]["output"];
  increaseDate?: Maybe<PriceIncreaseDate>;
  invoiceDescription?: Maybe<Scalars["String"]["output"]>;
  invoicingProduct: InvoicingProduct;
  name: Scalars["String"]["output"];
  nextIncreaseDate?: Maybe<Scalars["DateTime"]["output"]>;
  nextReferencePeriod?: Maybe<Scalars["DateTime"]["output"]>;
  overrideCost?: Maybe<InvoicingCost>;
  priceIndexPrintUsedForIncrease?: Maybe<PriceIndexPrint>;
  relativeToCost?: Maybe<InvoicingCost>;
  roomId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InvoicingLine = {
  __typename?: "InvoicingLine";
  amount: Scalars["Float"]["output"];
  approveAction?: Maybe<InvoicingLineApproveAction>;
  approved: Scalars["Boolean"]["output"];
  approvedBy?: Maybe<User>;
  attachment?: Maybe<Scalars["String"]["output"]>;
  billingId?: Maybe<Scalars["String"]["output"]>;
  companyPayer?: Maybe<Company>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy?: Maybe<User>;
  currency: Scalars["String"]["output"];
  end?: Maybe<Scalars["DateTime"]["output"]>;
  hasBeenApproved: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  invoiceDescription: Scalars["String"]["output"];
  invoicingProduct: InvoicingProduct;
  invoicingTag?: Maybe<InvoicingTag>;
  isCancelled: Scalars["Boolean"]["output"];
  isRecurring: Scalars["Boolean"]["output"];
  nextIncreaseDate?: Maybe<Scalars["DateTime"]["output"]>;
  nextReferencePeriod?: Maybe<Scalars["DateTime"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  originalLine?: Maybe<InvoicingLine>;
  start?: Maybe<Scalars["DateTime"]["output"]>;
  stay: Stay;
  systemMessage?: Maybe<SystemMessage>;
  type: Array<InvoicingProductType>;
  updatedAt: Scalars["DateTime"]["output"];
  userPayer?: Maybe<User>;
  vat?: Maybe<Scalars["Float"]["output"]>;
};

export enum InvoicingLineApproveAction {
  Cancel = "cancel",
  Create = "create",
  Terminate = "terminate",
}

export type InvoicingProduct = {
  __typename?: "InvoicingProduct";
  appendStayInfoToDescription?: Maybe<Scalars["Boolean"]["output"]>;
  billingId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  firstMonthProratedInvoicingProduct?: Maybe<InvoicingProduct>;
  id: Scalars["Int"]["output"];
  invoiceDescription?: Maybe<Scalars["String"]["output"]>;
  invoicingTenant: InvoicingTenant;
  isRecurring: Scalars["Boolean"]["output"];
  lastMonthProratedInvoicingProduct?: Maybe<InvoicingProduct>;
  name: Scalars["String"]["output"];
  oneTimeDueDate?: Maybe<OneTimeDueDate>;
  refundable?: Maybe<Refundable>;
  sku: Scalars["String"]["output"];
  taxable?: Maybe<Scalars["Boolean"]["output"]>;
  type: Array<InvoicingProductType>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InvoicingProductDefaultFilter = {
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  invoicingTenantId?: InputMaybe<Scalars["Int"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum InvoicingProductType {
  Deposit = "deposit",
  Extra = "extra",
  FirstMonth = "firstMonth",
  LastMonth = "lastMonth",
  Prepaid = "prepaid",
  Rent = "rent",
  Services = "services",
  Utilities = "utilities",
}

export type InvoicingTag = {
  __typename?: "InvoicingTag";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type InvoicingTenant = {
  __typename?: "InvoicingTenant";
  approvalRequired: Scalars["Boolean"]["output"];
  bank?: Maybe<Bank>;
  billingApiKey?: Maybe<Scalars["String"]["output"]>;
  country: Country;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  invoicingProducts?: Maybe<Array<InvoicingProduct>>;
  name: Scalars["String"]["output"];
  productPlanBillingId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vat?: Maybe<Scalars["Float"]["output"]>;
};

export type InvoicingTenantDefaultFilter = {
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Issue = {
  __typename?: "Issue";
  apartment?: Maybe<Apartment>;
  assigneeStatus: IssueAssigneeStatus;
  assignees?: Maybe<Array<User>>;
  author: User;
  building?: Maybe<Building>;
  closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  commentCount: Scalars["Int"]["output"];
  comments: Array<Comment>;
  conversations: Array<Conversation>;
  createdAt: Scalars["DateTime"]["output"];
  deadline?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  /** @deprecated use serviceConversation instead */
  intercomConversation?: Maybe<CustomerComConversation>;
  /** @deprecated use serviceConversationId instead */
  intercomConversationId?: Maybe<Scalars["String"]["output"]>;
  lastPublicCommentDate?: Maybe<Scalars["DateTime"]["output"]>;
  latestPublicComment?: Maybe<Comment>;
  media: Array<Media>;
  priority: IssuePriority;
  private?: Maybe<Scalars["Boolean"]["output"]>;
  recurrence?: Maybe<Recurrence>;
  recurrencePattern?: Maybe<Scalars["String"]["output"]>;
  reportedBy?: Maybe<User>;
  room?: Maybe<Room>;
  serviceConversation?: Maybe<CustomerComConversation>;
  serviceConversationId?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<IssueTag>>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum IssueAssigneeStatus {
  Default = "default",
  Replacement = "replacement",
  Unassigned = "unassigned",
}

export enum IssueDeadlineType {
  ContractEnd = "contractEnd",
  ContractStart = "contractStart",
  MoveIn = "moveIn",
  MoveOut = "moveOut",
}

export type IssueFilter = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  deadlineFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadlineTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeTagIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  includeTagIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  landlordId?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<IssuePriority>;
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  recurrenceId?: InputMaybe<Scalars["Int"]["input"]>;
  reportedById?: InputMaybe<Scalars["Int"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  tagId?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum IssuePriority {
  High = "high",
  Low = "low",
  Medium = "medium",
  Urgent = "urgent",
}

export type IssueTag = {
  __typename?: "IssueTag";
  color?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  private?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type Landlord = {
  __typename?: "Landlord";
  contactPerson?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dashboardUrl: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type LandlordByTag = {
  __typename?: "LandlordByTag";
  contactTag: ContactTag;
  landlords: Array<Landlord>;
};

export type ListMetadata = {
  __typename?: "ListMetadata";
  count: Scalars["Int"]["output"];
};

export type Listing = {
  __typename?: "Listing";
  apartmentMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  apartmentTypeId?: Maybe<Scalars["Int"]["output"]>;
  apartmentTypeName?: Maybe<Scalars["String"]["output"]>;
  available?: Maybe<Scalars["Boolean"]["output"]>;
  availableFrom?: Maybe<Scalars["DateTime"]["output"]>;
  bedrooms?: Maybe<Scalars["Int"]["output"]>;
  buildingId?: Maybe<Scalars["Int"]["output"]>;
  buildingMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  category: UnitCategory;
  currency: Scalars["String"]["output"];
  floor?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isShared: Scalars["Boolean"]["output"];
  latitude?: Maybe<Scalars["Float"]["output"]>;
  listingTitle?: Maybe<Scalars["String"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum price of all child units or unit price if no childs */
  price?: Maybe<Scalars["Float"]["output"]>;
  roomMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  roomsCount?: Maybe<Scalars["Int"]["output"]>;
  size?: Maybe<Scalars["Float"]["output"]>;
};

export type ListingDetail = {
  __typename?: "ListingDetail";
  addressLocality?: Maybe<Scalars["String"]["output"]>;
  apartmentAttributes?: Maybe<ApartmentAttributes>;
  apartmentId: Scalars["Int"]["output"];
  apartmentMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  apartmentTypeId?: Maybe<Scalars["Int"]["output"]>;
  apartmentTypeName?: Maybe<Scalars["String"]["output"]>;
  available?: Maybe<Scalars["Boolean"]["output"]>;
  availableFrom?: Maybe<Scalars["DateTime"]["output"]>;
  buildingAttributes?: Maybe<BuildingAttributes>;
  buildingId?: Maybe<Scalars["Int"]["output"]>;
  buildingMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  category?: Maybe<UnitCategory>;
  currency: Scalars["String"]["output"];
  deposit?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  maxMonthsRentalPeriod?: Maybe<Scalars["Int"]["output"]>;
  minMonthsRentalPeriod?: Maybe<Scalars["Int"]["output"]>;
  organisation: Organisation;
  price?: Maybe<Scalars["Float"]["output"]>;
  rentalPeriodType?: Maybe<RentalPeriodType>;
  roomAttributes?: Maybe<RoomAttributes>;
  roomMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  terminationNoticePeriod?: Maybe<Scalars["Int"]["output"]>;
};

export type ListingFilter = {
  airConditioning?: InputMaybe<Scalars["Boolean"]["input"]>;
  apartmentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  availableFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  balconyOrTerrace?: InputMaybe<Scalars["Boolean"]["input"]>;
  bikePark?: InputMaybe<Scalars["Boolean"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  canteen?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  cinemaRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  communalKitchen?: InputMaybe<Scalars["Boolean"]["input"]>;
  coworkingSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  desk?: InputMaybe<Scalars["Boolean"]["input"]>;
  dishwasher?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryer?: InputMaybe<Scalars["Boolean"]["input"]>;
  elevator?: InputMaybe<Scalars["Boolean"]["input"]>;
  ensuiteBathroom?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeCarPark?: InputMaybe<Scalars["Boolean"]["input"]>;
  furnished?: InputMaybe<Scalars["Boolean"]["input"]>;
  kitchen?: InputMaybe<Scalars["Boolean"]["input"]>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  laundryRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  linenAndTowels?: InputMaybe<Scalars["Boolean"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  maxBedrooms?: InputMaybe<Scalars["Int"]["input"]>;
  maxMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  maxPrice?: InputMaybe<Scalars["Int"]["input"]>;
  maxSize?: InputMaybe<Scalars["Int"]["input"]>;
  minBedrooms?: InputMaybe<Scalars["Int"]["input"]>;
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  minPrice?: InputMaybe<Scalars["Int"]["input"]>;
  minSize?: InputMaybe<Scalars["Int"]["input"]>;
  outdoorSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  paidCarPark?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  privateBalcony?: InputMaybe<Scalars["Boolean"]["input"]>;
  sharedBathroom?: InputMaybe<Scalars["Boolean"]["input"]>;
  sharedStorage?: InputMaybe<Scalars["Boolean"]["input"]>;
  sportFacilities?: InputMaybe<Scalars["Boolean"]["input"]>;
  storage?: InputMaybe<Scalars["Boolean"]["input"]>;
  tenantId?: InputMaybe<Scalars["String"]["input"]>;
  tv?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<UnitType>;
  washer?: InputMaybe<Scalars["Boolean"]["input"]>;
  wellnessFacilities?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  wifi?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ListingMetadata = {
  __typename?: "ListingMetadata";
  count: Scalars["Int"]["output"];
  maxPrice?: Maybe<Scalars["Float"]["output"]>;
  maxSize?: Maybe<Scalars["Float"]["output"]>;
  minPrice?: Maybe<Scalars["Float"]["output"]>;
  minSize?: Maybe<Scalars["Float"]["output"]>;
};

export enum ListingPlatform {
  BoligPortal = "BOLIG_PORTAL",
  HousingAnywhere = "HOUSING_ANYWHERE",
}

export enum ListingSortField {
  Added = "added",
  Price = "price",
}

export type ListingStatus = {
  __typename?: "ListingStatus";
  errorMessage?: Maybe<ErrorMessage>;
  id: Scalars["Int"]["output"];
  isEnabled: Scalars["Boolean"]["output"];
  listingId?: Maybe<Scalars["String"]["output"]>;
  platform: ListingPlatform;
};

export type ListingStatusOutput = {
  __typename?: "ListingStatusOutput";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isEnabled: Scalars["Boolean"]["output"];
  platform: ListingPlatform;
};

export type ListingType = {
  __typename?: "ListingType";
  /** Expensive query (N+1), avoid using it when querying for the listings list */
  apartment: Apartment;
  apartmentAddressLocality?: Maybe<Scalars["String"]["output"]>;
  apartmentBathrooms?: Maybe<Scalars["Int"]["output"]>;
  apartmentBedrooms?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated use apartmentCmsData instead */
  apartmentCmsContent?: Maybe<ApartmentContent>;
  apartmentCmsData?: Maybe<Scalars["JSONObject"]["output"]>;
  apartmentId: Scalars["Int"]["output"];
  apartmentLatitude?: Maybe<Scalars["Float"]["output"]>;
  apartmentLongitude?: Maybe<Scalars["Float"]["output"]>;
  apartmentMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  apartmentName: Scalars["String"]["output"];
  apartmentPostalCode?: Maybe<Scalars["String"]["output"]>;
  apartmentRooms?: Maybe<Scalars["Int"]["output"]>;
  apartmentSize?: Maybe<Scalars["Float"]["output"]>;
  apartmentSlug: Scalars["String"]["output"];
  apartmentStreetAddress?: Maybe<Scalars["String"]["output"]>;
  apartmentSuburb?: Maybe<Scalars["String"]["output"]>;
  apartmentTypeDescription?: Maybe<Scalars["String"]["output"]>;
  apartmentTypeName?: Maybe<Scalars["String"]["output"]>;
  available?: Maybe<Scalars["Boolean"]["output"]>;
  availableFrom?: Maybe<Scalars["DateTime"]["output"]>;
  /**
   * Number of available child units only or null if no childs
   * @deprecated Use availableUnits instead.
   */
  availableRooms?: Maybe<Scalars["Int"]["output"]>;
  /** Number of available child units only or null if no childs */
  availableUnits?: Maybe<Scalars["Int"]["output"]>;
  /** Expensive query (N+1), avoid using it when querying for the listings list */
  bookableUnits: Array<BookableUnit>;
  buildingMedia?: Maybe<Array<Scalars["String"]["output"]>>;
  cityName: Scalars["String"]["output"];
  citySlug: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  /** Minimum price of all child units or unit price if no childs */
  minPrice?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum price of available child units only or unit price if no childs */
  startingPrice?: Maybe<Scalars["Int"]["output"]>;
  /**
   * Number of all child units or null if no childs
   * @deprecated Use totalUnits instead.
   */
  totalRooms?: Maybe<Scalars["Int"]["output"]>;
  /** Number of all child units or null if no childs */
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
  unitType: UnitType;
};

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  mediaType: MediaType;
  updatedAt: Scalars["DateTime"]["output"];
  uri: Scalars["String"]["output"];
};

export enum MediaType {
  File = "file",
  Image = "image",
  Video = "video",
}

export type MessageTemplate = {
  __typename?: "MessageTemplate";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  message: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  subject?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type MessageTemplateFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type MetricsDashboard = {
  __typename?: "MetricsDashboard";
  id: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** @deprecated use createFeedComment */
  addComment?: Maybe<ReactionWithUser>;
  addParticipants?: Maybe<Conversation>;
  approve: InvoicingLine;
  approveInspection?: Maybe<Inspection>;
  assignAsHousekeeper: User;
  assignAsOpsManager: User;
  assignConversation: Conversation;
  bulkUpdateApartments: Array<Scalars["Int"]["output"]>;
  bulkUpdateRooms: Array<Scalars["Int"]["output"]>;
  cancelContract: Contract;
  cancelMoveIn: Stay;
  cancelMoveOut: Stay;
  closeConversation: Conversation;
  closeIssue: Issue;
  completeInspection?: Maybe<Inspection>;
  createApartment: Apartment;
  createApartmentStockItem: ApartmentStockItem;
  createApartmentStockItems: Array<ApartmentStockItem>;
  createApartmentType: ApartmentType;
  createAvailableUnitAlertSubscription: AvailableUnitAlertSubscription;
  createBank: Bank;
  createBookableUnit: BookableUnit;
  createBuilding: Building;
  createCity: City;
  /** @deprecated Use createIssueComment instead */
  createComment: Comment;
  createCommentV2: Comment;
  createCompany: Company;
  createContact: Contact;
  createContactTag: ContactTag;
  createContractTemplate: ContractTemplate;
  createConversation: Conversation;
  createConversationPart: ConversationPart;
  createCountry: Country;
  createEmailNotificationSetting: EmailNotificationSetting;
  createEvent: Event;
  createEventResponse: EventResponse;
  createExpenseAccount: ExpenseAccount;
  createFeedComment?: Maybe<ReactionWithUser>;
  createHoldRoomStay: Stay;
  createInNOutTaskTemplate: InNOutTaskTemplate;
  createInspection: Inspection;
  createInspectionSurvey: InspectionSurvey;
  createInvoicingCost: InvoicingCost;
  createInvoicingLine: InvoicingLine;
  createInvoicingLinesFromCost: Array<InvoicingLine>;
  createInvoicingProduct: InvoicingProduct;
  createInvoicingTag: InvoicingTag;
  createInvoicingTenant: InvoicingTenant;
  createIssue: Issue;
  createIssueComment: Comment;
  createIssueTag: IssueTag;
  createLandlord: Landlord;
  createMessageTemplate: MessageTemplate;
  createPost: Post;
  createPriceIndex: PriceIndex;
  createPriceIndexPrint: PriceIndexPrint;
  createRating: Rating;
  createRatingAnswer: RatingAnswer;
  createRemoteService: RemoteService;
  createRole: Role;
  createRoom: Room;
  createRoomType: RoomType;
  createSpecificationAttribute: SpecificationAttribute;
  createSpecificationCategory: SpecificationCategory;
  createSpecificationValue: SpecificationValue;
  createStay: Stay;
  createStockItem: StockItem;
  createStockItemCategory: StockItemCategory;
  createStreamFeed: StreamFeed;
  createTerminationReason: TerminationReason;
  createUnitMedia: Array<UnitMedia>;
  createUser: User;
  deleteApartment: Apartment;
  deleteApartmentStockItem: ApartmentStockItem;
  deleteApartmentType: ApartmentType;
  deleteBank: Bank;
  deleteBuilding: Building;
  deleteCity: City;
  deleteComment: Comment;
  deleteCompany: Company;
  deleteContact: Contact;
  deleteContactTag: ContactTag;
  deleteContract: Contract;
  deleteContractTemplate: ContractTemplate;
  deleteCountry: Country;
  deleteEmailNotificationSetting: EmailNotificationSetting;
  deleteEvent: Event;
  deleteExpenseAccount?: Maybe<ExpenseAccount>;
  deleteInNOutTask: InNOutTask;
  deleteInNOutTaskTemplate: InNOutTaskTemplate;
  deleteInspection: Inspection;
  deleteInspectionSurvey: InspectionSurvey;
  deleteInvoicingCost: InvoicingCost;
  deleteInvoicingLine: InvoicingLine;
  deleteInvoicingProduct: InvoicingProduct;
  deleteInvoicingTag: InvoicingTag;
  deleteInvoicingTenant: InvoicingTenant;
  deleteIssue?: Maybe<Issue>;
  deleteIssueTag: IssueTag;
  deleteLandlord: Landlord;
  deleteMessageTemplate: MessageTemplate;
  deletePost: Post;
  deletePriceIndex: PriceIndex;
  deletePriceIndexPrint: PriceIndexPrint;
  deleteRating: Rating;
  deleteRemoteService: RemoteService;
  deleteRole: Role;
  deleteRoom: Room;
  deleteRoomType: RoomType;
  deleteSpecificationAttribute: SpecificationAttribute;
  deleteSpecificationCategory: SpecificationCategory;
  deleteSpecificationValue: SpecificationValue;
  deleteStay: Stay;
  deleteStockItem: StockItem;
  deleteStockItemCategory: StockItemCategory;
  deleteStreamFeed: StreamFeed;
  deleteTerminationReason: TerminationReason;
  deleteUnitMedia: UnitMedia;
  deleteUser: User;
  duplicateContractTemplate: ContractTemplate;
  duplicateInvoicingLine: InvoicingLine;
  followStreamFeed: StreamFeed;
  generatePriceIncreaseForPriceIndex: Scalars["String"]["output"];
  linkConversationToIssue: Conversation;
  linkIssueToConversations: Issue;
  /** Mark a notification read. Mark all notifications read when id is null */
  markNotificationRead: Scalars["String"]["output"];
  parseText: Scalars["String"]["output"];
  reassignApartmentInspections: Array<Inspection>;
  reassignApartmentIssues: Array<Issue>;
  redactUser: User;
  rejectInvoicingLine: InvoicingLine;
  removeReaction: Scalars["Boolean"]["output"];
  reopenConversation: Conversation;
  reverseInvoicingLine: InvoicingLine;
  sendContract: Scalars["String"]["output"];
  setHasAcceptedHouseRules: User;
  setHasCompletedOnboardingProfile: User;
  terminateStay: Stay;
  terminateStayByAdmin: Stay;
  terminateStayV2: Stay;
  toggleLike?: Maybe<Reaction>;
  unassignAsHousekeeper: User;
  unassignAsOpsManager: User;
  unassignConversation: Conversation;
  unfollowStreamFeed: StreamFeed;
  unlinkConversationFromIssue: Conversation;
  updateApartment: Apartment;
  updateApartmentStockItem: ApartmentStockItem;
  updateApartmentType: ApartmentType;
  updateBank: Bank;
  updateBookableUnit: BookableUnit;
  updateBuilding: Building;
  updateCity: City;
  /** @deprecated Use updateFeedComment */
  updateComment?: Maybe<ReactionWithUser>;
  updateCompany: Company;
  updateContact: Contact;
  updateContactTag: ContactTag;
  updateContractTemplate: ContractTemplate;
  updateCountry: Country;
  updateEmailNotificationSetting: EmailNotificationSetting;
  updateEvent: Event;
  updateEventResponse: EventResponse;
  updateExpenseAccount?: Maybe<ExpenseAccount>;
  updateFeedComment?: Maybe<ReactionWithUser>;
  updateGenericComment: Comment;
  updateHoldRoomStay: Stay;
  updateInNOut: InNOut;
  updateInNOutTask: InNOutTask;
  updateInNOutTaskTemplate: InNOutTaskTemplate;
  updateInspection: Inspection;
  updateInspectionAssignees: Inspection;
  updateInspectionSurvey: InspectionSurvey;
  updateInvoicingCost: InvoicingCost;
  updateInvoicingLine: InvoicingLine;
  updateInvoicingProduct: InvoicingProduct;
  updateInvoicingTag: InvoicingTag;
  updateInvoicingTenant: InvoicingTenant;
  updateIssue: Issue;
  updateIssueTag: IssueTag;
  updateLandlord: Landlord;
  updateMessageTemplate: MessageTemplate;
  updateMoveIn?: Maybe<Stay>;
  updateMoveOut?: Maybe<Stay>;
  updateOrganisation: Organisation;
  updatePermission: Permission;
  updatePost: Post;
  updatePriceIndex: PriceIndex;
  updatePriceIndexPrint: PriceIndexPrint;
  updateRating: Rating;
  updateRemoteService: RemoteService;
  updateRole: Role;
  updateRoom: Room;
  updateRoomType: RoomType;
  updateSpecificationAttribute: SpecificationAttribute;
  updateSpecificationCategory: SpecificationCategory;
  updateSpecificationValue: SpecificationValue;
  updateStay: Stay;
  updateStockItem: StockItem;
  updateStockItemCategory: StockItemCategory;
  updateStreamFeed: StreamFeed;
  updateTerminationReason: TerminationReason;
  updateUnitMediaRank: UnitMedia;
  updateUser: User;
  updateUserLanguageCode: User;
  updateUserProfile: User;
  uploadContract?: Maybe<Contract>;
  uploadProfilePicture: User;
};

export type MutationAddCommentArgs = {
  isChild?: InputMaybe<Scalars["Boolean"]["input"]>;
  parentId: Scalars["String"]["input"];
  parentUserId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
};

export type MutationAddParticipantsArgs = {
  apartmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  buildingIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  conversationId: Scalars["Int"]["input"];
  participantIds: Array<Scalars["String"]["input"]>;
};

export type MutationApproveArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationApproveInspectionArgs = {
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  id: Scalars["Int"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  sections?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
};

export type MutationAssignAsHousekeeperArgs = {
  apartmentId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationAssignAsOpsManagerArgs = {
  apartmentId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationAssignConversationArgs = {
  assigneeId: Scalars["Int"]["input"];
  conversationId: Scalars["Int"]["input"];
};

export type MutationBulkUpdateApartmentsArgs = {
  data: BulkUpdateApartmentsData;
  filter?: InputMaybe<ApartmentDefaultFilter>;
  ignoreErrors?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationBulkUpdateRoomsArgs = {
  data: BulkUpdateRoomsData;
  filter?: InputMaybe<RoomFilter>;
  ignoreErrors?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCancelContractArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationCancelMoveInArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationCancelMoveOutArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationCloseConversationArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationCloseIssueArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationCompleteInspectionArgs = {
  id: Scalars["Int"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
};

export type MutationCreateApartmentArgs = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  apartmentAttributes?: InputMaybe<BaseApartmentAttributesInput>;
  apartmentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingAttributes?: InputMaybe<BaseBuildingAttributesInput>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId: Scalars["Int"]["input"];
  contractAdditionalInfo?: InputMaybe<Scalars["Upload"]["input"]>;
  contractAdditionalInfoEnglish?: InputMaybe<Scalars["Upload"]["input"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  coupleContractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  createBookableUnit?: InputMaybe<CreateBookableUnitInput>;
  createContacts?: InputMaybe<Array<BaseCreateContactInput>>;
  createRooms?: InputMaybe<Array<BaseCreateRoomInput>>;
  deposit?: InputMaybe<Scalars["Int"]["input"]>;
  floorPlan?: InputMaybe<Scalars["Upload"]["input"]>;
  generalFeedId?: InputMaybe<Scalars["Float"]["input"]>;
  housekeepersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  maxMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  opsManagersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  rent?: InputMaybe<Scalars["Int"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  signedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateApartmentStockItemArgs = {
  apartmentId: Scalars["Int"]["input"];
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  stockItemId: Scalars["Int"]["input"];
};

export type MutationCreateApartmentStockItemsArgs = {
  data: Array<CreateApartmentStockItemInput>;
};

export type MutationCreateApartmentTypeArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  internalName: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationCreateAvailableUnitAlertSubscriptionArgs = {
  availableFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  cityId?: InputMaybe<Scalars["Float"]["input"]>;
  email: Scalars["String"]["input"];
  frequency: AvailableUnitAlertFrequency;
  maxPrice?: InputMaybe<Scalars["Float"]["input"]>;
  minPrice?: InputMaybe<Scalars["Float"]["input"]>;
  tenantId?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<UnitType>;
};

export type MutationCreateBankArgs = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAddress?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  iban?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  personalAddress?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  swiftBic?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateBookableUnitArgs = {
  apartmentId?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  closeDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  listingStatuses?: InputMaybe<Array<BaseCreateListingStatus>>;
  openDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedNote?: InputMaybe<Scalars["String"]["input"]>;
  roomId?: InputMaybe<Scalars["Float"]["input"]>;
  sharedSpaceSize?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationCreateBuildingArgs = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  buildingAttributes?: InputMaybe<BaseBuildingAttributesInput>;
  businessUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId: Scalars["Int"]["input"];
  constructionYear?: InputMaybe<Scalars["Int"]["input"]>;
  createApartments?: InputMaybe<Array<BaseCreateApartmentInput>>;
  createContacts?: InputMaybe<Array<BaseCreateContactInput>>;
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateCityArgs = {
  countryId: Scalars["Int"]["input"];
  emergencyPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  generalFeedId?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationCreateCommentArgs = {
  issueId: Scalars["Int"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  message: Scalars["String"]["input"];
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateCommentV2Args = {
  channelId: Scalars["String"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  message: Scalars["String"]["input"];
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateCompanyArgs = {
  addressCountry?: InputMaybe<Scalars["String"]["input"]>;
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["Float"]["input"]>;
  collectiveBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  vat?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateContactArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  landlordId: Scalars["Int"]["input"];
  tagId: Scalars["Int"]["input"];
};

export type MutationCreateContactTagArgs = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationCreateContractTemplateArgs = {
  additionalInfoRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  contractEnglish?: InputMaybe<Scalars["Upload"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  eSignatureCustomFields?: InputMaybe<Array<ESignatureCustomFieldsInput>>;
  eSignatureMessage?: InputMaybe<Scalars["String"]["input"]>;
  eSignatureSubject?: InputMaybe<Scalars["String"]["input"]>;
  eSignatureTemplateId: Scalars["String"]["input"];
  messageTemplateId?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationCreateConversationArgs = {
  apartmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  attachmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  attachments?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  buildingIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  issueId?: InputMaybe<Scalars["Int"]["input"]>;
  participantIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subject: Scalars["String"]["input"];
  tagIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationCreateConversationPartArgs = {
  attachments?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  body: Scalars["String"]["input"];
  conversationId: Scalars["Int"]["input"];
  type: ConversationPartType;
};

export type MutationCreateCountryArgs = {
  currency: Scalars["String"]["input"];
  daysUntilMoveInInspectionAutoComplete: Scalars["Int"]["input"];
  daysUntilMoveOutInspectionAutoComplete: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationCreateEmailNotificationSettingArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  events?: InputMaybe<Array<EmailNotificationEvent>>;
  userId: Scalars["Int"]["input"];
};

export type MutationCreateEventArgs = {
  description: Scalars["String"]["input"];
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  hostId?: InputMaybe<Scalars["Int"]["input"]>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<EventLocationInput>;
  maxAttendees?: InputMaybe<Scalars["Int"]["input"]>;
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
  start: Scalars["DateTime"]["input"];
  streamFeedId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
  triggerPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateEventResponseArgs = {
  eventId: Scalars["Int"]["input"];
  type: ResponseType;
};

export type MutationCreateExpenseAccountArgs = {
  apartmentId?: InputMaybe<Scalars["Float"]["input"]>;
  buildingId?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationCreateFeedCommentArgs = {
  isChild?: InputMaybe<Scalars["Boolean"]["input"]>;
  parentId: Scalars["String"]["input"];
  parentUserId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
};

export type MutationCreateHoldRoomStayArgs = {
  bookableUnitId: Scalars["Int"]["input"];
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart: Scalars["DateTime"]["input"];
  holdRoomReason: HoldRoomReason;
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateInNOutTaskTemplateArgs = {
  citiesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  issueAssigneeIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  issueDeadlineShift?: InputMaybe<Scalars["Int"]["input"]>;
  issueDeadlineType?: InputMaybe<IssueDeadlineType>;
  issueDescription?: InputMaybe<Scalars["String"]["input"]>;
  issueTitle?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<InNOutTaskKind>;
  name: Scalars["String"]["input"];
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
  tagsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  types?: InputMaybe<Array<InNOutType>>;
};

export type MutationCreateInspectionArgs = {
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  stayId: Scalars["Int"]["input"];
  surveyId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateInspectionSurveyArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type MutationCreateInvoicingCostArgs = {
  activated?: InputMaybe<Scalars["Boolean"]["input"]>;
  amount: Scalars["Float"]["input"];
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  applyOnlyToUnitCategory?: InputMaybe<UnitCategory>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  followIncreaseOfCostId?: InputMaybe<Scalars["Int"]["input"]>;
  followPriceIndexId?: InputMaybe<Scalars["Int"]["input"]>;
  groupWithCostId?: InputMaybe<Scalars["Int"]["input"]>;
  increaseDate?: InputMaybe<PriceIncreaseDate>;
  invoiceDescription?: InputMaybe<Scalars["String"]["input"]>;
  invoicingProductId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  nextReferencePeriod?: InputMaybe<Scalars["DateTime"]["input"]>;
  overrideCostId?: InputMaybe<Scalars["Int"]["input"]>;
  relativeToCostId?: InputMaybe<Scalars["Int"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateInvoicingLineArgs = {
  amount: Scalars["Float"]["input"];
  attachment?: InputMaybe<Scalars["Upload"]["input"]>;
  companyPayerId?: InputMaybe<Scalars["Int"]["input"]>;
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  invoiceDescription: Scalars["String"]["input"];
  invoicingCostId?: InputMaybe<Scalars["Int"]["input"]>;
  invoicingProductId: Scalars["Int"]["input"];
  invoicingTagId?: InputMaybe<Scalars["Int"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  start?: InputMaybe<Scalars["DateTime"]["input"]>;
  stayId: Scalars["Int"]["input"];
  userPayerId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateInvoicingLinesFromCostArgs = {
  amount: Scalars["Float"]["input"];
  companyPayerDuration?: InputMaybe<Scalars["Int"]["input"]>;
  companyPayerId?: InputMaybe<Scalars["Int"]["input"]>;
  costId: Scalars["Int"]["input"];
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  followIncreaseOfCostId?: InputMaybe<Scalars["Int"]["input"]>;
  followPriceIndexId?: InputMaybe<Scalars["Int"]["input"]>;
  invoiceDescription: Scalars["String"]["input"];
  nextIncreaseDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  nextReferencePeriod?: InputMaybe<Scalars["DateTime"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  priceIndexPrintUsedForIncreaseId?: InputMaybe<Scalars["Int"]["input"]>;
  start?: InputMaybe<Scalars["DateTime"]["input"]>;
  stayId: Scalars["Int"]["input"];
  userPayerId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateInvoicingProductArgs = {
  appendStayInfoToDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]["input"]>;
  invoicingTenantId: Scalars["Int"]["input"];
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  oneTimeDueDate?: InputMaybe<OneTimeDueDateInput>;
  refundable?: InputMaybe<RefundableInput>;
  sku: Scalars["String"]["input"];
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<Array<InvoicingProductType>>;
};

export type MutationCreateInvoicingTagArgs = {
  name: Scalars["String"]["input"];
};

export type MutationCreateInvoicingTenantArgs = {
  approvalRequired: Scalars["Boolean"]["input"];
  bankId?: InputMaybe<Scalars["Float"]["input"]>;
  billingApiKey?: InputMaybe<Scalars["String"]["input"]>;
  countryId: Scalars["Float"]["input"];
  name: Scalars["String"]["input"];
  productPlanBillingId?: InputMaybe<Scalars["String"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationCreateIssueArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  intercomConversationId?: InputMaybe<Scalars["String"]["input"]>;
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  priority?: InputMaybe<IssuePriority>;
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurrencePattern?: InputMaybe<Scalars["String"]["input"]>;
  reportedById?: InputMaybe<Scalars["Int"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
  serviceConversationId?: InputMaybe<Scalars["String"]["input"]>;
  tagsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type MutationCreateIssueCommentArgs = {
  issueId: Scalars["Int"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  message: Scalars["String"]["input"];
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateIssueTagArgs = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateLandlordArgs = {
  contactPerson?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateMessageTemplateArgs = {
  message: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreatePostArgs = {
  authorId?: InputMaybe<Scalars["Int"]["input"]>;
  body: Scalars["String"]["input"];
  expireAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  frequency?: InputMaybe<Scalars["String"]["input"]>;
  ignoreOg?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeeplink?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
  streamFeedId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  triggerPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreatePriceIndexArgs = {
  countryId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  periodicity: PriceIndexPeriodicity;
  sourceUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreatePriceIndexPrintArgs = {
  cap?: InputMaybe<Scalars["Float"]["input"]>;
  period: Scalars["DateTime"]["input"];
  priceIndexId: Scalars["Int"]["input"];
  value: Scalars["Float"]["input"];
};

export type MutationCreateRatingArgs = {
  expireAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedbackQuestion?: InputMaybe<Scalars["String"]["input"]>;
  frequency?: InputMaybe<Scalars["String"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxScore: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  question: Scalars["String"]["input"];
  streamFeedId: Scalars["Int"]["input"];
  thankYouMessage: Scalars["String"]["input"];
  triggerPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateRatingAnswerArgs = {
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  ratingId: Scalars["Int"]["input"];
  score: Scalars["Float"]["input"];
  streamActivityId: Scalars["String"]["input"];
};

export type MutationCreateRemoteServiceArgs = {
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  keys?: InputMaybe<Array<CreateRemoteServiceKeyInput>>;
  name: RemoteServiceName;
  settings?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type MutationCreateRoleArgs = {
  name: Scalars["String"]["input"];
};

export type MutationCreateRoomArgs = {
  apartmentId: Scalars["Int"]["input"];
  bookableUnit: CreateBookableUnitInput;
  name: Scalars["String"]["input"];
  roomAttributes?: InputMaybe<BaseRoomAttributesInput>;
  roomTypeId: Scalars["Int"]["input"];
};

export type MutationCreateRoomTypeArgs = {
  name: Scalars["String"]["input"];
};

export type MutationCreateSpecificationAttributeArgs = {
  categoryId: Scalars["Int"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["Upload"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  type: SpecificationAttributeType;
};

export type MutationCreateSpecificationCategoryArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  unitType: SpecificationUnitType;
};

export type MutationCreateSpecificationValueArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  attributeId: Scalars["Int"]["input"];
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
  value: Scalars["String"]["input"];
};

export type MutationCreateStayArgs = {
  bookableUnitId: Scalars["Int"]["input"];
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart: Scalars["DateTime"]["input"];
  memberMove: Scalars["Boolean"]["input"];
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  moveIn?: InputMaybe<Scalars["DateTime"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  spouseId?: InputMaybe<Scalars["Int"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  userId: Scalars["Int"]["input"];
};

export type MutationCreateStockItemArgs = {
  brand: Scalars["String"]["input"];
  categoryId: Scalars["Int"]["input"];
  cityId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  quantityPerApartment?: InputMaybe<Scalars["Int"]["input"]>;
  quantityPerPerson?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationCreateStockItemCategoryArgs = {
  name: Scalars["String"]["input"];
};

export type MutationCreateStreamFeedArgs = {
  backgroundImage?: InputMaybe<Scalars["Upload"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  canJoinLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  canPostIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName: Scalars["String"]["input"];
  hideFollowers?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAudienceListed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationCreateTerminationReasonArgs = {
  name: Scalars["String"]["input"];
};

export type MutationCreateUnitMediaArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  media: Array<Scalars["Upload"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateUserArgs = {
  bankId?: InputMaybe<Scalars["Float"]["input"]>;
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  hasAcceptedHouseRules?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasCompletedOnboardingProfile?: InputMaybe<Scalars["Boolean"]["input"]>;
  immutableFirstName?: InputMaybe<Scalars["String"]["input"]>;
  immutableLastName?: InputMaybe<Scalars["String"]["input"]>;
  languageCode?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  rolesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationDeleteApartmentArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteApartmentStockItemArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteApartmentTypeArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteBankArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteBuildingArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteCityArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteCommentArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteContactArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteContactTagArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteContractArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteContractTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteCountryArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteEmailNotificationSettingArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteEventArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteExpenseAccountArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInNOutTaskArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInNOutTaskTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInspectionArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInspectionSurveyArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInvoicingCostArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInvoicingLineArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInvoicingProductArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInvoicingTagArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteInvoicingTenantArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteIssueArgs = {
  id: Scalars["Int"]["input"];
  updateBehaviour?: InputMaybe<RecurringUpdateBehaviour>;
};

export type MutationDeleteIssueTagArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteLandlordArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteMessageTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeletePostArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeletePriceIndexArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeletePriceIndexPrintArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteRatingArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteRemoteServiceArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteRoomArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteRoomTypeArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteSpecificationAttributeArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteSpecificationCategoryArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteSpecificationValueArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteStayArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteStockItemArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteStockItemCategoryArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteStreamFeedArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteTerminationReasonArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteUnitMediaArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDuplicateContractTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDuplicateInvoicingLineArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationFollowStreamFeedArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationGeneratePriceIncreaseForPriceIndexArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationLinkConversationToIssueArgs = {
  conversationId: Scalars["Int"]["input"];
  issueId: Scalars["Int"]["input"];
};

export type MutationLinkIssueToConversationsArgs = {
  conversationIds: Array<Scalars["Int"]["input"]>;
  issueId: Scalars["Int"]["input"];
};

export type MutationMarkNotificationReadArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationParseTextArgs = {
  stayId: Scalars["Int"]["input"];
  text: Scalars["String"]["input"];
};

export type MutationReassignApartmentInspectionsArgs = {
  apartmentId: Scalars["Int"]["input"];
  newAssigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  oldAssigneeId: Scalars["Int"]["input"];
};

export type MutationReassignApartmentIssuesArgs = {
  apartmentId: Scalars["Int"]["input"];
  newAssigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  oldAssigneeId: Scalars["Int"]["input"];
};

export type MutationRedactUserArgs = {
  userId: Scalars["Int"]["input"];
};

export type MutationRejectInvoicingLineArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationRemoveReactionArgs = {
  reactionId: Scalars["String"]["input"];
};

export type MutationReopenConversationArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationReverseInvoicingLineArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationSendContractArgs = {
  contractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  eSignatureCustomFields?: InputMaybe<Array<ESignatureCustomFieldsInput>>;
  eSignatureMessage?: InputMaybe<Scalars["String"]["input"]>;
  eSignatureSubject?: InputMaybe<Scalars["String"]["input"]>;
  shouldCancelPreviousContracts?: InputMaybe<Scalars["Boolean"]["input"]>;
  stayId: Scalars["Int"]["input"];
};

export type MutationSetHasAcceptedHouseRulesArgs = {
  value: Scalars["Boolean"]["input"];
};

export type MutationSetHasCompletedOnboardingProfileArgs = {
  value: Scalars["Boolean"]["input"];
};

export type MutationTerminateStayArgs = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAddress?: InputMaybe<Scalars["String"]["input"]>;
  bankLocation: Scalars["String"]["input"];
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  contractEnd: Scalars["DateTime"]["input"];
  ibanNumber?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  moveOut?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  personalAddress?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  surname: Scalars["String"]["input"];
  swiftNumber?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonFeedback?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonId?: InputMaybe<Scalars["Int"]["input"]>;
  terminationReasonsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationTerminateStayByAdminArgs = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAddress?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  contractEnd: Scalars["DateTime"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  ibanNumber?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  moveOut?: InputMaybe<Scalars["DateTime"]["input"]>;
  personalAddress?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  swiftNumber?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonFeedback?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationTerminateStayV2Args = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAddress?: InputMaybe<Scalars["String"]["input"]>;
  bankLocation: Scalars["String"]["input"];
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  contractEnd: Scalars["DateTime"]["input"];
  ibanNumber?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  moveOut?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  personalAddress?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  surname: Scalars["String"]["input"];
  swiftNumber?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonFeedback?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonId?: InputMaybe<Scalars["Int"]["input"]>;
  terminationReasonsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationToggleLikeArgs = {
  isChild?: InputMaybe<Scalars["Boolean"]["input"]>;
  parentId: Scalars["String"]["input"];
  parentUserId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationUnassignAsHousekeeperArgs = {
  apartmentId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationUnassignAsOpsManagerArgs = {
  apartmentId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationUnassignConversationArgs = {
  conversationId: Scalars["Int"]["input"];
};

export type MutationUnfollowStreamFeedArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationUnlinkConversationFromIssueArgs = {
  conversationId: Scalars["Int"]["input"];
};

export type MutationUpdateApartmentArgs = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  apartmentAttributes?: InputMaybe<UpdateApartmentAttributesInput>;
  apartmentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  bookableUnit?: InputMaybe<UpdateBookableUnitInput>;
  buildingAttributes?: InputMaybe<UpdateBuildingAttributesInput>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  businessUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  contractAdditionalInfo?: InputMaybe<Scalars["Upload"]["input"]>;
  contractAdditionalInfoEnglish?: InputMaybe<Scalars["Upload"]["input"]>;
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  coupleContractTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  deposit?: InputMaybe<Scalars["Int"]["input"]>;
  floorPlan?: InputMaybe<Scalars["Upload"]["input"]>;
  generalFeedId?: InputMaybe<Scalars["Float"]["input"]>;
  housekeepersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  id: Scalars["Int"]["input"];
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  maxMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  opsManagersIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  rent?: InputMaybe<Scalars["Int"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  signedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationUpdateApartmentStockItemArgs = {
  apartmentId: Scalars["Int"]["input"];
  id: Scalars["Int"]["input"];
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  stockItemId: Scalars["Int"]["input"];
};

export type MutationUpdateApartmentTypeArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  internalName: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateBankArgs = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAddress?: InputMaybe<Scalars["String"]["input"]>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  iban?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  personalAddress?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  swiftBic?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateBookableUnitArgs = {
  apartmentId?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  closeDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["Int"]["input"];
  listingStatuses?: InputMaybe<Array<BaseUpdateListingStatus>>;
  openDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedNote?: InputMaybe<Scalars["String"]["input"]>;
  roomId?: InputMaybe<Scalars["Float"]["input"]>;
  sharedSpaceSize?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateBuildingArgs = {
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  buildingAttributes?: InputMaybe<UpdateBuildingAttributesInput>;
  businessUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId: Scalars["Int"]["input"];
  constructionYear?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateCityArgs = {
  countryId: Scalars["Int"]["input"];
  emergencyPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  generalFeedId?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateCommentArgs = {
  reactionId: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
};

export type MutationUpdateCompanyArgs = {
  addressCountry?: InputMaybe<Scalars["String"]["input"]>;
  addressLocality?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["Float"]["input"]>;
  collectiveBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  vat?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateContactArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  landlordId: Scalars["Int"]["input"];
  tagId: Scalars["Int"]["input"];
};

export type MutationUpdateContactTagArgs = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateContractTemplateArgs = {
  additionalInfoRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  contractEnglish?: InputMaybe<Scalars["Upload"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  eSignatureCustomFields?: InputMaybe<Array<ESignatureCustomFieldsInput>>;
  eSignatureMessage?: InputMaybe<Scalars["String"]["input"]>;
  eSignatureSubject?: InputMaybe<Scalars["String"]["input"]>;
  eSignatureTemplateId: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  messageTemplateId?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationUpdateCountryArgs = {
  currency: Scalars["String"]["input"];
  daysUntilMoveInInspectionAutoComplete: Scalars["Int"]["input"];
  daysUntilMoveOutInspectionAutoComplete: Scalars["Int"]["input"];
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateEmailNotificationSettingArgs = {
  events?: InputMaybe<Array<EmailNotificationEvent>>;
  id: Scalars["Int"]["input"];
};

export type MutationUpdateEventArgs = {
  description: Scalars["String"]["input"];
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  hostId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  link?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<EventLocationInput>;
  maxAttendees?: InputMaybe<Scalars["Int"]["input"]>;
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
  start: Scalars["DateTime"]["input"];
  streamFeedId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
  triggerPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateEventResponseArgs = {
  id: Scalars["Int"]["input"];
  type: ResponseType;
};

export type MutationUpdateExpenseAccountArgs = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateFeedCommentArgs = {
  reactionId: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
};

export type MutationUpdateGenericCommentArgs = {
  id: Scalars["Int"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  message: Scalars["String"]["input"];
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateHoldRoomStayArgs = {
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart: Scalars["DateTime"]["input"];
  holdRoomReason: HoldRoomReason;
  id: Scalars["Int"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateInNOutArgs = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["Int"]["input"];
};

export type MutationUpdateInNOutTaskArgs = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["Int"]["input"];
};

export type MutationUpdateInNOutTaskTemplateArgs = {
  citiesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  issueAssigneeIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  issueDeadlineShift?: InputMaybe<Scalars["Int"]["input"]>;
  issueDeadlineType?: InputMaybe<IssueDeadlineType>;
  issueDescription?: InputMaybe<Scalars["String"]["input"]>;
  issueTitle?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<InNOutTaskKind>;
  name: Scalars["String"]["input"];
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
  tagsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  types?: InputMaybe<Array<InNOutType>>;
};

export type MutationUpdateInspectionArgs = {
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  id: Scalars["Int"]["input"];
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  sections?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
};

export type MutationUpdateInspectionAssigneesArgs = {
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  id: Scalars["Int"]["input"];
};

export type MutationUpdateInspectionSurveyArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  sections?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type MutationUpdateInvoicingCostArgs = {
  activated?: InputMaybe<Scalars["Boolean"]["input"]>;
  amount: Scalars["Float"]["input"];
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  applyOnlyToUnitCategory?: InputMaybe<UnitCategory>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  followIncreaseOfCostId?: InputMaybe<Scalars["Int"]["input"]>;
  followPriceIndexId?: InputMaybe<Scalars["Int"]["input"]>;
  groupWithCostId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  increaseDate?: InputMaybe<PriceIncreaseDate>;
  invoiceDescription?: InputMaybe<Scalars["String"]["input"]>;
  invoicingProductId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  nextReferencePeriod?: InputMaybe<Scalars["DateTime"]["input"]>;
  overrideCostId?: InputMaybe<Scalars["Int"]["input"]>;
  relativeToCostId?: InputMaybe<Scalars["Int"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationUpdateInvoicingLineArgs = {
  amount: Scalars["Float"]["input"];
  attachment?: InputMaybe<Scalars["Upload"]["input"]>;
  companyPayerId?: InputMaybe<Scalars["Int"]["input"]>;
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["Int"]["input"];
  invoiceDescription: Scalars["String"]["input"];
  invoicingProductId: Scalars["Int"]["input"];
  invoicingTagId?: InputMaybe<Scalars["Int"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  start?: InputMaybe<Scalars["DateTime"]["input"]>;
  userPayerId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationUpdateInvoicingProductArgs = {
  appendStayInfoToDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  invoiceDescription?: InputMaybe<Scalars["String"]["input"]>;
  invoicingTenantId: Scalars["Int"]["input"];
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  oneTimeDueDate?: InputMaybe<OneTimeDueDateInput>;
  refundable?: InputMaybe<RefundableInput>;
  sku: Scalars["String"]["input"];
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<Array<InvoicingProductType>>;
};

export type MutationUpdateInvoicingTagArgs = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateInvoicingTenantArgs = {
  approvalRequired: Scalars["Boolean"]["input"];
  bankId?: InputMaybe<Scalars["Float"]["input"]>;
  billingApiKey?: InputMaybe<Scalars["String"]["input"]>;
  countryId: Scalars["Float"]["input"];
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  productPlanBillingId?: InputMaybe<Scalars["String"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateIssueArgs = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  assigneesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  closedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  intercomConversationId?: InputMaybe<Scalars["String"]["input"]>;
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  priority?: InputMaybe<IssuePriority>;
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurrencePattern?: InputMaybe<Scalars["String"]["input"]>;
  roomId?: InputMaybe<Scalars["Int"]["input"]>;
  serviceConversationId?: InputMaybe<Scalars["String"]["input"]>;
  tagsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updateBehaviour?: InputMaybe<RecurringUpdateBehaviour>;
};

export type MutationUpdateIssueTagArgs = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateLandlordArgs = {
  contactPerson?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateMessageTemplateArgs = {
  id: Scalars["Int"]["input"];
  message: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateMoveInArgs = {
  moveIn: Scalars["DateTime"]["input"];
  stayId: Scalars["Int"]["input"];
};

export type MutationUpdateMoveOutArgs = {
  moveOut: Scalars["DateTime"]["input"];
  stayId: Scalars["Int"]["input"];
};

export type MutationUpdateOrganisationArgs = {
  image?: InputMaybe<Scalars["Upload"]["input"]>;
  listingEmail?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdatePermissionArgs = {
  shareAboutYou?: InputMaybe<Scalars["Boolean"]["input"]>;
  shareContactDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  shareProfessionalDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  shareProfilePicture?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdatePostArgs = {
  authorId?: InputMaybe<Scalars["Int"]["input"]>;
  body: Scalars["String"]["input"];
  expireAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  frequency?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  ignoreOg?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeeplink?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  media?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
  streamFeedId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  triggerPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdatePriceIndexArgs = {
  countryId: Scalars["Int"]["input"];
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  periodicity: PriceIndexPeriodicity;
  sourceUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdatePriceIndexPrintArgs = {
  cap?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["Int"]["input"];
  period: Scalars["DateTime"]["input"];
  priceIndexId: Scalars["Int"]["input"];
  value: Scalars["Float"]["input"];
};

export type MutationUpdateRatingArgs = {
  expireAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedbackQuestion?: InputMaybe<Scalars["String"]["input"]>;
  frequency?: InputMaybe<Scalars["String"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxScore: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  question: Scalars["String"]["input"];
  streamFeedId: Scalars["Int"]["input"];
  thankYouMessage: Scalars["String"]["input"];
  triggerPushNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateRemoteServiceArgs = {
  id: Scalars["Int"]["input"];
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  keys?: InputMaybe<Array<UpdateRemoteServiceKeyInput>>;
  settings?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type MutationUpdateRoleArgs = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateRoomArgs = {
  apartmentId: Scalars["Int"]["input"];
  bookableUnit: UpdateBookableUnitInput;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  roomAttributes?: InputMaybe<UpdateRoomAttributesInput>;
  roomTypeId: Scalars["Int"]["input"];
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateRoomTypeArgs = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateSpecificationAttributeArgs = {
  categoryId: Scalars["Int"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["Upload"]["input"]>;
  id: Scalars["Int"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  type: SpecificationAttributeType;
};

export type MutationUpdateSpecificationCategoryArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  unitType: SpecificationUnitType;
};

export type MutationUpdateSpecificationValueArgs = {
  id: Scalars["Int"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationUpdateStayArgs = {
  contractEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStart: Scalars["DateTime"]["input"];
  id: Scalars["Int"]["input"];
  minMonthsRentalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  moveIn?: InputMaybe<Scalars["DateTime"]["input"]>;
  moveOut?: InputMaybe<Scalars["DateTime"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  rentalPeriodType?: InputMaybe<RentalPeriodType>;
  spouseId?: InputMaybe<Scalars["Int"]["input"]>;
  terminationNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  terminationReasonFeedback?: InputMaybe<Scalars["String"]["input"]>;
  terminationReasonsIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationUpdateStockItemArgs = {
  brand: Scalars["String"]["input"];
  categoryId: Scalars["Int"]["input"];
  cityId: Scalars["Int"]["input"];
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  quantityPerApartment?: InputMaybe<Scalars["Int"]["input"]>;
  quantityPerPerson?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateStockItemCategoryArgs = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateStreamFeedArgs = {
  backgroundImage?: InputMaybe<Scalars["Upload"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  canJoinLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  canPostIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName: Scalars["String"]["input"];
  hideFollowers?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  isAudienceListed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  picture?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationUpdateTerminationReasonArgs = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateUnitMediaRankArgs = {
  afterId?: InputMaybe<Scalars["Int"]["input"]>;
  beforeId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type MutationUpdateUserArgs = {
  bankId?: InputMaybe<Scalars["Float"]["input"]>;
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  hasAcceptedHouseRules?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasCompletedOnboardingProfile?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  immutableFirstName?: InputMaybe<Scalars["String"]["input"]>;
  immutableLastName?: InputMaybe<Scalars["String"]["input"]>;
  languageCode?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  rolesIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type MutationUpdateUserLanguageCodeArgs = {
  languageCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateUserProfileArgs = {
  about?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["DateTime"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  immutableFirstName?: InputMaybe<Scalars["String"]["input"]>;
  immutableLastName?: InputMaybe<Scalars["String"]["input"]>;
  languageCode?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  profession?: InputMaybe<Scalars["String"]["input"]>;
  workplace?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUploadContractArgs = {
  contractFile: Scalars["Upload"]["input"];
  name: Scalars["String"]["input"];
  stayId: Scalars["Int"]["input"];
};

export type MutationUploadProfilePictureArgs = {
  picture: Scalars["Upload"]["input"];
};

export type NotificationConfiguration = {
  __typename?: "NotificationConfiguration";
  getStreamApiKey: Scalars["String"]["output"];
  getStreamAppId?: Maybe<Scalars["String"]["output"]>;
  getStreamUserToken?: Maybe<Scalars["String"]["output"]>;
  notificationFeedName: Scalars["String"]["output"];
  notificationFeedUserId?: Maybe<Scalars["String"]["output"]>;
};

export type NotificationEntity = Apartment | Building | Conversation | InNOut | Issue | Post | Room | Stay;

export type NotificationFeed = {
  __typename?: "NotificationFeed";
  author?: Maybe<User>;
  createdAt: Scalars["DateTime"]["output"];
  entity?: Maybe<NotificationEntity>;
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  isRead: Scalars["Boolean"]["output"];
  isSeen: Scalars["Boolean"]["output"];
  verb: StreamNotificationVerb;
};

export type NotificationListMetadata = {
  __typename?: "NotificationListMetadata";
  count: Scalars["Int"]["output"];
  unread?: Maybe<Scalars["Int"]["output"]>;
  unseen?: Maybe<Scalars["Int"]["output"]>;
};

export type NumberListQuestion = {
  __typename?: "NumberListQuestion";
  changeState?: Maybe<QuestionChangeState>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  landlordAnswer: Array<TextAnswer>;
  previousAnswer?: Maybe<Array<TextAnswer>>;
  rows: Array<Scalars["String"]["output"]>;
  status: InspectionItemStatus;
  tenantAnswer: Array<TextAnswer>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type NumberListSurveyQuestion = {
  __typename?: "NumberListSurveyQuestion";
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  rows: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type OneTimeDueDate = {
  __typename?: "OneTimeDueDate";
  daysBeforeContractStart?: Maybe<Scalars["Float"]["output"]>;
  event?: Maybe<Scalars["String"]["output"]>;
};

export enum OneTimeDueDateEvent {
  DaysBeforeContractStart = "daysBeforeContractStart",
  OnApproval = "onApproval",
}

export type OneTimeDueDateInput = {
  daysBeforeContractStart?: InputMaybe<Scalars["Int"]["input"]>;
  event?: InputMaybe<OneTimeDueDateEvent>;
};

export type OpenGraph = {
  __typename?: "OpenGraph";
  description?: Maybe<Scalars["String"]["output"]>;
  determiner?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<OpenGraphImage>>;
  locale?: Maybe<Scalars["String"]["output"]>;
  site?: Maybe<Scalars["String"]["output"]>;
  site_name?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type OpenGraphImage = {
  __typename?: "OpenGraphImage";
  alt?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  secure_url?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["String"]["output"]>;
};

export type Organisation = {
  __typename?: "Organisation";
  image?: Maybe<Scalars["String"]["output"]>;
  listingEmail?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  tenantId: Scalars["String"]["output"];
};

export type OrganisationSettings = {
  __typename?: "OrganisationSettings";
  billing: Scalars["Boolean"]["output"];
  community: Scalars["Boolean"]["output"];
  contract: Scalars["Boolean"]["output"];
  customerSupport: Scalars["Boolean"]["output"];
  inspection: Scalars["Boolean"]["output"];
  languageCode?: Maybe<Scalars["String"]["output"]>;
  listing: Scalars["Boolean"]["output"];
};

export type Permission = {
  __typename?: "Permission";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  shareAboutYou: Scalars["Boolean"]["output"];
  shareContactDetails: Scalars["Boolean"]["output"];
  shareProfessionalDetails: Scalars["Boolean"]["output"];
  shareProfilePicture: Scalars["Boolean"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Permissions = {
  __typename?: "Permissions";
  customerSettings?: Maybe<OrganisationSettings>;
  organisationSettings?: Maybe<OrganisationSettings>;
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type Post = {
  __typename?: "Post";
  author: User;
  body: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  expireAt?: Maybe<Scalars["DateTime"]["output"]>;
  frequency?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  ignoreOg?: Maybe<Scalars["Boolean"]["output"]>;
  isDeeplink?: Maybe<Scalars["Boolean"]["output"]>;
  isPaused?: Maybe<Scalars["Boolean"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  media: Array<Media>;
  streamActivityId?: Maybe<Scalars["String"]["output"]>;
  streamFeed: StreamFeed;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  triggerPushNotification?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PostFilter = {
  admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  streamFeedId?: InputMaybe<Scalars["Float"]["input"]>;
};

/** Incoiving cost setting to decide when price should be increased */
export enum PriceIncreaseDate {
  April = "april",
  August = "august",
  ContractAnniversary = "contractAnniversary",
  December = "december",
  February = "february",
  January = "january",
  July = "july",
  June = "june",
  March = "march",
  May = "may",
  November = "november",
  October = "october",
  September = "september",
}

export type PriceIndex = {
  __typename?: "PriceIndex";
  country: Country;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  periodicity: PriceIndexPeriodicity;
  sourceUrl?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PriceIndexFilter = {
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum PriceIndexPeriodicity {
  Monthly = "monthly",
  Quarterly = "quarterly",
}

export type PriceIndexPrint = {
  __typename?: "PriceIndexPrint";
  cap?: Maybe<Scalars["Float"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  period: Scalars["DateTime"]["output"];
  priceIndex: PriceIndex;
  updatedAt: Scalars["DateTime"]["output"];
  value: Scalars["Float"]["output"];
};

export type PriceIndexPrintFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  priceIndexId?: InputMaybe<Scalars["Int"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  _apartmentStockItemsMeta: ListMetadata;
  _apartmentTypesMeta: ListMetadata;
  _apartmentsMeta: ListMetadata;
  _banksMeta: ListMetadata;
  _bookableUnitsMeta: ListMetadata;
  _buildingsMeta: ListMetadata;
  _citiesMeta: ListMetadata;
  _commentsMeta: ListMetadata;
  _companiesMeta: ListMetadata;
  _contactTagsMeta: ListMetadata;
  _contactsMeta: ListMetadata;
  _contractTemplatesMeta: ListMetadata;
  _contractsMeta: ListMetadata;
  _conversationPartsMeta: ListMetadata;
  _conversationsMeta: ListMetadata;
  _countriesMeta: ListMetadata;
  _eSignatureTemplatesMeta: ListMetadata;
  _emailNotificationSettingsMeta: ListMetadata;
  _eventsMeta: ListMetadata;
  _expenseAccountsMeta: ListMetadata;
  _financialEntriesMeta: ListMetadata;
  _inNOutTaskTemplatesMeta: ListMetadata;
  _inNOutTasksMeta: ListMetadata;
  _inNOutsMeta: ListMetadata;
  _inspectionSurveysMeta: ListMetadata;
  _inspectionsMeta: ListMetadata;
  _invoicingCostsMeta: ListMetadata;
  _invoicingLinesMeta: ListMetadata;
  _invoicingProductsMeta: ListMetadata;
  _invoicingTagsMeta: ListMetadata;
  _invoicingTenantsMeta: ListMetadata;
  _issueTagsMeta: ListMetadata;
  _issuesMeta: ListMetadata;
  _landlordsMeta: ListMetadata;
  _listingsMeta: ListingMetadata;
  _mediasMeta: ListMetadata;
  _messageTemplatesMeta: ListMetadata;
  _notificationsMeta: NotificationListMetadata;
  _postsMeta: ListMetadata;
  _priceIndexPrintsMeta: ListMetadata;
  _priceIndicesMeta: ListMetadata;
  _ratingsMeta: ListMetadata;
  _remoteServicesMeta: ListMetadata;
  _rolesMeta: ListMetadata;
  _roomTypesMeta: ListMetadata;
  _roomsMeta: ListMetadata;
  _specificationAttributesMeta: ListMetadata;
  _specificationCategoriesMeta: ListMetadata;
  _specificationValuesMeta: ListMetadata;
  _staysMeta: ListMetadata;
  _stockItemCategoriesMeta: ListMetadata;
  _stockItemsMeta: ListMetadata;
  _streamFeedsMeta: ListMetadata;
  _terminationReasonsMeta: ListMetadata;
  _usersMeta: ListMetadata;
  activity?: Maybe<CardUnion>;
  allFollowingFeeds: Array<StreamFeed>;
  apartment?: Maybe<Apartment>;
  /** @deprecated Use ListingResolver.listing instead */
  apartmentBySlug?: Maybe<Apartment>;
  apartmentSpecifications: Array<SpecificationOneValue>;
  apartmentStockItem?: Maybe<ApartmentStockItem>;
  apartmentStockItems: Array<ApartmentStockItem>;
  apartmentStockList?: Maybe<ApartmentStockList>;
  apartmentType?: Maybe<ApartmentType>;
  apartmentTypes: Array<ApartmentType>;
  apartments: Array<Apartment>;
  bank?: Maybe<Bank>;
  banks: Array<Bank>;
  bookableUnit?: Maybe<BookableUnit>;
  bookableUnits: Array<BookableUnit>;
  building?: Maybe<Building>;
  buildingListingBySlug?: Maybe<BuildingListingType>;
  buildingListings?: Maybe<Array<BuildingListingType>>;
  buildingSpecifications: Array<SpecificationOneValue>;
  buildings: Array<Building>;
  bulkParseText: Array<BulkParseOutput>;
  cities: Array<City>;
  city?: Maybe<City>;
  cityBySlug?: Maybe<City>;
  cityStockList?: Maybe<Array<ApartmentStockList>>;
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  companies: Array<Company>;
  company?: Maybe<Company>;
  contact?: Maybe<Contact>;
  contactTag?: Maybe<ContactTag>;
  contactTags: Array<ContactTag>;
  contacts: Array<Contact>;
  contract?: Maybe<Contract>;
  contractTemplate?: Maybe<ContractTemplate>;
  contractTemplates: Array<ContractTemplate>;
  contracts: Array<Contract>;
  conversation?: Maybe<Conversation>;
  conversationPart?: Maybe<ConversationPart>;
  conversationParts: Array<ConversationPart>;
  conversations: Array<Conversation>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  eSignatureTemplate?: Maybe<ESignatureTemplate>;
  eSignatureTemplates?: Maybe<Array<ESignatureTemplate>>;
  emailNotificationSetting?: Maybe<EmailNotificationSetting>;
  emailNotificationSettings: Array<EmailNotificationSetting>;
  event?: Maybe<Event>;
  events: Array<Event>;
  expenseAccount?: Maybe<ExpenseAccount>;
  expenseAccounts: Array<ExpenseAccount>;
  feed: Array<CardUnion>;
  financialEntries: Array<FinancialEntry>;
  financialEntry?: Maybe<FinancialEntry>;
  getToken?: Maybe<Token>;
  handlebarsVariable: Array<BaseContextVariable>;
  inNOut?: Maybe<InNOut>;
  inNOutPart?: Maybe<InNOutPart>;
  inNOutTask?: Maybe<InNOutTask>;
  inNOutTaskTemplate?: Maybe<InNOutTaskTemplate>;
  inNOutTaskTemplates: Array<InNOutTaskTemplate>;
  inNOutTasks: Array<InNOutTask>;
  inNOuts: Array<InNOut>;
  inspection: Inspection;
  inspectionSurvey: InspectionSurvey;
  inspectionSurveys: Array<InspectionSurvey>;
  inspections: Array<Inspection>;
  invoicingCost?: Maybe<InvoicingCost>;
  invoicingCosts: Array<InvoicingCost>;
  invoicingLine?: Maybe<InvoicingLine>;
  invoicingLines: Array<InvoicingLine>;
  invoicingProduct?: Maybe<InvoicingProduct>;
  invoicingProducts: Array<InvoicingProduct>;
  invoicingTag?: Maybe<InvoicingTag>;
  invoicingTags: Array<InvoicingTag>;
  invoicingTenant?: Maybe<InvoicingTenant>;
  invoicingTenants: Array<InvoicingTenant>;
  issue?: Maybe<Issue>;
  issueTag?: Maybe<IssueTag>;
  issueTags: Array<IssueTag>;
  issues: Array<Issue>;
  landlord?: Maybe<Landlord>;
  landlords: Array<Landlord>;
  landlordsByTags: Array<LandlordByTag>;
  listing?: Maybe<ListingType>;
  listings: Array<ListingType>;
  me: User;
  medias: Array<Media>;
  messageTemplate?: Maybe<MessageTemplate>;
  messageTemplates: Array<MessageTemplate>;
  metricsDashboards: Array<MetricsDashboard>;
  notifications: Array<NotificationFeed>;
  notificationsConfiguration: NotificationConfiguration;
  opengraph?: Maybe<OpenGraph>;
  organisation?: Maybe<Organisation>;
  permissions?: Maybe<Permissions>;
  post?: Maybe<Post>;
  posts: Array<Post>;
  priceIndex?: Maybe<PriceIndex>;
  priceIndexPrint?: Maybe<PriceIndexPrint>;
  priceIndexPrints: Array<PriceIndexPrint>;
  priceIndices: Array<PriceIndex>;
  rating?: Maybe<Rating>;
  ratings: Array<Rating>;
  reactions: Array<ReactionWithUser>;
  recommendedFeeds: Array<StreamFeed>;
  remoteService?: Maybe<RemoteService>;
  remoteServices: Array<RemoteService>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  room?: Maybe<Room>;
  roomSpecifications: Array<SpecificationOneValue>;
  roomType?: Maybe<RoomType>;
  roomTypes: Array<RoomType>;
  rooms: Array<Room>;
  specificationAttribute?: Maybe<SpecificationAttribute>;
  specificationAttributes: Array<SpecificationAttribute>;
  specificationCategories: Array<SpecificationCategory>;
  specificationCategory?: Maybe<SpecificationCategory>;
  specificationValue?: Maybe<SpecificationValue>;
  specificationValues: Array<SpecificationValue>;
  stay?: Maybe<Stay>;
  stays: Array<Stay>;
  staysNeedingApproval: Array<Stay>;
  stockItem?: Maybe<StockItem>;
  stockItemCategories: Array<StockItemCategory>;
  stockItemCategory?: Maybe<StockItemCategory>;
  stockItems: Array<StockItem>;
  streamFeed?: Maybe<StreamFeed>;
  streamFeeds: Array<StreamFeed>;
  suburbs?: Maybe<Array<Scalars["String"]["output"]>>;
  syncUnitsWithListingPlatform?: Maybe<Scalars["String"]["output"]>;
  syncUsersWithGetStream: Scalars["String"]["output"];
  terminationReason?: Maybe<TerminationReason>;
  terminationReasons: Array<TerminationReason>;
  user?: Maybe<User>;
  users: Array<User>;
};

export type Query_ApartmentTypesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ApartmentsMetaArgs = {
  filter?: InputMaybe<ApartmentDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_BanksMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_BookableUnitsMetaArgs = {
  filter?: InputMaybe<BookableUnitFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_BuildingsMetaArgs = {
  filter?: InputMaybe<BuildingDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_CitiesMetaArgs = {
  filter?: InputMaybe<CityDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_CommentsMetaArgs = {
  filter: CommentDefaultFilter;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_CompaniesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ContactTagsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ContactsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ContractTemplatesMetaArgs = {
  filter?: InputMaybe<ContractTemplateFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ContractsMetaArgs = {
  filter?: InputMaybe<ContractFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ConversationPartsMetaArgs = {
  filter: ConversationPartDefaultFilter;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ConversationsMetaArgs = {
  filter?: InputMaybe<ConversationDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_CountriesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ESignatureTemplatesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_EmailNotificationSettingsMetaArgs = {
  filter?: InputMaybe<EmailNotificationFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_EventsMetaArgs = {
  filter?: InputMaybe<EventFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ExpenseAccountsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_FinancialEntriesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InNOutTasksMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InNOutsMetaArgs = {
  filter?: InputMaybe<InNOutFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InspectionSurveysMetaArgs = {
  filter?: InputMaybe<InspectionSurveyDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InspectionsMetaArgs = {
  filter?: InputMaybe<InspectionDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InvoicingCostsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InvoicingLinesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InvoicingProductsMetaArgs = {
  filter?: InputMaybe<InvoicingProductDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InvoicingTagsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_InvoicingTenantsMetaArgs = {
  filter?: InputMaybe<InvoicingTenantDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_IssueTagsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_IssuesMetaArgs = {
  filter?: InputMaybe<IssueFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_LandlordsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_ListingsMetaArgs = {
  filter?: InputMaybe<ListingFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<ListingSortField>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_MediasMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_MessageTemplatesMetaArgs = {
  filter?: InputMaybe<MessageTemplateFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_PostsMetaArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_PriceIndexPrintsMetaArgs = {
  filter?: InputMaybe<PriceIndexPrintFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_PriceIndicesMetaArgs = {
  filter?: InputMaybe<PriceIndexFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RatingsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RemoteServicesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RolesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RoomTypesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RoomsMetaArgs = {
  filter?: InputMaybe<RoomFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_SpecificationAttributesMetaArgs = {
  filter?: InputMaybe<SpecificationAttributeFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_SpecificationCategoriesMetaArgs = {
  filter?: InputMaybe<SpecificationCategoryFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_SpecificationValuesMetaArgs = {
  filter?: InputMaybe<SpecificationValueFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_StaysMetaArgs = {
  filter?: InputMaybe<StayFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_StockItemCategoriesMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_StockItemsMetaArgs = {
  filter?: InputMaybe<StockItemDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_StreamFeedsMetaArgs = {
  filter?: InputMaybe<StreamFeedFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_TerminationReasonsMetaArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_UsersMetaArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryActivityArgs = {
  activityId: Scalars["String"]["input"];
  streamFeedId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllFollowingFeedsArgs = {
  canJoinLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  canPostIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAudienceListed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryApartmentArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryApartmentBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryApartmentSpecificationsArgs = {
  apartmentId: Scalars["Int"]["input"];
};

export type QueryApartmentStockItemArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryApartmentStockListArgs = {
  apartmentId: Scalars["Int"]["input"];
};

export type QueryApartmentTypeArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryApartmentTypesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryApartmentsArgs = {
  filter?: InputMaybe<ApartmentDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryBankArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryBanksArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryBookableUnitArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryBookableUnitsArgs = {
  filter?: InputMaybe<BookableUnitFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryBuildingArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryBuildingListingBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryBuildingSpecificationsArgs = {
  buildingId: Scalars["Int"]["input"];
};

export type QueryBuildingsArgs = {
  filter?: InputMaybe<BuildingDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryBulkParseTextArgs = {
  input: Array<BulkParseInput>;
  stayId: Scalars["Int"]["input"];
};

export type QueryCitiesArgs = {
  filter?: InputMaybe<CityDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCityArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryCityBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryCityStockListArgs = {
  cityId: Scalars["Int"]["input"];
};

export type QueryCommentArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryCommentsArgs = {
  filter: CommentDefaultFilter;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCompaniesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCompanyArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryContactArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryContactTagArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryContactTagsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryContactsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryContractArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryContractTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryContractTemplatesArgs = {
  filter?: InputMaybe<ContractTemplateFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryConversationArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryConversationPartArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryConversationPartsArgs = {
  filter: ConversationPartDefaultFilter;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryConversationsArgs = {
  filter?: InputMaybe<ConversationDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCountryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryESignatureTemplateArgs = {
  id: Scalars["String"]["input"];
};

export type QueryESignatureTemplatesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryEmailNotificationSettingArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryEmailNotificationSettingsArgs = {
  filter?: InputMaybe<EmailNotificationFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryEventArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryExpenseAccountArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryExpenseAccountsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFeedArgs = {
  id_lt?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  streamFeedId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryFinancialEntriesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFinancialEntryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryGetTokenArgs = {
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  redirectUri?: InputMaybe<Scalars["String"]["input"]>;
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryHandlebarsVariableArgs = {
  requiresStay?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryInNOutArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInNOutPartArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInNOutTaskArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInNOutTaskTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInNOutTasksArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInNOutsArgs = {
  filter?: InputMaybe<InNOutFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInspectionArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInspectionSurveyArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInspectionSurveysArgs = {
  filter?: InputMaybe<InspectionSurveyDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInspectionsArgs = {
  filter?: InputMaybe<InspectionDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInvoicingCostArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInvoicingCostsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInvoicingLineArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInvoicingLinesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInvoicingProductArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInvoicingProductsArgs = {
  filter?: InputMaybe<InvoicingProductDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInvoicingTagArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInvoicingTagsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInvoicingTenantArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryInvoicingTenantsArgs = {
  filter?: InputMaybe<InvoicingTenantDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIssueArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryIssueTagArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryIssueTagsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIssuesArgs = {
  filter?: InputMaybe<IssueFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLandlordArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryLandlordsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryListingArgs = {
  apartmentSlug: Scalars["String"]["input"];
};

export type QueryListingsArgs = {
  apartmentSlug?: InputMaybe<Scalars["String"]["input"]>;
  apartmentTypeIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  availableFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  buildingSlug?: InputMaybe<Scalars["String"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  citySlug?: InputMaybe<Scalars["String"]["input"]>;
  maxPrice?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  unitType?: InputMaybe<UnitType>;
};

export type QueryMediasArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMessageTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryMessageTemplatesArgs = {
  filter?: InputMaybe<MessageTemplateFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryNotificationsArgs = {
  id_lt?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOpengraphArgs = {
  url: Scalars["String"]["input"];
};

export type QueryOrganisationArgs = {
  tenantId: Scalars["String"]["input"];
};

export type QueryPostArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryPostsArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPriceIndexArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryPriceIndexPrintArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryPriceIndexPrintsArgs = {
  filter?: InputMaybe<PriceIndexPrintFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPriceIndicesArgs = {
  filter?: InputMaybe<PriceIndexFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRatingArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryRatingsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryReactionsArgs = {
  id_gt?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  parentId: Scalars["String"]["input"];
};

export type QueryRemoteServiceArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryRemoteServicesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRoleArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryRolesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRoomArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryRoomSpecificationsArgs = {
  roomId: Scalars["Int"]["input"];
};

export type QueryRoomTypeArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryRoomTypesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRoomsArgs = {
  filter?: InputMaybe<RoomFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySpecificationAttributeArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySpecificationAttributesArgs = {
  filter?: InputMaybe<SpecificationAttributeFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySpecificationCategoriesArgs = {
  filter?: InputMaybe<SpecificationCategoryFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySpecificationCategoryArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySpecificationValueArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySpecificationValuesArgs = {
  filter?: InputMaybe<SpecificationValueFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStayArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryStaysArgs = {
  filter?: InputMaybe<StayFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStaysNeedingApprovalArgs = {
  filter?: InputMaybe<StayApprovalFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStockItemArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryStockItemCategoriesArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStockItemCategoryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryStockItemsArgs = {
  filter?: InputMaybe<StockItemDefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStreamFeedArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryStreamFeedsArgs = {
  filter?: InputMaybe<StreamFeedFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySuburbsArgs = {
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerySyncUnitsWithListingPlatformArgs = {
  listingPlatform: ListingPlatform;
};

export type QueryTerminationReasonArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryTerminationReasonsArgs = {
  filter?: InputMaybe<DefaultFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryUserArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export enum QuestionChangeState {
  BothAgree = "BothAgree",
  BothDisagree = "BothDisagree",
  Landlord = "Landlord",
  None = "None",
  Tenant = "Tenant",
}

export enum QuestionType {
  Checkbox = "Checkbox",
  NumberList = "NumberList",
  Radio = "Radio",
  RadioGrid = "RadioGrid",
  Text = "Text",
}

export type RadioGridQuestion = {
  __typename?: "RadioGridQuestion";
  changeState?: Maybe<QuestionChangeState>;
  choices: Array<Scalars["String"]["output"]>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  landlordAnswer: Array<TextAnswer>;
  previousAnswer?: Maybe<Array<TextAnswer>>;
  rows: Array<Scalars["String"]["output"]>;
  status: InspectionItemStatus;
  tenantAnswer: Array<TextAnswer>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type RadioGridSurveyQuestion = {
  __typename?: "RadioGridSurveyQuestion";
  choices: Array<Scalars["String"]["output"]>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  rows: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type RadioQuestion = {
  __typename?: "RadioQuestion";
  changeState?: Maybe<QuestionChangeState>;
  choices: Array<Scalars["String"]["output"]>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  landlordAnswer: TextAnswer;
  previousAnswer?: Maybe<TextAnswer>;
  status: InspectionItemStatus;
  tenantAnswer: TextAnswer;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type RadioSurveyQuestion = {
  __typename?: "RadioSurveyQuestion";
  choices: Array<Scalars["String"]["output"]>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type Rating = {
  __typename?: "Rating";
  createdAt: Scalars["DateTime"]["output"];
  expireAt?: Maybe<Scalars["DateTime"]["output"]>;
  feedbackQuestion?: Maybe<Scalars["String"]["output"]>;
  frequency?: Maybe<Scalars["String"]["output"]>;
  group?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isPaused?: Maybe<Scalars["Boolean"]["output"]>;
  maxScore: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  question: Scalars["String"]["output"];
  streamFeed: StreamFeed;
  thankYouMessage: Scalars["String"]["output"];
  triggerPushNotification?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RatingAnswer = {
  __typename?: "RatingAnswer";
  createdAt: Scalars["DateTime"]["output"];
  feedback?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  score: Scalars["Float"]["output"];
  streamActivityId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Reaction = {
  __typename?: "Reaction";
  activity_id: Scalars["String"]["output"];
  children_counts: Scalars["JSONObject"]["output"];
  created_at: Scalars["String"]["output"];
  data: Scalars["JSONObject"]["output"];
  duration: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  kind: Scalars["String"]["output"];
  latest_children: Scalars["JSONObject"]["output"];
  own_children?: Maybe<Scalars["JSONObject"]["output"]>;
  parent: Scalars["String"]["output"];
  updated_at: Scalars["String"]["output"];
  user_id: Scalars["String"]["output"];
};

export type ReactionWithUser = {
  __typename?: "ReactionWithUser";
  activity_id: Scalars["String"]["output"];
  children_counts: Scalars["JSONObject"]["output"];
  created_at: Scalars["String"]["output"];
  data: Scalars["JSONObject"]["output"];
  duration: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  kind: Scalars["String"]["output"];
  latest_children: Scalars["JSONObject"]["output"];
  own_children?: Maybe<Scalars["JSONObject"]["output"]>;
  parent: Scalars["String"]["output"];
  updated_at: Scalars["String"]["output"];
  user: User;
  user_id: Scalars["String"]["output"];
};

export type ReadReceipt = {
  __typename?: "ReadReceipt";
  readAt?: Maybe<Scalars["DateTime"]["output"]>;
  user: ConversationParticipant;
};

export type Recurrence = {
  __typename?: "Recurrence";
  id: Scalars["Int"]["output"];
  recurrencePattern: Scalars["String"]["output"];
};

export enum RecurringUpdateBehaviour {
  All = "all",
  Future = "future",
}

export type Refundable = {
  __typename?: "Refundable";
  invoiceDescription?: Maybe<Scalars["String"]["output"]>;
  refundToLastRentPayer?: Maybe<Scalars["Boolean"]["output"]>;
  type?: Maybe<RefundableType>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type RefundableInput = {
  invoiceDescription?: InputMaybe<Scalars["String"]["input"]>;
  refundToLastRentPayer?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<RefundableType>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum RefundableType {
  DaysAfterTermination = "daysAfterTermination",
  FirstDayOfTerminationMonth = "firstDayOfTerminationMonth",
}

export type Remindable = {
  __typename?: "Remindable";
  frequency: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  isPaused?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoteService = {
  __typename?: "RemoteService";
  boligPortalUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  isPaused: Scalars["Boolean"]["output"];
  keys?: Maybe<Array<RemoteServiceKey>>;
  name: RemoteServiceName;
  settings?: Maybe<Scalars["JSONObject"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RemoteServiceKey = {
  __typename?: "RemoteServiceKey";
  id: Scalars["Int"]["output"];
  key?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export enum RemoteServiceKeyNames {
  ContentfulAccessToken = "CONTENTFUL_ACCESS_TOKEN",
  ContentfulSpaceId = "CONTENTFUL_SPACE_ID",
  EConomicGrantToken = "E_CONOMIC_GRANT_TOKEN",
  HellosignApiKey = "HELLOSIGN_API_KEY",
  HousingAnywhereApiKey = "HOUSING_ANYWHERE_API_KEY",
  IntercomAccessKey = "INTERCOM_ACCESS_KEY",
  IntercomIdentityVerificationSecret = "INTERCOM_IDENTITY_VERIFICATION_SECRET",
  IntercomWorkspaceId = "INTERCOM_WORKSPACE_ID",
}

export enum RemoteServiceName {
  BoligPortal = "BOLIG_PORTAL",
  Contentful = "CONTENTFUL",
  EConomic = "E_CONOMIC",
  GetStream = "GET_STREAM",
  Hellosign = "HELLOSIGN",
  HousingAnywhere = "HOUSING_ANYWHERE",
  Intercom = "INTERCOM",
  Upodi = "UPODI",
}

export enum RentalPeriodType {
  CalendarMonth = "calendarMonth",
  RollingMonth = "rollingMonth",
}

export enum ResponseType {
  Maybe = "maybe",
  No = "no",
  Yes = "yes",
}

export type Role = {
  __typename?: "Role";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Room = {
  __typename?: "Room";
  apartment: Apartment;
  bookableUnit?: Maybe<BookableUnit>;
  /** @deprecated use cmsData instead */
  cmsContent?: Maybe<RoomContent>;
  cmsData?: Maybe<Scalars["JSONObject"]["output"]>;
  /** @deprecated use cmsData instead */
  contentful?: Maybe<RoomContent>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  invoicingCosts: Array<InvoicingCost>;
  issues: Array<Issue>;
  name: Scalars["String"]["output"];
  roomAttributes: RoomAttributes;
  roomType: RoomType;
  slug: Scalars["String"]["output"];
  /** @deprecated Use specifications */
  specification: Array<Specification>;
  specifications: Array<SpecificationOneValue>;
  unitMedia: Array<UnitMedia>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RoomAttributes = {
  __typename?: "RoomAttributes";
  /** [[nb of beds for this size, width, length]] */
  bedsSizes: Array<Maybe<Array<Maybe<Scalars["Int"]["output"]>>>>;
  desk: Scalars["Boolean"]["output"];
  ensuiteBathroom: Scalars["Boolean"]["output"];
  floor?: Maybe<Scalars["Int"]["output"]>;
  furnished: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  linenAndTowels: Scalars["Boolean"]["output"];
  listingDescription?: Maybe<Scalars["String"]["output"]>;
  listingTitle?: Maybe<Scalars["String"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  privateBalcony: Scalars["Boolean"]["output"];
  size?: Maybe<Scalars["Float"]["output"]>;
  storage: Scalars["Boolean"]["output"];
  tv: Scalars["Boolean"]["output"];
  wheelchairAccessible: Scalars["Boolean"]["output"];
};

export type RoomContent = {
  __typename?: "RoomContent";
  facts?: Maybe<Array<Scalars["String"]["output"]>>;
  immoViewerLink?: Maybe<Scalars["String"]["output"]>;
  photos?: Maybe<Array<Asset>>;
};

export type RoomFilter = {
  apartmentId?: InputMaybe<Scalars["Int"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  contact?: InputMaybe<ContactTagLandlordFilterInput>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  maxSize?: InputMaybe<Scalars["Int"]["input"]>;
  minSize?: InputMaybe<Scalars["Int"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  suburb?: InputMaybe<Scalars["String"]["input"]>;
};

export type RoomFilterInput = {
  filter?: InputMaybe<RoomFilter>;
};

export type RoomType = {
  __typename?: "RoomType";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  roomCount: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Section = {
  __typename?: "Section";
  description?: Maybe<Scalars["String"]["output"]>;
  questions: Array<Scalars["JSONObject"]["output"]>;
  status?: Maybe<InspectionItemStatus>;
  title: Scalars["String"]["output"];
};

export type Specification = {
  __typename?: "Specification";
  attributes: Array<Attribute>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type SpecificationAttribute = {
  __typename?: "SpecificationAttribute";
  category: SpecificationCategory;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  /** Human reabable identifier to facilitate access of a specific attribute */
  key?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  type: SpecificationAttributeType;
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<SpecificationValue>;
};

export type SpecificationAttributeFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<SpecificationAttributeType>;
  unitType?: InputMaybe<SpecificationUnitType>;
};

export enum SpecificationAttributeType {
  Member = "MEMBER",
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type SpecificationCategory = {
  __typename?: "SpecificationCategory";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  specificationAttributes: Array<SpecificationAttribute>;
  unitType: SpecificationUnitType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SpecificationCategoryFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type SpecificationOneValue = {
  __typename?: "SpecificationOneValue";
  attributes: Array<AttributeOneValue>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  unitType: SpecificationUnitType;
};

export enum SpecificationUnitType {
  Apartment = "APARTMENT",
  Building = "BUILDING",
  Room = "ROOM",
}

export type SpecificationValue = {
  __typename?: "SpecificationValue";
  apartment?: Maybe<Apartment>;
  attribute: SpecificationAttribute;
  building?: Maybe<Building>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  room?: Maybe<Room>;
  updatedAt: Scalars["DateTime"]["output"];
  value: Scalars["String"]["output"];
};

export type SpecificationValueFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  unitType?: InputMaybe<SpecificationUnitType>;
};

export type Stay = {
  __typename?: "Stay";
  bookableUnit: BookableUnit;
  contractEnd?: Maybe<Scalars["DateTime"]["output"]>;
  contractStart: Scalars["DateTime"]["output"];
  contractStatus: ColorStatus;
  contracts: Array<Contract>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy?: Maybe<User>;
  deposit?: Maybe<Scalars["Float"]["output"]>;
  earliestContractEnd: Scalars["DateTime"]["output"];
  holdRoomReason?: Maybe<HoldRoomReason>;
  id: Scalars["Int"]["output"];
  inspections: Array<Inspection>;
  invoicingCostSetup?: Maybe<Array<InvoicingCost>>;
  invoicingLines: Array<InvoicingLine>;
  invoicingStatus: ColorStatus;
  isCancelled?: Maybe<Scalars["Boolean"]["output"]>;
  isSigned: Scalars["Boolean"]["output"];
  memberMove: Scalars["Boolean"]["output"];
  minMonthsRentalPeriod?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated use minMonthsRentalPeriod instead */
  minimumMonth?: Maybe<Scalars["Int"]["output"]>;
  moveIn?: Maybe<Scalars["DateTime"]["output"]>;
  moveOut?: Maybe<Scalars["DateTime"]["output"]>;
  moveOutConfirmed?: Maybe<Scalars["DateTime"]["output"]>;
  nextStay?: Maybe<Stay>;
  nextStayId?: Maybe<Scalars["Float"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  prepaid?: Maybe<Scalars["Float"]["output"]>;
  /** Return the previous stay linked to the room of this stay */
  previousBookableUnitStay?: Maybe<Stay>;
  /** @deprecated Use previousBookableUnitStay instead */
  previousRoomStay?: Maybe<Stay>;
  previousStay?: Maybe<Stay>;
  previousStayMoveOutInspection?: Maybe<Inspection>;
  price?: Maybe<Scalars["Float"]["output"]>;
  rentalPeriodType?: Maybe<RentalPeriodType>;
  /** @deprecated Use bookableUnit instead */
  room?: Maybe<Room>;
  signedContracts: Array<Contract>;
  spouse?: Maybe<User>;
  terminatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminatedBy?: Maybe<User>;
  terminationNoticePeriod?: Maybe<Scalars["Int"]["output"]>;
  terminationReasonFeedback?: Maybe<Scalars["String"]["output"]>;
  terminationReasons: Array<TerminationReason>;
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
};

export type StayApprovalFilter = {
  approveActions?: InputMaybe<Array<InvoicingLineApproveAction>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  refund?: InputMaybe<Scalars["Boolean"]["input"]>;
  types?: InputMaybe<Array<InvoicingProductType>>;
};

export type StayFilter = {
  bookableUnitId?: InputMaybe<Scalars["Float"]["input"]>;
  buildingId?: InputMaybe<Scalars["Int"]["input"]>;
  cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  contractStatus?: InputMaybe<ColorStatus>;
  createdById?: InputMaybe<Scalars["Int"]["input"]>;
  current?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  invoicingStatus?: InputMaybe<ColorStatus>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StockItem = {
  __typename?: "StockItem";
  brand: Scalars["String"]["output"];
  category: StockItemCategory;
  city: City;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  quantityPerApartment?: Maybe<Scalars["Int"]["output"]>;
  quantityPerPerson?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type StockItemCategory = {
  __typename?: "StockItemCategory";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type StockItemDefaultFilter = {
  cityId?: InputMaybe<Scalars["Float"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type StockList = {
  __typename?: "StockList";
  brand: Scalars["String"]["output"];
  expectedQuantity: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  ongoingStays?: Maybe<Scalars["Int"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  quantityPerApartment?: Maybe<Scalars["Int"]["output"]>;
  quantityPerPerson: Scalars["Float"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** Reflects feed on getstream.io */
export type StreamFeed = {
  __typename?: "StreamFeed";
  backgroundImage?: Maybe<Scalars["String"]["output"]>;
  building?: Maybe<Building>;
  canJoinLeave: Scalars["Boolean"]["output"];
  canPostIn: Scalars["Boolean"]["output"];
  city?: Maybe<City>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName: Scalars["String"]["output"];
  followers: Array<User>;
  following: Scalars["Boolean"]["output"];
  hideFollowers: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  isAudienceListed: Scalars["Boolean"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  numFollowers: Scalars["Int"]["output"];
  picture?: Maybe<Scalars["String"]["output"]>;
  triggerPushNotification?: Maybe<Scalars["Boolean"]["output"]>;
  upcomingEvents: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

/** Reflects feed on getstream.io */
export type StreamFeedFollowersArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StreamFeedFilter = {
  canJoinLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  canPostIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  cityId?: InputMaybe<Scalars["Int"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isAudienceListed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export enum StreamNotificationVerb {
  Comment = "Comment",
  CommentTagged = "CommentTagged",
  ConversationAssign = "ConversationAssign",
  ConversationClose = "ConversationClose",
  ConversationNote = "ConversationNote",
  ConversationPartBounce = "ConversationPartBounce",
  ConversationReopen = "ConversationReopen",
  ConversationReply = "ConversationReply",
  FeedComment = "FeedComment",
  FeedLike = "FeedLike",
  InNOutClose = "InNOutClose",
  InNOutReopen = "InNOutReopen",
  InNOutTaskClose = "InNOutTaskClose",
  InNOutTaskReopen = "InNOutTaskReopen",
  IssueAssign = "IssueAssign",
  IssueClose = "IssueClose",
  IssueReopen = "IssueReopen",
}

export type SurveySection = {
  __typename?: "SurveySection";
  description?: Maybe<Scalars["String"]["output"]>;
  questions: Array<Scalars["JSONObject"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SystemMessage = {
  __typename?: "SystemMessage";
  level: SystemMessageLogLevel;
  message: Scalars["String"]["output"];
};

export enum SystemMessageLogLevel {
  Error = "error",
  Info = "info",
  Warning = "warning",
}

export type Tag = {
  __typename?: "Tag";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type TerminationReason = {
  __typename?: "TerminationReason";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  noneOfTheAbove?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TextAnswer = {
  __typename?: "TextAnswer";
  changeState?: Maybe<QuestionChangeState>;
  comment?: Maybe<Scalars["String"]["output"]>;
  media?: Maybe<Array<Scalars["String"]["output"]>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type TextQuestion = {
  __typename?: "TextQuestion";
  changeState?: Maybe<QuestionChangeState>;
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  landlordAnswer: TextAnswer;
  previousAnswer?: Maybe<TextAnswer>;
  status: InspectionItemStatus;
  tenantAnswer: TextAnswer;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type TextSurveyQuestion = {
  __typename?: "TextSurveyQuestion";
  helpText?: Maybe<Scalars["String"]["output"]>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  type: QuestionType;
};

export type Token = {
  __typename?: "Token";
  access_token: Scalars["String"]["output"];
  expires_in?: Maybe<Scalars["Int"]["output"]>;
  id_token: Scalars["String"]["output"];
  refresh_token?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Scalars["String"]["output"]>;
  token_type?: Maybe<Scalars["String"]["output"]>;
};

export enum UnitCategory {
  Couple = "couple",
  Single = "single",
}

export type UnitMedia = {
  __typename?: "UnitMedia";
  id: Scalars["Int"]["output"];
  media?: Maybe<Media>;
  rank: Scalars["String"]["output"];
};

export enum UnitType {
  Entire = "entire",
  Shared = "shared",
}

export type UpdateApartmentAttributesInput = {
  addressRegistration?: InputMaybe<Scalars["Boolean"]["input"]>;
  airConditioning?: InputMaybe<Scalars["Boolean"]["input"]>;
  balconyOrTerrace?: InputMaybe<Scalars["Boolean"]["input"]>;
  bathrooms?: InputMaybe<Scalars["Int"]["input"]>;
  bedrooms?: InputMaybe<Scalars["Int"]["input"]>;
  /** [[bedCount, width, length]] */
  bedsSizes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>>>;
  constructionYear?: InputMaybe<Scalars["Int"]["input"]>;
  desk?: InputMaybe<Scalars["Boolean"]["input"]>;
  dishwasher?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryer?: InputMaybe<Scalars["Boolean"]["input"]>;
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  furnished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  kitchen?: InputMaybe<Scalars["Boolean"]["input"]>;
  linenAndTowels?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingDescription?: InputMaybe<Scalars["String"]["input"]>;
  listingTitle?: InputMaybe<Scalars["String"]["input"]>;
  locationDescription?: InputMaybe<Scalars["String"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  roomsCount?: InputMaybe<Scalars["Int"]["input"]>;
  sharedBathroom?: InputMaybe<Scalars["Boolean"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  spaceDescription?: InputMaybe<Scalars["String"]["input"]>;
  storage?: InputMaybe<Scalars["Boolean"]["input"]>;
  tv?: InputMaybe<Scalars["Boolean"]["input"]>;
  washer?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  wifi?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateBookableUnitInput = {
  apartmentId?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  closeDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["Int"]["input"];
  listingStatuses?: InputMaybe<Array<BaseUpdateListingStatus>>;
  openDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedNote?: InputMaybe<Scalars["String"]["input"]>;
  roomId?: InputMaybe<Scalars["Float"]["input"]>;
  sharedSpaceSize?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateBuildingAttributesInput = {
  bikePark?: InputMaybe<Scalars["Boolean"]["input"]>;
  canteen?: InputMaybe<Scalars["Boolean"]["input"]>;
  cinemaRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  communalKitchen?: InputMaybe<Scalars["Boolean"]["input"]>;
  communalSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  constructionYear?: InputMaybe<Scalars["Int"]["input"]>;
  cowokringSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  elevator?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeCarPark?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  laundryRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingDescription?: InputMaybe<Scalars["String"]["input"]>;
  listingTitle?: InputMaybe<Scalars["String"]["input"]>;
  locationDescription?: InputMaybe<Scalars["String"]["input"]>;
  nbFloor?: InputMaybe<Scalars["Int"]["input"]>;
  outdoorSpace?: InputMaybe<Scalars["Boolean"]["input"]>;
  paidCarPark?: InputMaybe<Scalars["Boolean"]["input"]>;
  sharedStorage?: InputMaybe<Scalars["Boolean"]["input"]>;
  spaceDescription?: InputMaybe<Scalars["String"]["input"]>;
  sportFacilities?: InputMaybe<Scalars["Boolean"]["input"]>;
  wellnessFacilities?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateOrganisationInput = {
  listingEmail?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateRemoteServiceKeyInput = {
  id: Scalars["Int"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  name: RemoteServiceKeyNames;
};

export type UpdateRoomAttributesInput = {
  /** [[bedCount, width, length]] */
  bedsSizes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>>>;
  desk?: InputMaybe<Scalars["Boolean"]["input"]>;
  ensuiteBathroom?: InputMaybe<Scalars["Boolean"]["input"]>;
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  furnished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  linenAndTowels?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingDescription?: InputMaybe<Scalars["String"]["input"]>;
  listingTitle?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  privateBalcony?: InputMaybe<Scalars["Boolean"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  storage?: InputMaybe<Scalars["Boolean"]["input"]>;
  tv?: InputMaybe<Scalars["Boolean"]["input"]>;
  wheelchairAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateRoomInput = {
  apartmentId: Scalars["Int"]["input"];
  bookableUnit: UpdateBookableUnitInput;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  roomAttributes?: InputMaybe<UpdateRoomAttributesInput>;
  roomTypeId: Scalars["Int"]["input"];
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpodiInvoice = {
  __typename?: "UpodiInvoice";
  bank: Bank;
  currencyCode: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  invoiceDate: Scalars["String"]["output"];
  invoiceNumber: Scalars["String"]["output"];
  invoiceStatus: Scalars["Int"]["output"];
  invoiceUrl: Scalars["String"]["output"];
  invoicingTenantId: Scalars["Int"]["output"];
  isCreditNote?: Maybe<Scalars["Boolean"]["output"]>;
  paymentDate?: Maybe<Scalars["String"]["output"]>;
  paymentStatus: Scalars["Int"]["output"];
  totalAmount: Scalars["Float"]["output"];
};

export type User = {
  __typename?: "User";
  about?: Maybe<Scalars["String"]["output"]>;
  accountingCodeId: Scalars["Int"]["output"];
  allStays: Array<Stay>;
  apartmentsAsHousekeeper?: Maybe<Array<Apartment>>;
  apartmentsAsOpsManager?: Maybe<Array<Apartment>>;
  bank?: Maybe<Bank>;
  birthday?: Maybe<Scalars["DateTime"]["output"]>;
  company?: Maybe<Company>;
  createdAt: Scalars["DateTime"]["output"];
  currentApartment?: Maybe<Apartment>;
  currentCity?: Maybe<City>;
  currentCountry?: Maybe<Country>;
  currentRoom?: Maybe<Room>;
  currentStay?: Maybe<Stay>;
  customerComIdentityVerification: Scalars["String"]["output"];
  customerCommunicationServiceId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  gender?: Maybe<Scalars["String"]["output"]>;
  hasAcceptedHouseRules?: Maybe<Scalars["DateTime"]["output"]>;
  hasCompletedOnboardingProfile?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  immutableFirstName: Scalars["String"]["output"];
  immutableFullName: Scalars["String"]["output"];
  immutableLastName: Scalars["String"]["output"];
  /** @deprecated use customerCommunicationServiceId instead */
  intercomId?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated use customerComIdentityVerification instead */
  intercomIdentityVerification: Scalars["String"]["output"];
  invoices: Array<UpodiInvoice>;
  languageCode?: Maybe<Scalars["String"]["output"]>;
  lastName: Scalars["String"]["output"];
  lastStay?: Maybe<Stay>;
  nationality?: Maybe<Scalars["String"]["output"]>;
  nextStay?: Maybe<Stay>;
  note?: Maybe<Scalars["String"]["output"]>;
  ongoingStay?: Maybe<Stay>;
  permission: Permission;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  profession?: Maybe<Scalars["String"]["output"]>;
  profilePicture?: Maybe<Scalars["String"]["output"]>;
  responses: Array<EventResponse>;
  roles?: Maybe<Array<Role>>;
  slug: Scalars["String"]["output"];
  /** @deprecated use unreadServiceConversations instead */
  unreadIntercomConversations: Scalars["Int"]["output"];
  unreadServiceConversations: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  workplace?: Maybe<Scalars["String"]["output"]>;
};

export type UserFilter = {
  current?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type Value = {
  __typename?: "Value";
  apartment?: Maybe<Apartment>;
  building?: Maybe<Room>;
  room?: Maybe<Room>;
  type: SpecificationUnitType;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ValueOneValue = {
  __typename?: "ValueOneValue";
  apartment?: Maybe<Apartment>;
  building?: Maybe<Room>;
  id: Scalars["Int"]["output"];
  room?: Maybe<Room>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ApartmentAmenitiesFragment = {
  __typename?: "Apartment";
  apartmentAttributes: {
    __typename?: "ApartmentAttributes";
    furnished: boolean;
    petsAllowed: boolean;
    sharedBathroom: boolean;
    kitchen: boolean;
    balconyOrTerrace: boolean;
    airConditioning: boolean;
    tv: boolean;
    wifi: boolean;
    storage: boolean;
    washer: boolean;
    dryer: boolean;
    dishwasher: boolean;
    wheelchairAccessible: boolean;
    linenAndTowels: boolean;
    desk: boolean;
  };
};

export type ApartmentCardFragment = {
  __typename?: "ListingType";
  availableFrom?: any | null;
  availableRooms?: number | null;
  available?: boolean | null;
  citySlug: string;
  currency: string;
  apartmentName: string;
  apartmentSuburb?: string | null;
  apartmentSlug: string;
  apartmentTypeName?: string | null;
  totalRooms?: number | null;
  minPrice?: number | null;
  startingPrice?: number | null;
  unitType: UnitType;
  apartmentCmsData?: any | null;
  apartmentMedia?: Array<string> | null;
  buildingMedia?: Array<string> | null;
  apartmentTypeDescription?: string | null;
};

export type ApartmentDescriptionFragment = {
  __typename?: "Apartment";
  apartmentAttributes: {
    __typename?: "ApartmentAttributes";
    addressRegistration?: boolean | null;
    spaceDescription?: string | null;
  };
};

export type ApartmentFloorPlanFragment = {
  __typename?: "Apartment";
  apartmentAttributes: { __typename?: "ApartmentAttributes"; floorPlan?: string | null };
};

export type ApartmentHeroFragment = {
  __typename?: "ListingType";
  apartmentCmsData?: any | null;
  apartmentName: string;
  apartmentSuburb?: string | null;
  apartmentSlug: string;
  apartmentSize?: number | null;
  citySlug: string;
  totalRooms?: number | null;
  unitType: UnitType;
  apartmentMedia?: Array<string> | null;
  buildingMedia?: Array<string> | null;
  apartmentTypeName?: string | null;
  apartmentTypeDescription?: string | null;
  startingPrice?: number | null;
  currency: string;
  available?: boolean | null;
  availableFrom?: any | null;
  apartment: {
    __typename?: "Apartment";
    minMonthsRentalPeriod?: number | null;
    maxMonthsRentalPeriod?: number | null;
    terminationNoticePeriod?: number | null;
    apartmentAttributes: {
      __typename?: "ApartmentAttributes";
      bathrooms?: number | null;
      bedrooms?: number | null;
      floor?: number | null;
      floorPlan?: string | null;
      listingTitle?: string | null;
      listingDescription?: string | null;
      roomsCount?: number | null;
      bedsSizes: Array<Array<number | null> | null>;
    };
  };
};

export type ApartmentInfoCardFragment = {
  __typename?: "ListingType";
  apartmentTypeName?: string | null;
  apartmentName: string;
  apartmentTypeDescription?: string | null;
  apartmentSize?: number | null;
  totalRooms?: number | null;
  apartmentSlug: string;
  startingPrice?: number | null;
  currency: string;
  available?: boolean | null;
  availableFrom?: any | null;
  unitType: UnitType;
  apartment: {
    __typename?: "Apartment";
    minMonthsRentalPeriod?: number | null;
    maxMonthsRentalPeriod?: number | null;
    terminationNoticePeriod?: number | null;
  };
};

export type ApartmentLocationFragment = {
  __typename?: "Apartment";
  apartmentAttributes: { __typename?: "ApartmentAttributes"; locationDescription?: string | null };
  buildingAttributes?: { __typename?: "BuildingAttributes"; locationDescription?: string | null } | null;
  building?: {
    __typename?: "Building";
    buildingAttributes: { __typename?: "BuildingAttributes"; locationDescription?: string | null };
  } | null;
};

export type ApartmentPopoverApartmentFragment = {
  __typename?: "ListingType";
  availableFrom?: any | null;
  availableRooms?: number | null;
  citySlug: string;
  currency: string;
  apartmentName: string;
  apartmentSuburb?: string | null;
  apartmentSlug: string;
  minPrice?: number | null;
  startingPrice?: number | null;
  apartmentCmsData?: any | null;
};

export type ApartmentTabsFragment = {
  __typename?: "ListingType";
  apartment: {
    __typename?: "Apartment";
    apartmentAttributes: {
      __typename?: "ApartmentAttributes";
      furnished: boolean;
      petsAllowed: boolean;
      sharedBathroom: boolean;
      kitchen: boolean;
      balconyOrTerrace: boolean;
      airConditioning: boolean;
      tv: boolean;
      wifi: boolean;
      storage: boolean;
      washer: boolean;
      dryer: boolean;
      dishwasher: boolean;
      wheelchairAccessible: boolean;
      linenAndTowels: boolean;
      desk: boolean;
      addressRegistration?: boolean | null;
      spaceDescription?: string | null;
      locationDescription?: string | null;
      floorPlan?: string | null;
    };
    buildingAttributes?: {
      __typename?: "BuildingAttributes";
      locationDescription?: string | null;
      listingDescription?: string | null;
      nbFloor?: number | null;
      constructionYear?: number | null;
      freeCarPark: boolean;
      paidCarPark: boolean;
      bikePark: boolean;
      wheelchairAccessible: boolean;
      elevator: boolean;
      communalKitchen: boolean;
      laundryRoom: boolean;
      canteen: boolean;
      communalSpace: boolean;
      cowokringSpace: boolean;
      sportFacilities: boolean;
      wellnessFacilities: boolean;
      outdoorSpace: boolean;
      sharedStorage: boolean;
      cinemaRoom: boolean;
      spaceDescription?: string | null;
    } | null;
    building?: {
      __typename?: "Building";
      buildingAttributes: {
        __typename?: "BuildingAttributes";
        locationDescription?: string | null;
        listingDescription?: string | null;
        nbFloor?: number | null;
        constructionYear?: number | null;
        freeCarPark: boolean;
        paidCarPark: boolean;
        bikePark: boolean;
        wheelchairAccessible: boolean;
        elevator: boolean;
        communalKitchen: boolean;
        laundryRoom: boolean;
        canteen: boolean;
        communalSpace: boolean;
        cowokringSpace: boolean;
        sportFacilities: boolean;
        wellnessFacilities: boolean;
        outdoorSpace: boolean;
        sharedStorage: boolean;
        cinemaRoom: boolean;
        spaceDescription?: string | null;
      };
    } | null;
  };
};

export type ApartmentTypeFragment = {
  __typename?: "ListingType";
  apartmentTypeName?: string | null;
  apartmentTypeDescription?: string | null;
};

export type AvailabilityMapPinApartmentFragment = {
  __typename?: "ListingType";
  apartmentId: number;
  availableFrom?: any | null;
  apartmentLatitude?: number | null;
  apartmentLongitude?: number | null;
  availableRooms?: number | null;
  citySlug: string;
  currency: string;
  apartmentName: string;
  apartmentSuburb?: string | null;
  apartmentSlug: string;
  minPrice?: number | null;
  startingPrice?: number | null;
  apartmentCmsData?: any | null;
};

export type AvailableApartmentsPreviewQueryVariables = Exact<{
  citySlug: Scalars["String"]["input"];
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type AvailableApartmentsPreviewQuery = {
  __typename?: "Query";
  listings: Array<{
    __typename?: "ListingType";
    apartmentId: number;
    availableFrom?: any | null;
    availableRooms?: number | null;
    available?: boolean | null;
    citySlug: string;
    currency: string;
    apartmentName: string;
    apartmentSuburb?: string | null;
    apartmentSlug: string;
    apartmentTypeName?: string | null;
    totalRooms?: number | null;
    minPrice?: number | null;
    startingPrice?: number | null;
    unitType: UnitType;
    apartmentCmsData?: any | null;
    apartmentMedia?: Array<string> | null;
    buildingMedia?: Array<string> | null;
    apartmentTypeDescription?: string | null;
  }>;
};

export type AvailableRoomsInCityQueryVariables = Exact<{
  citySlug: Scalars["String"]["input"];
}>;

export type AvailableRoomsInCityQuery = {
  __typename?: "Query";
  cityBySlug?: { __typename?: "City"; id: number; name: string; availableUnits: number } | null;
};

export type BuildingAmenitiesFragment = {
  __typename?: "Apartment";
  buildingAttributes?: {
    __typename?: "BuildingAttributes";
    listingDescription?: string | null;
    locationDescription?: string | null;
    nbFloor?: number | null;
    constructionYear?: number | null;
    freeCarPark: boolean;
    paidCarPark: boolean;
    bikePark: boolean;
    wheelchairAccessible: boolean;
    elevator: boolean;
    communalKitchen: boolean;
    laundryRoom: boolean;
    canteen: boolean;
    communalSpace: boolean;
    cowokringSpace: boolean;
    sportFacilities: boolean;
    wellnessFacilities: boolean;
    outdoorSpace: boolean;
    sharedStorage: boolean;
    cinemaRoom: boolean;
    spaceDescription?: string | null;
  } | null;
  building?: {
    __typename?: "Building";
    buildingAttributes: {
      __typename?: "BuildingAttributes";
      listingDescription?: string | null;
      locationDescription?: string | null;
      nbFloor?: number | null;
      constructionYear?: number | null;
      freeCarPark: boolean;
      paidCarPark: boolean;
      bikePark: boolean;
      wheelchairAccessible: boolean;
      elevator: boolean;
      communalKitchen: boolean;
      laundryRoom: boolean;
      canteen: boolean;
      communalSpace: boolean;
      cowokringSpace: boolean;
      sportFacilities: boolean;
      wellnessFacilities: boolean;
      outdoorSpace: boolean;
      sharedStorage: boolean;
      cinemaRoom: boolean;
      spaceDescription?: string | null;
    };
  } | null;
};

export type CitiesQueryVariables = Exact<{
  filter?: InputMaybe<CityDefaultFilter>;
}>;

export type CitiesQuery = {
  __typename?: "Query";
  cities: Array<{ __typename?: "City"; id: number; slug: string; name: string; cmsData?: any | null }>;
};

export type CitiesWithPublishedApartmentsQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesWithPublishedApartmentsQuery = {
  __typename?: "Query";
  cities: Array<{ __typename?: "City"; id: number; slug: string; name: string }>;
};

export type CityCardFragment = { __typename?: "City"; slug: string; name: string; cmsData?: any | null };

export type RoomCardRoomFragment = {
  __typename?: "BookableUnit";
  id: number;
  available: boolean;
  availableFrom?: any | null;
  category: UnitCategory;
  rent?: number | null;
  apartment: {
    __typename?: "Apartment";
    id: number;
    apartmentAttributes: { __typename?: "ApartmentAttributes"; id: number; floorPlan?: string | null };
  };
  room?: {
    __typename?: "Room";
    id: number;
    name: string;
    cmsData?: any | null;
    unitMedia: Array<{
      __typename?: "UnitMedia";
      id: number;
      media?: { __typename?: "Media"; id: number; filename: string; uri: string } | null;
    }>;
    roomAttributes: {
      __typename?: "RoomAttributes";
      size?: number | null;
      bedsSizes: Array<Array<number | null> | null>;
      furnished: boolean;
      linenAndTowels: boolean;
      desk: boolean;
      storage: boolean;
      tv: boolean;
      privateBalcony: boolean;
      ensuiteBathroom: boolean;
      listingDescription?: string | null;
    };
    roomType: { __typename?: "RoomType"; name: string };
  } | null;
};

export type UnitSignupFragment = {
  __typename?: "ListingType";
  apartmentName: string;
  apartmentTypeName?: string | null;
  apartmentSlug: string;
  apartmentId: number;
  apartmentSuburb?: string | null;
  citySlug: string;
  cityName: string;
  currency: string;
  minPrice?: number | null;
  availableFrom?: any | null;
  startingPrice?: number | null;
};

export type RoomSignupFragment = {
  __typename?: "BookableUnit";
  rent?: number | null;
  category: UnitCategory;
  room?: {
    __typename?: "Room";
    id: number;
    slug: string;
    name: string;
    cmsData?: any | null;
    roomType: { __typename?: "RoomType"; name: string };
  } | null;
};

export type CreateAvailableUnitAlertSubscriptionMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  frequency: AvailableUnitAlertFrequency;
  tenantId?: InputMaybe<Scalars["String"]["input"]>;
  cityId?: InputMaybe<Scalars["Float"]["input"]>;
  minPrice?: InputMaybe<Scalars["Float"]["input"]>;
  maxPrice?: InputMaybe<Scalars["Float"]["input"]>;
  availableFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  type?: InputMaybe<UnitType>;
}>;

export type CreateAvailableUnitAlertSubscriptionMutation = {
  __typename?: "Mutation";
  createAvailableUnitAlertSubscription: { __typename?: "AvailableUnitAlertSubscription"; id: number };
};

export type BuildingBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type BuildingBySlugQuery = {
  __typename?: "Query";
  buildingListingBySlug?: {
    __typename?: "BuildingListingType";
    name: string;
    slug: string;
    citySlug?: string | null;
    cmsData?: any | null;
    specifications: Array<{
      __typename?: "SpecificationOneValue";
      id: number;
      attributes: Array<{
        __typename?: "AttributeOneValue";
        id: number;
        name: string;
        key?: string | null;
        icon?: string | null;
        value?: { __typename?: "ValueOneValue"; id: number; value?: string | null } | null;
      }>;
    }>;
  } | null;
};

export type BuildingStaticPathsQueryVariables = Exact<{ [key: string]: never }>;

export type BuildingStaticPathsQuery = {
  __typename?: "Query";
  buildingListings?: Array<{ __typename?: "BuildingListingType"; slug: string; citySlug?: string | null }> | null;
};

export type ApartmentBySlugWithRoomsQueryVariables = Exact<{
  apartmentSlug: Scalars["String"]["input"];
}>;

export type ApartmentBySlugWithRoomsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "ListingType";
    apartmentName: string;
    apartmentTypeName?: string | null;
    apartmentSlug: string;
    apartmentId: number;
    apartmentSuburb?: string | null;
    citySlug: string;
    cityName: string;
    currency: string;
    minPrice?: number | null;
    availableFrom?: any | null;
    startingPrice?: number | null;
    bookableUnits: Array<{
      __typename?: "BookableUnit";
      rent?: number | null;
      category: UnitCategory;
      room?: {
        __typename?: "Room";
        id: number;
        slug: string;
        name: string;
        cmsData?: any | null;
        roomType: { __typename?: "RoomType"; name: string };
      } | null;
    }>;
  } | null;
};

export type RoomStaticPathsQueryVariables = Exact<{
  unitType?: InputMaybe<UnitType>;
}>;

export type RoomStaticPathsQuery = {
  __typename?: "Query";
  listings: Array<{
    __typename?: "ListingType";
    citySlug: string;
    apartmentSlug: string;
    bookableUnits: Array<{
      __typename?: "BookableUnit";
      room?: {
        __typename?: "Room";
        cmsData?: any | null;
        name: string;
        roomType: { __typename?: "RoomType"; id: number; name: string };
      } | null;
    }>;
  }>;
};

export type ListingBySlugQueryVariables = Exact<{
  apartmentSlug: Scalars["String"]["input"];
}>;

export type ListingBySlugQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "ListingType";
    apartmentId: number;
    apartmentName: string;
    apartmentSlug: string;
    apartmentSuburb?: string | null;
    apartmentLatitude?: number | null;
    apartmentLongitude?: number | null;
    apartmentSize?: number | null;
    apartmentTypeName?: string | null;
    citySlug: string;
    cityName: string;
    available?: boolean | null;
    availableFrom?: any | null;
    availableRooms?: number | null;
    totalRooms?: number | null;
    minPrice?: number | null;
    startingPrice?: number | null;
    currency: string;
    unitType: UnitType;
    apartmentCmsData?: any | null;
    apartmentTypeDescription?: string | null;
    apartmentMedia?: Array<string> | null;
    buildingMedia?: Array<string> | null;
    apartment: {
      __typename?: "Apartment";
      minMonthsRentalPeriod?: number | null;
      maxMonthsRentalPeriod?: number | null;
      terminationNoticePeriod?: number | null;
      apartmentAttributes: {
        __typename?: "ApartmentAttributes";
        listingTitle?: string | null;
        listingDescription?: string | null;
        bathrooms?: number | null;
        bedrooms?: number | null;
        floor?: number | null;
        floorPlan?: string | null;
        roomsCount?: number | null;
        bedsSizes: Array<Array<number | null> | null>;
        furnished: boolean;
        petsAllowed: boolean;
        sharedBathroom: boolean;
        kitchen: boolean;
        balconyOrTerrace: boolean;
        airConditioning: boolean;
        tv: boolean;
        wifi: boolean;
        storage: boolean;
        washer: boolean;
        dryer: boolean;
        dishwasher: boolean;
        wheelchairAccessible: boolean;
        linenAndTowels: boolean;
        desk: boolean;
        addressRegistration?: boolean | null;
        spaceDescription?: string | null;
        locationDescription?: string | null;
      };
      buildingAttributes?: {
        __typename?: "BuildingAttributes";
        locationDescription?: string | null;
        listingDescription?: string | null;
        nbFloor?: number | null;
        constructionYear?: number | null;
        freeCarPark: boolean;
        paidCarPark: boolean;
        bikePark: boolean;
        wheelchairAccessible: boolean;
        elevator: boolean;
        communalKitchen: boolean;
        laundryRoom: boolean;
        canteen: boolean;
        communalSpace: boolean;
        cowokringSpace: boolean;
        sportFacilities: boolean;
        wellnessFacilities: boolean;
        outdoorSpace: boolean;
        sharedStorage: boolean;
        cinemaRoom: boolean;
        spaceDescription?: string | null;
      } | null;
      building?: {
        __typename?: "Building";
        buildingAttributes: {
          __typename?: "BuildingAttributes";
          locationDescription?: string | null;
          listingDescription?: string | null;
          nbFloor?: number | null;
          constructionYear?: number | null;
          freeCarPark: boolean;
          paidCarPark: boolean;
          bikePark: boolean;
          wheelchairAccessible: boolean;
          elevator: boolean;
          communalKitchen: boolean;
          laundryRoom: boolean;
          canteen: boolean;
          communalSpace: boolean;
          cowokringSpace: boolean;
          sportFacilities: boolean;
          wellnessFacilities: boolean;
          outdoorSpace: boolean;
          sharedStorage: boolean;
          cinemaRoom: boolean;
          spaceDescription?: string | null;
        };
      } | null;
    };
    bookableUnits: Array<{
      __typename?: "BookableUnit";
      id: number;
      available: boolean;
      availableFrom?: any | null;
      category: UnitCategory;
      rent?: number | null;
      apartment: {
        __typename?: "Apartment";
        id: number;
        apartmentAttributes: { __typename?: "ApartmentAttributes"; id: number; floorPlan?: string | null };
      };
      room?: {
        __typename?: "Room";
        id: number;
        name: string;
        cmsData?: any | null;
        unitMedia: Array<{
          __typename?: "UnitMedia";
          id: number;
          media?: { __typename?: "Media"; id: number; filename: string; uri: string } | null;
        }>;
        roomAttributes: {
          __typename?: "RoomAttributes";
          size?: number | null;
          bedsSizes: Array<Array<number | null> | null>;
          furnished: boolean;
          linenAndTowels: boolean;
          desk: boolean;
          storage: boolean;
          tv: boolean;
          privateBalcony: boolean;
          ensuiteBathroom: boolean;
          listingDescription?: string | null;
        };
        roomType: { __typename?: "RoomType"; name: string };
      } | null;
    }>;
  } | null;
};

export type ApartmentStaticPathsQueryVariables = Exact<{ [key: string]: never }>;

export type ApartmentStaticPathsQuery = {
  __typename?: "Query";
  listings: Array<{ __typename?: "ListingType"; apartmentSlug: string; citySlug: string }>;
};

export type ApartmentSignupQueryVariables = Exact<{
  apartmentSlug: Scalars["String"]["input"];
}>;

export type ApartmentSignupQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "ListingType";
    unitType: UnitType;
    apartmentCmsData?: any | null;
    apartmentName: string;
    apartmentTypeName?: string | null;
    apartmentSlug: string;
    apartmentId: number;
    apartmentSuburb?: string | null;
    citySlug: string;
    cityName: string;
    currency: string;
    minPrice?: number | null;
    availableFrom?: any | null;
    startingPrice?: number | null;
  } | null;
};

export type EnitreSignupStaticPathsQueryVariables = Exact<{
  unitType?: InputMaybe<UnitType>;
}>;

export type EnitreSignupStaticPathsQuery = {
  __typename?: "Query";
  listings: Array<{ __typename?: "ListingType"; citySlug: string; apartmentSlug: string }>;
};

export type CityBySlugQueryVariables = Exact<{
  citySlug: Scalars["String"]["input"];
}>;

export type CityBySlugQuery = {
  __typename?: "Query";
  cityBySlug?: { __typename?: "City"; slug: string; cmsData?: any | null } | null;
};

export type CityStaticPathsQueryVariables = Exact<{
  filter?: InputMaybe<CityDefaultFilter>;
}>;

export type CityStaticPathsQuery = { __typename?: "Query"; cities: Array<{ __typename?: "City"; slug: string }> };

export type CityApartmentsQueryVariables = Exact<{
  buildingSlug?: InputMaybe<Scalars["String"]["input"]>;
  citySlug?: InputMaybe<Scalars["String"]["input"]>;
  category?: InputMaybe<UnitCategory>;
  availableFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  maxPrice?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<UnitType>;
}>;

export type CityApartmentsQuery = {
  __typename?: "Query";
  listings: Array<{
    __typename?: "ListingType";
    apartmentId: number;
    availableRooms?: number | null;
    availableFrom?: any | null;
    available?: boolean | null;
    citySlug: string;
    currency: string;
    apartmentName: string;
    apartmentSuburb?: string | null;
    apartmentSlug: string;
    apartmentTypeName?: string | null;
    totalRooms?: number | null;
    minPrice?: number | null;
    startingPrice?: number | null;
    unitType: UnitType;
    apartmentCmsData?: any | null;
    apartmentMedia?: Array<string> | null;
    buildingMedia?: Array<string> | null;
    apartmentLatitude?: number | null;
    apartmentLongitude?: number | null;
    apartmentTypeDescription?: string | null;
  }>;
};

export type StaticCityBySlugQueryVariables = Exact<{
  citySlug: Scalars["String"]["input"];
}>;

export type StaticCityBySlugQuery = {
  __typename?: "Query";
  cityBySlug?: { __typename?: "City"; id: number; name: string; slug: string } | null;
};

export type SignupsStaticPathsQueryVariables = Exact<{
  filter?: InputMaybe<CityDefaultFilter>;
}>;

export type SignupsStaticPathsQuery = {
  __typename?: "Query";
  cities: Array<{ __typename?: "City"; slug: string; name: string }>;
};

export const ApartmentTypeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentTypeFragment, unknown>;
export const ApartmentCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentType" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentMedia" } },
          { kind: "Field", name: { kind: "Name", value: "buildingMedia" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentCardFragment, unknown>;
export const ApartmentInfoCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentInfoCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSize" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "minMonthsRentalPeriod" } },
                { kind: "Field", name: { kind: "Name", value: "maxMonthsRentalPeriod" } },
                { kind: "Field", name: { kind: "Name", value: "terminationNoticePeriod" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentInfoCardFragment, unknown>;
export const ApartmentHeroFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentHero" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSize" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentMedia" } },
          { kind: "Field", name: { kind: "Name", value: "buildingMedia" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartmentAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "bathrooms" } },
                      { kind: "Field", name: { kind: "Name", value: "bedrooms" } },
                      { kind: "Field", name: { kind: "Name", value: "floor" } },
                      { kind: "Field", name: { kind: "Name", value: "floorPlan" } },
                      { kind: "Field", name: { kind: "Name", value: "listingTitle" } },
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "roomsCount" } },
                      { kind: "Field", name: { kind: "Name", value: "bedsSizes" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentInfoCard" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentInfoCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSize" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "minMonthsRentalPeriod" } },
                { kind: "Field", name: { kind: "Name", value: "maxMonthsRentalPeriod" } },
                { kind: "Field", name: { kind: "Name", value: "terminationNoticePeriod" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentHeroFragment, unknown>;
export const ApartmentAmenitiesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentAmenities" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "furnished" } },
                { kind: "Field", name: { kind: "Name", value: "petsAllowed" } },
                { kind: "Field", name: { kind: "Name", value: "sharedBathroom" } },
                { kind: "Field", name: { kind: "Name", value: "kitchen" } },
                { kind: "Field", name: { kind: "Name", value: "balconyOrTerrace" } },
                { kind: "Field", name: { kind: "Name", value: "airConditioning" } },
                { kind: "Field", name: { kind: "Name", value: "tv" } },
                { kind: "Field", name: { kind: "Name", value: "wifi" } },
                { kind: "Field", name: { kind: "Name", value: "storage" } },
                { kind: "Field", name: { kind: "Name", value: "washer" } },
                { kind: "Field", name: { kind: "Name", value: "dryer" } },
                { kind: "Field", name: { kind: "Name", value: "dishwasher" } },
                { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                { kind: "Field", name: { kind: "Name", value: "linenAndTowels" } },
                { kind: "Field", name: { kind: "Name", value: "desk" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentAmenitiesFragment, unknown>;
export const ApartmentDescriptionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentDescription" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "addressRegistration" } },
                { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentDescriptionFragment, unknown>;
export const ApartmentLocationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentLocation" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "building" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buildingAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentLocationFragment, unknown>;
export const ApartmentFloorPlanFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentFloorPlan" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "floorPlan" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentFloorPlanFragment, unknown>;
export const BuildingAmenitiesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuildingAmenities" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "nbFloor" } },
                { kind: "Field", name: { kind: "Name", value: "constructionYear" } },
                { kind: "Field", name: { kind: "Name", value: "freeCarPark" } },
                { kind: "Field", name: { kind: "Name", value: "paidCarPark" } },
                { kind: "Field", name: { kind: "Name", value: "bikePark" } },
                { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                { kind: "Field", name: { kind: "Name", value: "elevator" } },
                { kind: "Field", name: { kind: "Name", value: "communalKitchen" } },
                { kind: "Field", name: { kind: "Name", value: "laundryRoom" } },
                { kind: "Field", name: { kind: "Name", value: "canteen" } },
                { kind: "Field", name: { kind: "Name", value: "communalSpace" } },
                { kind: "Field", name: { kind: "Name", value: "cowokringSpace" } },
                { kind: "Field", name: { kind: "Name", value: "sportFacilities" } },
                { kind: "Field", name: { kind: "Name", value: "wellnessFacilities" } },
                { kind: "Field", name: { kind: "Name", value: "outdoorSpace" } },
                { kind: "Field", name: { kind: "Name", value: "sharedStorage" } },
                { kind: "Field", name: { kind: "Name", value: "cinemaRoom" } },
                { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "building" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buildingAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "nbFloor" } },
                      { kind: "Field", name: { kind: "Name", value: "constructionYear" } },
                      { kind: "Field", name: { kind: "Name", value: "freeCarPark" } },
                      { kind: "Field", name: { kind: "Name", value: "paidCarPark" } },
                      { kind: "Field", name: { kind: "Name", value: "bikePark" } },
                      { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                      { kind: "Field", name: { kind: "Name", value: "elevator" } },
                      { kind: "Field", name: { kind: "Name", value: "communalKitchen" } },
                      { kind: "Field", name: { kind: "Name", value: "laundryRoom" } },
                      { kind: "Field", name: { kind: "Name", value: "canteen" } },
                      { kind: "Field", name: { kind: "Name", value: "communalSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "cowokringSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "sportFacilities" } },
                      { kind: "Field", name: { kind: "Name", value: "wellnessFacilities" } },
                      { kind: "Field", name: { kind: "Name", value: "outdoorSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "sharedStorage" } },
                      { kind: "Field", name: { kind: "Name", value: "cinemaRoom" } },
                      { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuildingAmenitiesFragment, unknown>;
export const ApartmentTabsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentTabs" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentAmenities" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentDescription" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentLocation" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentFloorPlan" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "BuildingAmenities" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentAmenities" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "furnished" } },
                { kind: "Field", name: { kind: "Name", value: "petsAllowed" } },
                { kind: "Field", name: { kind: "Name", value: "sharedBathroom" } },
                { kind: "Field", name: { kind: "Name", value: "kitchen" } },
                { kind: "Field", name: { kind: "Name", value: "balconyOrTerrace" } },
                { kind: "Field", name: { kind: "Name", value: "airConditioning" } },
                { kind: "Field", name: { kind: "Name", value: "tv" } },
                { kind: "Field", name: { kind: "Name", value: "wifi" } },
                { kind: "Field", name: { kind: "Name", value: "storage" } },
                { kind: "Field", name: { kind: "Name", value: "washer" } },
                { kind: "Field", name: { kind: "Name", value: "dryer" } },
                { kind: "Field", name: { kind: "Name", value: "dishwasher" } },
                { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                { kind: "Field", name: { kind: "Name", value: "linenAndTowels" } },
                { kind: "Field", name: { kind: "Name", value: "desk" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentDescription" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "addressRegistration" } },
                { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentLocation" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "building" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buildingAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentFloorPlan" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "floorPlan" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuildingAmenities" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "nbFloor" } },
                { kind: "Field", name: { kind: "Name", value: "constructionYear" } },
                { kind: "Field", name: { kind: "Name", value: "freeCarPark" } },
                { kind: "Field", name: { kind: "Name", value: "paidCarPark" } },
                { kind: "Field", name: { kind: "Name", value: "bikePark" } },
                { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                { kind: "Field", name: { kind: "Name", value: "elevator" } },
                { kind: "Field", name: { kind: "Name", value: "communalKitchen" } },
                { kind: "Field", name: { kind: "Name", value: "laundryRoom" } },
                { kind: "Field", name: { kind: "Name", value: "canteen" } },
                { kind: "Field", name: { kind: "Name", value: "communalSpace" } },
                { kind: "Field", name: { kind: "Name", value: "cowokringSpace" } },
                { kind: "Field", name: { kind: "Name", value: "sportFacilities" } },
                { kind: "Field", name: { kind: "Name", value: "wellnessFacilities" } },
                { kind: "Field", name: { kind: "Name", value: "outdoorSpace" } },
                { kind: "Field", name: { kind: "Name", value: "sharedStorage" } },
                { kind: "Field", name: { kind: "Name", value: "cinemaRoom" } },
                { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "building" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buildingAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "nbFloor" } },
                      { kind: "Field", name: { kind: "Name", value: "constructionYear" } },
                      { kind: "Field", name: { kind: "Name", value: "freeCarPark" } },
                      { kind: "Field", name: { kind: "Name", value: "paidCarPark" } },
                      { kind: "Field", name: { kind: "Name", value: "bikePark" } },
                      { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                      { kind: "Field", name: { kind: "Name", value: "elevator" } },
                      { kind: "Field", name: { kind: "Name", value: "communalKitchen" } },
                      { kind: "Field", name: { kind: "Name", value: "laundryRoom" } },
                      { kind: "Field", name: { kind: "Name", value: "canteen" } },
                      { kind: "Field", name: { kind: "Name", value: "communalSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "cowokringSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "sportFacilities" } },
                      { kind: "Field", name: { kind: "Name", value: "wellnessFacilities" } },
                      { kind: "Field", name: { kind: "Name", value: "outdoorSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "sharedStorage" } },
                      { kind: "Field", name: { kind: "Name", value: "cinemaRoom" } },
                      { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentTabsFragment, unknown>;
export const ApartmentPopoverApartmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentPopoverApartment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentPopoverApartmentFragment, unknown>;
export const AvailabilityMapPinApartmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailabilityMapPinApartment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentLatitude" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentLongitude" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentPopoverApartment" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentPopoverApartment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailabilityMapPinApartmentFragment, unknown>;
export const CityCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CityCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "City" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "cmsData" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityCardFragment, unknown>;
export const RoomCardRoomFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RoomCardRoom" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BookableUnit" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "rent" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartmentAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "floorPlan" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "room" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitMedia" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "media" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "filename" } },
                            { kind: "Field", name: { kind: "Name", value: "uri" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roomAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "size" } },
                      { kind: "Field", name: { kind: "Name", value: "bedsSizes" } },
                      { kind: "Field", name: { kind: "Name", value: "furnished" } },
                      { kind: "Field", name: { kind: "Name", value: "linenAndTowels" } },
                      { kind: "Field", name: { kind: "Name", value: "desk" } },
                      { kind: "Field", name: { kind: "Name", value: "storage" } },
                      { kind: "Field", name: { kind: "Name", value: "tv" } },
                      { kind: "Field", name: { kind: "Name", value: "privateBalcony" } },
                      { kind: "Field", name: { kind: "Name", value: "ensuiteBathroom" } },
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roomType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "cmsData" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoomCardRoomFragment, unknown>;
export const UnitSignupFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UnitSignup" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "cityName" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnitSignupFragment, unknown>;
export const RoomSignupFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RoomSignup" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BookableUnit" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "rent" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "room" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roomType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "cmsData" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoomSignupFragment, unknown>;
export const AvailableApartmentsPreviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AvailableApartmentsPreview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "perPage" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "citySlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "perPage" },
                value: { kind: "Variable", name: { kind: "Name", value: "perPage" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentCard" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentType" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentMedia" } },
          { kind: "Field", name: { kind: "Name", value: "buildingMedia" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableApartmentsPreviewQuery, AvailableApartmentsPreviewQueryVariables>;
export const AvailableRoomsInCityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AvailableRoomsInCity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cityBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableUnits" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "published" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableRoomsInCityQuery, AvailableRoomsInCityQueryVariables>;
export const CitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Cities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CityDefaultFilter" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "CityCard" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CityCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "City" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "cmsData" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CitiesQuery, CitiesQueryVariables>;
export const CitiesWithPublishedApartmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CitiesWithPublishedApartments" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "unitPublished" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CitiesWithPublishedApartmentsQuery, CitiesWithPublishedApartmentsQueryVariables>;
export const CreateAvailableUnitAlertSubscriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateAvailableUnitAlertSubscription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "frequency" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "AvailableUnitAlertFrequency" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tenantId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "cityId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "minPrice" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "maxPrice" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "availableFrom" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DateTime" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "category" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UnitCategory" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UnitType" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createAvailableUnitAlertSubscription" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "frequency" },
                value: { kind: "Variable", name: { kind: "Name", value: "frequency" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenantId" },
                value: { kind: "Variable", name: { kind: "Name", value: "tenantId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "cityId" },
                value: { kind: "Variable", name: { kind: "Name", value: "cityId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "minPrice" },
                value: { kind: "Variable", name: { kind: "Name", value: "minPrice" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxPrice" },
                value: { kind: "Variable", name: { kind: "Name", value: "maxPrice" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "availableFrom" },
                value: { kind: "Variable", name: { kind: "Name", value: "availableFrom" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "category" },
                value: { kind: "Variable", name: { kind: "Name", value: "category" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAvailableUnitAlertSubscriptionMutation,
  CreateAvailableUnitAlertSubscriptionMutationVariables
>;
export const BuildingBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BuildingBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingListingBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "citySlug" } },
                { kind: "Field", name: { kind: "Name", value: "cmsData" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specifications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "key" } },
                            { kind: "Field", name: { kind: "Name", value: "icon" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuildingBySlugQuery, BuildingBySlugQueryVariables>;
export const BuildingStaticPathsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BuildingStaticPaths" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingListings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "citySlug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuildingStaticPathsQuery, BuildingStaticPathsQueryVariables>;
export const ApartmentBySlugWithRoomsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApartmentBySlugWithRooms" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "apartmentSlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "apartmentSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "apartmentSlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "UnitSignup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bookableUnits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RoomSignup" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UnitSignup" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "cityName" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RoomSignup" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BookableUnit" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "rent" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "room" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roomType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "cmsData" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentBySlugWithRoomsQuery, ApartmentBySlugWithRoomsQueryVariables>;
export const RoomStaticPathsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RoomStaticPaths" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unitType" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UnitType" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitType" },
                value: { kind: "Variable", name: { kind: "Name", value: "unitType" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "citySlug" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bookableUnits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "room" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "cmsData" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roomType" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoomStaticPathsQuery, RoomStaticPathsQueryVariables>;
export const ListingBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListingBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "apartmentSlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "apartmentSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "apartmentSlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentLatitude" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentLongitude" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentSize" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
                { kind: "Field", name: { kind: "Name", value: "citySlug" } },
                { kind: "Field", name: { kind: "Name", value: "cityName" } },
                { kind: "Field", name: { kind: "Name", value: "available" } },
                { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
                { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
                { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
                { kind: "Field", name: { kind: "Name", value: "minPrice" } },
                { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "unitType" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartmentAttributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "listingTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bookableUnits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RoomCardRoom" } }],
                  },
                },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentInfoCard" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentHero" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentTabs" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentInfoCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSize" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "minMonthsRentalPeriod" } },
                { kind: "Field", name: { kind: "Name", value: "maxMonthsRentalPeriod" } },
                { kind: "Field", name: { kind: "Name", value: "terminationNoticePeriod" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentAmenities" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "furnished" } },
                { kind: "Field", name: { kind: "Name", value: "petsAllowed" } },
                { kind: "Field", name: { kind: "Name", value: "sharedBathroom" } },
                { kind: "Field", name: { kind: "Name", value: "kitchen" } },
                { kind: "Field", name: { kind: "Name", value: "balconyOrTerrace" } },
                { kind: "Field", name: { kind: "Name", value: "airConditioning" } },
                { kind: "Field", name: { kind: "Name", value: "tv" } },
                { kind: "Field", name: { kind: "Name", value: "wifi" } },
                { kind: "Field", name: { kind: "Name", value: "storage" } },
                { kind: "Field", name: { kind: "Name", value: "washer" } },
                { kind: "Field", name: { kind: "Name", value: "dryer" } },
                { kind: "Field", name: { kind: "Name", value: "dishwasher" } },
                { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                { kind: "Field", name: { kind: "Name", value: "linenAndTowels" } },
                { kind: "Field", name: { kind: "Name", value: "desk" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentDescription" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "addressRegistration" } },
                { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentLocation" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "building" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buildingAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "locationDescription" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentFloorPlan" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartmentAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "floorPlan" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuildingAmenities" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Apartment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "buildingAttributes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "nbFloor" } },
                { kind: "Field", name: { kind: "Name", value: "constructionYear" } },
                { kind: "Field", name: { kind: "Name", value: "freeCarPark" } },
                { kind: "Field", name: { kind: "Name", value: "paidCarPark" } },
                { kind: "Field", name: { kind: "Name", value: "bikePark" } },
                { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                { kind: "Field", name: { kind: "Name", value: "elevator" } },
                { kind: "Field", name: { kind: "Name", value: "communalKitchen" } },
                { kind: "Field", name: { kind: "Name", value: "laundryRoom" } },
                { kind: "Field", name: { kind: "Name", value: "canteen" } },
                { kind: "Field", name: { kind: "Name", value: "communalSpace" } },
                { kind: "Field", name: { kind: "Name", value: "cowokringSpace" } },
                { kind: "Field", name: { kind: "Name", value: "sportFacilities" } },
                { kind: "Field", name: { kind: "Name", value: "wellnessFacilities" } },
                { kind: "Field", name: { kind: "Name", value: "outdoorSpace" } },
                { kind: "Field", name: { kind: "Name", value: "sharedStorage" } },
                { kind: "Field", name: { kind: "Name", value: "cinemaRoom" } },
                { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "building" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buildingAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "nbFloor" } },
                      { kind: "Field", name: { kind: "Name", value: "constructionYear" } },
                      { kind: "Field", name: { kind: "Name", value: "freeCarPark" } },
                      { kind: "Field", name: { kind: "Name", value: "paidCarPark" } },
                      { kind: "Field", name: { kind: "Name", value: "bikePark" } },
                      { kind: "Field", name: { kind: "Name", value: "wheelchairAccessible" } },
                      { kind: "Field", name: { kind: "Name", value: "elevator" } },
                      { kind: "Field", name: { kind: "Name", value: "communalKitchen" } },
                      { kind: "Field", name: { kind: "Name", value: "laundryRoom" } },
                      { kind: "Field", name: { kind: "Name", value: "canteen" } },
                      { kind: "Field", name: { kind: "Name", value: "communalSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "cowokringSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "sportFacilities" } },
                      { kind: "Field", name: { kind: "Name", value: "wellnessFacilities" } },
                      { kind: "Field", name: { kind: "Name", value: "outdoorSpace" } },
                      { kind: "Field", name: { kind: "Name", value: "sharedStorage" } },
                      { kind: "Field", name: { kind: "Name", value: "cinemaRoom" } },
                      { kind: "Field", name: { kind: "Name", value: "spaceDescription" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RoomCardRoom" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BookableUnit" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "rent" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartmentAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "floorPlan" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "room" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitMedia" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "media" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "filename" } },
                            { kind: "Field", name: { kind: "Name", value: "uri" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roomAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "size" } },
                      { kind: "Field", name: { kind: "Name", value: "bedsSizes" } },
                      { kind: "Field", name: { kind: "Name", value: "furnished" } },
                      { kind: "Field", name: { kind: "Name", value: "linenAndTowels" } },
                      { kind: "Field", name: { kind: "Name", value: "desk" } },
                      { kind: "Field", name: { kind: "Name", value: "storage" } },
                      { kind: "Field", name: { kind: "Name", value: "tv" } },
                      { kind: "Field", name: { kind: "Name", value: "privateBalcony" } },
                      { kind: "Field", name: { kind: "Name", value: "ensuiteBathroom" } },
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roomType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "cmsData" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentHero" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSize" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentMedia" } },
          { kind: "Field", name: { kind: "Name", value: "buildingMedia" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartmentAttributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "bathrooms" } },
                      { kind: "Field", name: { kind: "Name", value: "bedrooms" } },
                      { kind: "Field", name: { kind: "Name", value: "floor" } },
                      { kind: "Field", name: { kind: "Name", value: "floorPlan" } },
                      { kind: "Field", name: { kind: "Name", value: "listingTitle" } },
                      { kind: "Field", name: { kind: "Name", value: "listingDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "roomsCount" } },
                      { kind: "Field", name: { kind: "Name", value: "bedsSizes" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentInfoCard" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentTabs" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentAmenities" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentDescription" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentLocation" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentFloorPlan" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "BuildingAmenities" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingBySlugQuery, ListingBySlugQueryVariables>;
export const ApartmentStaticPathsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApartmentStaticPaths" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
                { kind: "Field", name: { kind: "Name", value: "citySlug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentStaticPathsQuery, ApartmentStaticPathsQueryVariables>;
export const ApartmentSignupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApartmentSignup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "apartmentSlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "apartmentSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "apartmentSlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "UnitSignup" } },
                { kind: "Field", name: { kind: "Name", value: "unitType" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UnitSignup" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "cityName" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentSignupQuery, ApartmentSignupQueryVariables>;
export const EnitreSignupStaticPathsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EnitreSignupStaticPaths" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unitType" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UnitType" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitType" },
                value: { kind: "Variable", name: { kind: "Name", value: "unitType" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "citySlug" } },
                { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnitreSignupStaticPathsQuery, EnitreSignupStaticPathsQueryVariables>;
export const CityBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CityBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cityBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "cmsData" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityBySlugQuery, CityBySlugQueryVariables>;
export const CityStaticPathsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CityStaticPaths" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CityDefaultFilter" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityStaticPathsQuery, CityStaticPathsQueryVariables>;
export const CityApartmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CityApartments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "buildingSlug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "category" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UnitCategory" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "availableFrom" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DateTime" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "maxPrice" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UnitType" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "buildingSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "buildingSlug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "citySlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "category" },
                value: { kind: "Variable", name: { kind: "Name", value: "category" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "availableFrom" },
                value: { kind: "Variable", name: { kind: "Name", value: "availableFrom" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxPrice" },
                value: { kind: "Variable", name: { kind: "Name", value: "maxPrice" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitType" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              { kind: "Argument", name: { kind: "Name", value: "perPage" }, value: { kind: "IntValue", value: "100" } },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
                { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
                { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
                { kind: "Field", name: { kind: "Name", value: "available" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentCard" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "AvailabilityMapPinApartment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentType" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeDescription" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentPopoverApartment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApartmentCard" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "availableRooms" } },
          { kind: "Field", name: { kind: "Name", value: "available" } },
          { kind: "Field", name: { kind: "Name", value: "citySlug" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentName" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSuburb" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentSlug" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentTypeName" } },
          { kind: "Field", name: { kind: "Name", value: "totalRooms" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "startingPrice" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentType" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentCmsData" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentMedia" } },
          { kind: "Field", name: { kind: "Name", value: "buildingMedia" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailabilityMapPinApartment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ListingType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "apartmentId" } },
          { kind: "Field", name: { kind: "Name", value: "availableFrom" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentLatitude" } },
          { kind: "Field", name: { kind: "Name", value: "apartmentLongitude" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ApartmentPopoverApartment" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityApartmentsQuery, CityApartmentsQueryVariables>;
export const StaticCityBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StaticCityBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cityBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "citySlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StaticCityBySlugQuery, StaticCityBySlugQueryVariables>;
export const SignupsStaticPathsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SignupsStaticPaths" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CityDefaultFilter" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignupsStaticPathsQuery, SignupsStaticPathsQueryVariables>;
