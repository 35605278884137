import { getSrcset } from "@/utils/getSrcset";
import type { BoxProps, ImageProps as ChakraImageProps } from "@chakra-ui/react";
import { Box, chakra, useBreakpointValue } from "@chakra-ui/react";
import * as React from "react";

export interface ImageProps extends Omit<BoxProps, "as"> {
  src: any;
  alt: string;
  sizes?: string | { [key: string]: string };
  imageProps?: ChakraImageProps;
}

export const Image = ({ src, alt, children, imageProps, sizes, ...rest }: ImageProps) => {
  const breakpointSize = useBreakpointValue(typeof sizes === "object" ? sizes : {});

  const [isSafari, setIsSafari] = React.useState(true);

  React.useEffect(() => {
    // https://stackoverflow.com/questions/7944460/detect-safari-browser
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);

  if (sizes && typeof sizes === "object" && !breakpointSize) {
    return <></>;
  }

  return (
    <Box position="relative" {...rest}>
      <chakra.img
        objectFit="cover"
        height="100%"
        width="100%"
        alt={alt}
        loading="lazy"
        // Apparently Safari doesn't support `loading="lazy"` when using `srcSet` and `sizes`???
        srcSet={isSafari ? undefined : getSrcset(src)}
        sizes={isSafari ? undefined : breakpointSize ?? (sizes as string)}
        src={src}
        {...imageProps}
      />
      {children}
    </Box>
  );
};
