// import { ChangeLanguage } from "@/components/ChangeLanguage";
import { CitiesMenu } from "@/components/CitiesMenu";
import { CollapsibleMenu } from "@/components/CollapsibleMenu";
import type { ContainerProps } from "@/components/Container";
import { Container } from "@/components/Container";
import { Menu } from "@/components/Menu";
import type { MenuData } from "@/types/menu-data";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  useStyleConfig,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import Logo from "../../../../public/Logo.svg";
import { Link } from "../Link";

const HEADER_HEIGHT = 56;

interface HeaderProps {
  navigationData: MenuData["headerNavigation"];
  variant?: "primary" | "transparent";
}

export const Header = ({ navigationData, variant, ...rest }: HeaderProps & ContainerProps) => {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const activeVariant = variant ? variant : scrolled ? "primary" : "transparent";
  const styles = useStyleConfig("Header", { variant: activeVariant });
  const { t } = useTranslation("common");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -HEADER_HEIGHT && !scrolled) {
      setScrolled(true);
    } else if (currPos.y >= -HEADER_HEIGHT && scrolled) {
      setScrolled(false);
    }
  });

  const headerBg = styles.bg as any;

  delete styles.bg;

  const isDesktop = useBreakpointValue({ base: false, lg: true }, "base");

  const HeaderLinks = () => (
    <>
      {navigationData.map((headerLink) => {
        if (!headerLink.primary.link.url) {
          return (
            <React.Fragment key={headerLink.primary.label}>
              {isDesktop ? (
                <Menu links={headerLink.items} variant="unstyled" onClick={onClose}>
                  {headerLink.primary.label}
                </Menu>
              ) : (
                <CollapsibleMenu display={{ lg: "none" }} links={headerLink.items} onClick={onClose}>
                  {headerLink.primary.label}
                </CollapsibleMenu>
              )}
            </React.Fragment>
          );
        }
        return (
          <Link
            key={headerLink.primary.label}
            href={headerLink.primary.link.url}
            isExternal={headerLink.primary.link.link_type === "Web"}
            fontWeight="500"
            onClick={onClose}
          >
            {headerLink.primary.label}
          </Link>
        );
      })}
    </>
  );

  return (
    <Flex
      as="header"
      zIndex={2}
      position="sticky"
      top={0}
      w="100%"
      bg={headerBg}
      transitionProperty="background, box-shadow, color"
      transitionDuration="normal"
      flexDirection="column"
      boxShadow={scrolled ? "sm" : undefined}
    >
      <Container __css={styles} {...rest}>
        <Link href="/">
          <VisuallyHidden>{t("home")}</VisuallyHidden>
          <Logo fill={styles.color} />
        </Link>
        <HStack display={{ base: "flex", lg: "none" }} alignItems="center" justifyContent="flex-end">
          <IconButton
            aria-label={t("aria-label.open-menu")}
            variant="ghost"
            onClick={onOpen}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
          >
            <Icon as={RiMenuFill} color={styles.color as string} fontSize="lg" />
          </IconButton>
        </HStack>
        <Drawer onClose={onClose} isOpen={isOpen} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Link href="/">
                <VisuallyHidden>{t("home")}</VisuallyHidden>
                <Logo />
              </Link>
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={8} as="nav" py={2} alignItems="start">
                <HeaderLinks />
                <CitiesMenu toApartments variant="primary" onClick={onClose}>
                  {t("find-your-home")}
                </CitiesMenu>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <HStack spacing={8} as="nav" display={{ base: "none", lg: "flex" }} justifyContent="center">
          <HeaderLinks />
          {/* <ChangeLanguage /> */}
          <CitiesMenu toApartments variant={activeVariant}>
            {t("find-your-home")}
          </CitiesMenu>
        </HStack>
      </Container>
    </Flex>
  );
};
