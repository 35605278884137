import type { IconBaseProps } from "react-icons";

export const View3D = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 19v.727c0 .596-.518 1.061-1.106.967C4.42 20.137 2 18.842 2 17V7c0-2.507 4.48-4 10-4s10 1.493 10 4v10c0 1.842-2.42 3.137-5.894 3.694-.588.095-1.106-.371-1.106-.967v-4.543a24.332 24.332 0 00-6 0V19zm-2-.532V10.53c-1.17-.245-2.191-.582-3-1.002V17c0 .462 1.17 1.05 3 1.468zm10 0c1.83-.419 3-1.006 3-1.468V9.528c-.809.42-1.83.757-3 1.002v7.938zm-2-5.31v-3.46c0-.504.377-.927.874-1.01C18.335 8.28 20 7.55 20 7c0-.807-3.58-2-8-2S4 6.193 4 7c0 .55 1.665 1.28 4.126 1.689.497.082.874.505.874 1.01v3.458A27.659 27.659 0 0112 13c1.045 0 2.053.053 3 .157z"
    />
  </svg>
);
