import type { IconBaseProps } from "react-icons";

export const ChevronLeft = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M10.4797 11.9997L14.7227 16.2427C15.1132 16.6332 15.1132 17.2663 14.7227 17.6567C14.3322 18.0472 13.6992 18.0472 13.3087 17.6567L8.23023 12.5783C7.91071 12.2588 7.91071 11.7407 8.23023 11.4212L13.3087 6.34274C13.6992 5.95228 14.3322 5.95228 14.7227 6.34274C15.1132 6.73321 15.1132 7.36628 14.7227 7.75674L10.4797 11.9997Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);
