/* eslint-disable no-case-declarations */
import { Link } from "@/components/common";
import { SectionHeading } from "@/components/Section";
import { getHeadingId } from "@/utils/getHeadingId";
import { Divider } from "@chakra-ui/react";
import { chakra, Heading, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react";
import { Element } from "@prismicio/react";
import { linkResolver } from "prismic-configuration";
import * as React from "react";

const propsWithUniqueKey = function (props: any, key: any) {
  return Object.assign(props || {}, { key });
};

export const htmlSerializer = function (type: any, element: any, content: any, children: any, key: any) {
  let props = {};

  let id: string | undefined;

  switch (type) {
    case Element.heading1: // Heading 1
      id = getHeadingId(element.text);
      return (
        <SectionHeading as="h1" fontSize={{ base: "48px", md: "64px" }} id={id} {...propsWithUniqueKey(props, key)}>
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {children}
          </chakra.a>
        </SectionHeading>
      );

    case Element.heading2: // Heading 2
      id = getHeadingId(element.text);
      return (
        <Heading as="h2" fontSize="5xl" id={id} {...propsWithUniqueKey(props, key)}>
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {children}
          </chakra.a>
        </Heading>
      );

    case Element.heading3: // Heading 3
      id = getHeadingId(element.text);
      return (
        <Heading as="h3" fontSize="4xl" id={id} {...propsWithUniqueKey(props, key)}>
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {children}
          </chakra.a>
        </Heading>
      );

    case Element.heading4: // Heading 4
      id = getHeadingId(element.text);
      return (
        <Text as="h4" fontSize="2xl" id={id} {...propsWithUniqueKey(props, key)}>
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {children}
          </chakra.a>
        </Text>
      );

    case Element.heading5: // Heading 5
      id = getHeadingId(element.text);
      return (
        <Text as="h5" fontSize="xl" id={id} {...propsWithUniqueKey(props, key)}>
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {children}
          </chakra.a>
        </Text>
      );

    case Element.heading6: // Heading 6
      id = getHeadingId(element.text);
      return (
        <Text as="h6" fontSize="lg" id={id} {...propsWithUniqueKey(props, key)}>
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {children}
          </chakra.a>
        </Text>
      );

    case Element.paragraph: // Paragraph
      if (element.text === "---") {
        return <Divider my={10} bg="gray.400" opacity={1} />;
      }
      return <Text {...propsWithUniqueKey(props, key)}>{children}</Text>;

    case Element.preformatted: // Preformatted
      return (
        <Text as="pre" {...propsWithUniqueKey(props, key)}>
          {children}
        </Text>
      );

    case Element.strong: // Strong
      return (
        <Text as="span" fontWeight="semibold" {...propsWithUniqueKey(props, key)}>
          {children}
        </Text>
      );

    case Element.em: // Emphasis
      return (
        <Text as="span" fontStyle="italic" {...propsWithUniqueKey(props, key)}>
          {children}
        </Text>
      );

    case Element.listItem: // Unordered List Item
      return <ListItem {...propsWithUniqueKey(props, key)}>{children}</ListItem>;

    case Element.oListItem: // Ordered List Item
      return <ListItem {...propsWithUniqueKey(props, key)}>{children}</ListItem>;

    case Element.list: // Unordered List
      return (
        <UnorderedList mt={4} spacing={2} {...propsWithUniqueKey(props, key)}>
          {children}
        </UnorderedList>
      );

    case Element.oList: // Ordered List
      return (
        <OrderedList mt={4} spacing={2} {...propsWithUniqueKey(props, key)}>
          {children}
        </OrderedList>
      );

    case Element.hyperlink: // Hyperlink
      const targetAttr = element.data.target ? { target: element.data.target } : {};
      const relAttr = element.data.target ? { rel: "noopener" } : {};

      const href = element.data.url || linkResolver(element.data);

      if (href.includes("https://#")) {
        const handleClick = () => {
          const element: HTMLElement | null = document.querySelector(decodeURIComponent(href.replace("https://", "")));

          if (element) {
            const top = element.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
              top: top - 88,
              behavior: "smooth",
            });

            history.replaceState({}, "", href.replace("https://", ""));
          }
        };

        return (
          <chakra.button fontWeight="medium" color="almond" onClick={handleClick} {...propsWithUniqueKey(props, key)}>
            {children}
          </chakra.button>
        );
      }

      props = Object.assign(
        {
          href: href.includes("https://#") ? href.replace("https://", "") : href,
        },
        targetAttr,
        relAttr,
      );

      return (
        <Link color="almond" scrollPaddingTop="88px" {...propsWithUniqueKey(props, key)}>
          {children}
        </Link>
      );

    case Element.label: // Label
      props = element.data ? Object.assign({}, { className: element.data.label }) : {};
      return React.createElement("span", propsWithUniqueKey(props, key), children);

    case Element.span: // Span
      if (content) {
        return content.split("\n").reduce((acc: any, p: any) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement("br", propsWithUniqueKey({}, brIndex));
            return [...acc, br, p];
          }
        }, []);
      } else {
        return null;
      }

    default:
      return null;
  }
};
