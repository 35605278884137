import type { MenuItemProps } from "@chakra-ui/react";
import { Link as ChakraLink, MenuItem } from "@chakra-ui/react";
import NextLink from "next/link";
import * as React from "react";

export interface LinkProps extends MenuItemProps {
  children?: React.ReactNode;
  href: any;
}

export const MenuLink = ({ children, href, ...rest }: LinkProps) => {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <MenuItem as={ChakraLink} _hover={{ textDecoration: "none", boxShadow: "none" }} {...rest}>
        {children}
      </MenuItem>
    </NextLink>
  );
};
