import type { IconBaseProps } from "react-icons";

export const CheckboxOff = (props: IconBaseProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 2.5h16A1.5 1.5 0 0 1 21.5 4v16a1.5 1.5 0 0 1-1.5 1.5H4A1.5 1.5 0 0 1 2.5 20V4A1.5 1.5 0 0 1 4 2.5Z"
      fill="#F4F4F3"
      stroke="#D3D2CF"
    />
  </svg>
);
