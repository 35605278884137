import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import type { ContainerProps } from "@/components/Container";
import { Container } from "@/components/Container";
import type { MenuData } from "@/types/menu-data";
import type { FlexProps } from "@chakra-ui/react";
import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Link as ChakraLink,
  List,
  ListItem,
  Text,
  useStyleConfig,
  VisuallyHidden,
} from "@chakra-ui/react";
import type { FilledLinkToWebField } from "@prismicio/types";
import * as React from "react";
import { RiFacebookCircleFill, RiInstagramLine, RiLinkedinBoxFill, RiYoutubeFill } from "react-icons/ri";
import Logo from "../../../../public/Logo.svg";
import { Link } from "../Link";

interface FooterListProps extends FlexProps {
  heading: string;
  links: { label: string; link: FilledLinkToWebField }[];
}

const FooterList = ({ heading, links, ...rest }: FooterListProps) => {
  return (
    <Flex flexDirection="column" {...rest}>
      <Text opacity={0.6} fontSize="md" mb={6}>
        {heading}
      </Text>
      <List>
        {links.map((footerLink, index) => {
          if (!footerLink.link.url) return null;
          return (
            <ListItem key={footerLink.label} mb={index === links.length ? 0 : 2}>
              <Link href={footerLink.link.url} isExternal={footerLink.link.link_type === "Web"} fontWeight="500">
                {footerLink.label}
              </Link>
            </ListItem>
          );
        })}
      </List>
    </Flex>
  );
};

interface FooterProps {
  navigationData: MenuData["footerNavigation"];
  variant?: "white" | "green" | "black" | "cream";
}

export const Footer = ({ navigationData, variant = "green", ...rest }: FooterProps & ContainerProps) => {
  const styles = useStyleConfig("Footer", { variant });
  const { t } = useTranslation("common");

  return (
    <Box as="footer" __css={styles}>
      <Container px={{ base: 4, md: 10, xl: 20 }} {...rest}>
        <Grid
          py={{ base: "80px", md: "40px", xl: "80px" }}
          gridTemplateColumns={{ base: "repeat(4, 1fr)", md: "repeat(8, 1fr)", xl: "repeat(12, 1fr)" }}
          gridTemplateRows={{ base: "unset", md: "repeat(2, 1fr)", xl: "1fr" }}
          gridColumnGap={4}
          gridRowGap={{ base: 16, md: 20 }}
        >
          <Box gridColumn={{ base: "1 / -1", md: "1 / 4", xl: "1 / 4" }} gridRow="1 / -1">
            <Link href="/">
              <Logo fill={styles.color} />
              <VisuallyHidden>{t("home")}</VisuallyHidden>
            </Link>
            <Box py={10}>
              <Text>LifeX ApS</Text>
              <Text>Wilders Plads 15D</Text>
              <Text>1403 København K</Text>
              <Text>Denmark</Text>
              <Text>CVR: 38502824</Text>
            </Box>
            <Link
              display="block"
              href="https://www.iubenda.com/privacy-policy/90056981"
              isExternal
              fontWeight="medium"
              mb={4}
            >
              {t("privacy-policy")}
            </Link>
            <Link
              display="block"
              href="https://www.iubenda.com/privacy-policy/90056981/cookie-policy"
              isExternal
              fontWeight="medium"
              mb={4}
            >
              {t("cookie-policy")}
            </Link>
            <Link
              display="block"
              href="https://s3.eu-central-1.amazonaws.com/felix.files/prod/listing/data-protection-and-IT-security-policy-at-LifeX.pdf"
              isExternal
              fontWeight="medium"
            >
              {t("security-policy")}
            </Link>
          </Box>
          {navigationData.map((link) => {
            return (
              <FooterList
                key={link.primary.label}
                heading={link.primary.label}
                links={link.items}
                gridColumn="span 2"
              />
            );
          })}
        </Grid>
        <Divider bg="#221D10" opacity={0.2} />
        <Flex
          pt="40px"
          pb={{ base: "80px", md: "40px" }}
          justifyContent={{ md: "space-between" }}
          alignItems={{ base: "flex-start", md: "center" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Text opacity={0.6}>
            <Trans
              i18nKey="common:made-in-copenhagen"
              components={{
                heart: (
                  <span key="heart" role="img" aria-label={t("aria-label.love")}>
                    ❤️
                  </span>
                ),
              }}
              values={{ count: 42 }}
            />
          </Text>
          <HStack spacing={4} mt={{ base: 6, md: 0 }}>
            <ChakraLink aria-label="Instagram" variant="link" href="https://www.instagram.com/joinlifex" isExternal>
              <Icon fontSize="24px" opacity={0.5} as={RiInstagramLine} />
            </ChakraLink>
            <ChakraLink aria-label="Facebook" variant="link" href="https://www.facebook.com/joinlifex/" isExternal>
              <Icon fontSize="24px" opacity={0.5} as={RiFacebookCircleFill} />
            </ChakraLink>
            <ChakraLink
              aria-label="LinkedIn"
              variant="link"
              href="https://www.linkedin.com/company/join-lifex"
              isExternal
            >
              <Icon fontSize="24px" opacity={0.5} as={RiLinkedinBoxFill} />
            </ChakraLink>
            <ChakraLink
              aria-label="YouTube"
              variant="link"
              href="https://www.youtube.com/channel/UCkwJcBRc8ko64GwZ-GdSDTQ"
              isExternal
            >
              <Icon fontSize="24px" opacity={0.5} as={RiYoutubeFill} />
            </ChakraLink>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};
